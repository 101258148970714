import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import rbacCheck from '../../../rbac/rbacCheck';
import CreateProjectFormModal from './CreateProjectFormModal';
import useApiCall from '../../../hooks/useApiCall';
import Auth from '../../../api/auth';
import Table from '../../../components/Table';

const Projects = () => {
  const { organizationId } = useParams();
  const intl = useIntl();
  const navigate = useNavigate();
  const userData = useSelector((state) => state.user);

  const [createModalOpened, setCreateModalOpened] = useState(false);
  const [projects, setProjects] = useState(null);

  const getProjectsCall = useApiCall(Auth.fetchV2Projects);

  const canCreateProject = rbacCheck(userData.roles, '/projects:POST');

  useEffect(() => {
    const getProjects = async () => {
      const [data] = await getProjectsCall({ organizationId });

      if (data) setProjects(data);
    };

    getProjects();
  }, []);

  const fields = [
    {
      title: 'Name',
      id: 'name',
    },
    {
      title: 'Description',
      id: 'description',
    },
    {
      title: 'Business Unit',
      id: 'businessUnit',
    },
    {
      title: 'Technical Lead',
      id: 'technicalLead',
    },
  ];

  const rows = projects?.map((project) => ({
    key: project._id,
    onRowClick: () => navigate(`/projects/${project._id}`),
    data: [
      {
        field: 'name',
        value: project.name,
      },
      {
        field: 'description',
        value: project.description,
      },
      {
        field: 'businessUnit',
        value: project.businessUnit?.name,
      },
      {
        field: 'technicalLead',
        value: project.technicalLead?.email,
      },
    ],
  }));

  return (
    <>
      {createModalOpened && (
        <CreateProjectFormModal
          onSubmit={(newProject) => setProjects((prev) => [...prev, newProject])}
          onCancel={() => setCreateModalOpened(false)}
        />
      )}
      <Table
        header="Projects"
        fields={fields}
        rows={rows}
        createAction={{
          disabled: !canCreateProject,
          onClick: () => setCreateModalOpened(true),
          tooltip: !canCreateProject ? intl.formatMessage({ id: 'projectCreationPermissionErr' }) : '',
        }}
      />
    </>
  );
};

export default Projects;
