import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import useApiCall from '../../../../../hooks/useApiCall';

import Provision from '../../../../../api/provision';
import Table from '../../../../../components/Table';

const fields = [
  { title: 'CN Type', id: 'CNType' },
  { title: 'Server #', id: 'server' },
  { title: 'Storage Volume (GB)', id: 'storageVolume' },
  { title: 'vCPU (Shared)', id: 'vCPUShared' },
  { title: 'vCPU (Dedicated)', id: 'vCPUDedicated' },
  { title: 'RAM (Shared)(GB)', id: 'RAMShared' },
  { title: 'RAM (Dedicated)(GB)', id: 'RAMDedicated' },
  { title: 'Root disk local (GB)', id: 'rootDiskLocal' },
  { title: 'Root disk (GB)', id: 'rootDisk' },
];

const getRows = (usageByNodeType) => {
  const CNTypes = ['shared_local', 'shared', 'dedicated', 'dedicated_local'];
  return CNTypes.map((CNType) => ({
    key: CNType,
    data: [
      { field: 'CNType', value: CNType },
      { field: 'server', value: usageByNodeType[CNType].vms_total },
      { field: 'storageVolume', value: '-' },
      { field: 'vCPUShared', value: usageByNodeType[CNType].cpus_shared_total },
      { field: 'vCPUDedicated', value: usageByNodeType[CNType].cpus_dedicated_total },
      { field: 'RAMShared', value: usageByNodeType[CNType].ram_shared_gigabytes },
      { field: 'RAMDedicated', value: usageByNodeType[CNType].ram_dedicated_gigabytes },
      { field: 'rootDiskLocal', value: usageByNodeType[CNType].root_disk_local_gigabytes },
      { field: 'rootDisk', value: usageByNodeType[CNType].root_disk_gigabytes },
    ],
  }));
};

const getTotals = (totalUsage) => ([{
  key: 'total',
  data: [
    { field: 'CNType', value: 'total' },
    { field: 'server', value: totalUsage.vms_total },
    { field: 'storageVolume', value: totalUsage.volume_gigabytes },
    { field: 'vCPUShared', value: totalUsage.cpus_shared_total },
    { field: 'vCPUDedicated', value: totalUsage.cpus_dedicated_total },
    { field: 'RAMShared', value: totalUsage.ram_shared_gigabytes },
    { field: 'RAMDedicated', value: totalUsage.ram_dedicated_gigabytes },
    { field: 'rootDiskLocal', value: totalUsage.root_disk_local_gigabytes },
    { field: 'rootDisk', value: totalUsage.root_disk_gigabytes },
  ],
}]);

const UsageSummary = ({ juiceGroup, cloudId }) => {
  const [virtualResources, setVirtualResources] = useState([]);
  const fetchVirtualResources = useApiCall(Provision.fetchOSVirtualResources);

  useEffect(() => {
    async function getVirtualResources() {
      const [data] = await fetchVirtualResources(juiceGroup, [cloudId]);
      if (data) setVirtualResources(data);
    }
    if (cloudId && juiceGroup) getVirtualResources();
  }, [cloudId, juiceGroup]);

  if (!virtualResources.length) return <p>No usage data found</p>;
  return (
    <Table
      fields={fields}
      rows={getRows(virtualResources[0].usage.by_compute_node_type)}
      footerRows={getTotals(virtualResources[0].usage.total)}
      search={false}
      pagination={false}
    />
  );
};

UsageSummary.propTypes = {
  juiceGroup: PropTypes.string.isRequired,
  cloudId: PropTypes.string.isRequired,
};

export default UsageSummary;
