import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { Grid } from '@mui/material';
import { useEffect, useMemo, useCallback } from 'react';
import Button from '../../Buttons/Button';
import { FIELD_COMPONENTS } from './fieldComponents';

const FormikForm = ({
  formRef,
  fields,
  initialValues,
  validationSchema,
  onSubmit,
  onCancel,
  submitLabel,
  cancelLabel,
}) => {
  useEffect(() => {
    const firstField = fields.find((field) => FIELD_COMPONENTS[field.fieldType] && !field.disabled);
    if (firstField) {
      requestAnimationFrame(() => {
        document.querySelector(`input[name="${firstField.name}"]`)?.focus();
      });
    }
  }, [fields]);

  const isFieldRequired = useCallback((fieldName) => {
    const fieldSchema = validationSchema?.fields?.[fieldName];
    return fieldSchema?.tests?.some((test) => test.OPTIONS?.name === 'required') || false;
  }, [validationSchema]);

  const processedFields = useMemo(() => fields.map((field) => ({
    ...field,
    label: validationSchema
    && typeof field?.label === 'string'
    && !isFieldRequired(field.name)
      ? `${field.label} (optional)`
      : field.label,
  })), [fields, validationSchema]);

  const renderField = (field) => {
    const { fieldType, fieldContent, ...fieldProps } = field;
    const FieldComponent = FIELD_COMPONENTS[fieldType] || fieldType;

    return <FieldComponent {...fieldProps}>{fieldContent}</FieldComponent>;
  };

  return (
    <Formik
      enableReinitialize
      innerRef={formRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      <Form>
        <Grid
          container
          direction="column"
        >
          {processedFields.map((field, index) => (
            <Grid
              item
              key={field.name || `field-${index}`}
            >
              {renderField(field)}
            </Grid>
          ))}
          <Grid
            container
            justifyContent="end"
            gap={1}
          >
            {onCancel && (
              <Grid item>
                <Button
                  type="button"
                  variant="outlined"
                  onClick={onCancel}
                  label={cancelLabel}
                />
              </Grid>
            )}
            <Grid item>
              <Button
                type="submit"
                label={submitLabel}
              />
            </Grid>
          </Grid>
        </Grid>
      </Form>
    </Formik>
  );
};

FormikForm.propTypes = {
  formRef: PropTypes.shape({
    current: PropTypes.shape({
      resetForm: PropTypes.func,
    }),
  }),
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      label: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.shape({
          on: PropTypes.string.isRequired,
          off: PropTypes.string.isRequired,
        }),
      ]),
      fieldType: PropTypes.oneOfType([
        PropTypes.oneOf(Object.keys(FIELD_COMPONENTS)),
        PropTypes.elementType,
      ]).isRequired,
      menuItems: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string.isRequired,
          label: PropTypes.string.isRequired,
        }),
      ),
      disabled: PropTypes.bool,
      fieldContent: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
      autoFocus: PropTypes.bool,
    }),
  ).isRequired,
  initialValues: PropTypes.object.isRequired,
  validationSchema: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  submitLabel: PropTypes.string.isRequired,
  cancelLabel: PropTypes.string,
};

FormikForm.defaultProps = {
  formRef: null,
  onCancel: null,
  validationSchema: null,
  cancelLabel: 'Cancel',
};

export default FormikForm;
