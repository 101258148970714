export const FETCH_INSTANCE_STARTED = 'FETCH_INSTANCE_STARTED';
export const FETCH_INSTANCE_FULFILLED = 'FETCH_INSTANCE_FULFILLED';

export const fetchInstance = () => (
  { type: FETCH_INSTANCE_STARTED }
);

export const fetchInstancefulfilled = (data) => (
  { type: FETCH_INSTANCE_FULFILLED, payload: data }
);
