import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { CircularProgress } from '@mui/material';
import useJuiceGroupValidation from '../../../../hooks/useJuiceGroupValidation';
import { regexValidator } from '../../../../helpers/regexValidator';
import useApiCall from '../../../../hooks/useApiCall';
import Auth from '../../../../api/auth';
import { FormModal } from '../../../../components/Formik';
import Tags from '../../../../components/Tags/Tags';
import Provision from '../../../../api/provision';

const CreateProductFormModal = ({ onSubmit, onCancel }) => {
  const { projectId } = useParams();
  const { isCheckingJuiceGroup, juiceGroupError, checkJuiceGroup } = useJuiceGroupValidation();

  const [project, setProject] = useState(null);
  const [organization, setOrganization] = useState(null);
  const [juiceTags, setJuiceTags] = useState(null);

  const getProjectCall = useApiCall(Auth.fetchV2ProjectById);
  const getOrganizationCall = useApiCall(Auth.fetchV1OrganizationById);
  const createProductCall = useApiCall(Auth.createV2Product);
  const getTagsCall = useApiCall(Provision.fetchTags);

  useEffect(() => {
    const getTags = async () => {
      const [data] = await getTagsCall();
      if (data) setJuiceTags(data);
    };

    const getProjectAndOrganization = async () => {
      const [projData] = await getProjectCall(projectId);
      if (!projData) return;
      setProject(projData);

      const [orgData] = await getOrganizationCall(projData.organizationId);
      if (orgData) setOrganization(orgData);
    };

    getTags();
    getProjectAndOrganization();
  }, []);

  const productTypes = organization?.marketplaceItems?.reduce((acc, curr) => {
    if (!['Safescarf', 'Evaluation', 'Monitoring'].includes(curr.name)) {
      acc[curr.name] = curr.name;
    }
    return acc;
  }, {}) || {};


  const handleJuiceGroupChange = (value, { setFieldValue }) => {
    setFieldValue('juiceGroup', value);
    checkJuiceGroup(value);
  };

  const fields = [
    {
      fieldType: 'text',
      name: 'name',
      label: 'Product Name',
      onChange: (e, { setFieldValue }) => {
        const updatedJuiceGroup = `${project?.organization?.enterpriseName}-${project?.name}-${e.target.value}`;
        handleJuiceGroupChange(updatedJuiceGroup, { setFieldValue });
      },
    },
    {
      fieldType: 'text',
      name: 'juiceGroup',
      label: 'Juice Group',
      InputProps: {
        endAdornment: isCheckingJuiceGroup ? <CircularProgress size={20} /> : null,
      },
      onChange: (e, { setFieldValue }) => handleJuiceGroupChange(e.target.value, { setFieldValue }),
      helperText: juiceGroupError,
    },
    {
      fieldType: 'text',
      name: 'description',
      label: 'Description',
    },
    {
      fieldType: 'select',
      name: 'type',
      label: 'Product Type',
      menuItems: Object.keys(productTypes).map((key) => ({
        value: key,
        label: productTypes[key],
      })),
    },
    {
      fieldType: Tags,
      name: 'tags',
      label: 'Juice Group Tags',
      tags: juiceTags,
    },
  ];

  const initialValues = {
    name: '',
    juiceGroup: `${project?.organization?.enterpriseName}-${project?.name}-`,
    description: '',
    type: '',
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required('Required')
      .matches(regexValidator.name, 'All lower-case, no spaces, no special characters (dash only)'),
    juiceGroup: Yup.string()
      .required('Required')
      .matches(regexValidator.juiceGroup, 'The Juice Group must start with a letter and contain only lower-case letters, numbers, and dashes')
      .min(3, 'The Juice Group must be at least 3 characters long')
      .max(64, 'The Juice Group must be at most 64 characters long'),
    description: Yup.string()
      .required('Required'),
    type: Yup.string()
      .required('Required'),
  });

  const createProduct = async (formValues) => {
    onCancel();

    const [data] = await createProductCall({
      ...formValues,
      projectId,
      organizationId: project?.organizationId,
    });

    if (data) {
      onSubmit(data);
    }
  };

  return organization && (
    <FormModal
      title="Create New Product"
      fields={fields}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={createProduct}
      onCancel={onCancel}
      submitLabel="Create"
    />
  );
};

CreateProductFormModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default CreateProductFormModal;
