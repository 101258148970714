import { useNavigate } from 'react-router-dom';
import { Typography, Grid } from '@mui/material';
import Button from '../../components/Buttons/Button';

const TokenExpired = () => {
  const navigate = useNavigate();

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
    >
      <Grid item xs={12}>
        <Typography variant="h2" gutterBottom>
          Something Went Wrong!
        </Typography>
        <br />
        <Typography gutterBottom>
          Your link has reached expiration time, sadly. Please restart the operation.
          <br />
          {'If you can\'t do this properly please contact our support team.'}
        </Typography>
        <br />
        <Button
          type="submit"
          onClick={() => navigate('/')}
          label="Back to homepage"
        />
      </Grid>
    </Grid>
  );
};

export default TokenExpired;
