import { ofType, combineEpics } from 'redux-observable';
import { mergeMap, catchError } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { handleError } from '../../errorHandler';
import Safescarf from '../../../api/safescarf';
import {
  FETCH_USER_PROFILE_STARTED,
  fetchUserProfileFulfilled,
} from './actions';

const fetchUserProfileEpic = (action$) => action$.pipe(
  ofType(FETCH_USER_PROFILE_STARTED),
  mergeMap(() => from(Safescarf.fetchSafescarfUserProfile()).pipe(
    mergeMap((response) => of(
      fetchUserProfileFulfilled(response.data),
    )),
    catchError(handleError),
  )),
);

export default combineEpics(fetchUserProfileEpic);
