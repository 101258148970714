import PropTypes from 'prop-types';
import Table from '../../../../../components/Table';

function ramToGigabytes(ram) {
  return Math.floor(ram / 1024);
}

const fields = [
  {
    title: 'Quota',
    id: 'quota',
  },
  {
    title: 'vCPU',
    id: 'vCPU',
  },
  {
    title: 'RAM (GB)',
    id: 'RAM',
  },
  {
    title: 'Volumes (GB)',
    id: 'volumes',
  },
  {
    title: 'Object Storage (GB)',
    id: 'objectStorage',
  },
  {
    title: 'Floating IPs',
    id: 'floatingIPs',
  },
];

const getRows = (allowedQuota, usedQuota) => [
  {
    key: 'allowed-quota',
    data: [
      {
        field: 'quota',
        value: 'Current allowed',
      },
      {
        field: 'vCPU',
        value: allowedQuota.compute.cores,
      },
      {
        field: 'RAM',
        value: ramToGigabytes(allowedQuota.compute.ram),
      },
      {
        field: 'volumes',
        value: allowedQuota.volume.gigabytes,
      },
      {
        field: 'objectStorage',
        value: allowedQuota.volume.objects_megabytes,
      },
      {
        field: 'floatingIPs',
        value: allowedQuota.network.floating_ips,
      },
    ],
  },
  {
    key: 'allocated-quota',
    data: [
      {
        field: 'quota',
        value: 'Current allocated',
      },
      {
        field: 'vCPU',
        value: usedQuota.compute.cores,
      },
      {
        field: 'RAM',
        value: ramToGigabytes(usedQuota.compute.ram),
      },
      {
        field: 'volumes',
        value: usedQuota.volume.gigabytes,
      },
      {
        field: 'objectStorage',
        value: usedQuota.volume.objects_megabytes,
      },
      {
        field: 'floatingIPs',
        value: usedQuota.network.floating_ips,
      },
    ],
  },
];


const VirtualCloudsSummary = ({ virtualCloud }) => {
  if (!virtualCloud) return <p>No Virtual clouds were found</p>;
  return (
    <Table
      fields={fields}
      rows={getRows(virtualCloud.allowed_quota, virtualCloud.used_quota)}
      search={false}
      pagination={false}
    />
  );
};

VirtualCloudsSummary.propTypes = {
  virtualCloud: PropTypes.objectOf(PropTypes.oneOfType(
    [PropTypes.object, PropTypes.number, PropTypes.array],
  )).isRequired,
};

export default VirtualCloudsSummary;
