import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FormModal } from '../../../components/Formik';
import { regexValidator } from '../../../helpers/regexValidator';
import useApiCall from '../../../hooks/useApiCall';
import Auth from '../../../api/auth';

const CreateProjectFormModal = ({ onSubmit, onCancel }) => {
  const { organizationId } = useParams();

  const [businessUnits, setBusinessUnits] = useState(null);
  const [technicalLeads, setTechnicalLeads] = useState(null);

  const getUsersCall = useApiCall(Auth.fetchV2Users);
  const getBusinessUnitsCall = useApiCall(Auth.fetchV1BusinessUnits);

  const createProjectCall = useApiCall(Auth.createV2Project);

  useEffect(() => {
    const getBusinessUnits = async () => {
      const [data] = await getBusinessUnitsCall({ organizationId });
      if (data) setBusinessUnits(data);
    };

    const getTechnicalLeads = async () => {
      const [data] = await getUsersCall({ organizationId, roles: ['TECHNICAL_LEAD'] });

      if (data) {
        const activatedTechnicalLeads = data.filter((technicalLead) => technicalLead.activated);
        setTechnicalLeads(activatedTechnicalLeads);
      }
    };

    getBusinessUnits();
    getTechnicalLeads();
  }, []);

  const fields = [
    {
      fieldType: 'text',
      name: 'name',
      label: 'Project Name',
    },
    {
      fieldType: 'text',
      name: 'description',
      label: 'Description',
    },
    {
      fieldType: 'select',
      name: 'businessUnitId',
      label: 'Business Unit',
      menuItems: businessUnits?.map(({ _id, name }) => ({ value: _id, label: name })),
    },
    {
      fieldType: 'select',
      name: 'technicalLeadId',
      label: 'Technical Lead',
      menuItems: technicalLeads?.map(({ _id, email }) => ({ value: _id, label: email })),
    },
  ];

  const initialValues = {
    name: '',
    description: '',
    businessUnitId: null,
    technicalLeadId: null,
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required('Required')
      .matches(
        regexValidator.name,
        'All lower-case, no spaces, no special characters (dash only)',
      ),
    description: Yup.string()
      .required('Required'),
  });

  const createProject = async (formValues) => {
    onCancel();

    const [data] = await createProjectCall({
      ...formValues,
      organizationId,
    });

    if (data) {
      onSubmit(data);
    }
  };

  return (
    <FormModal
      title="Create New Project"
      fields={fields}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={createProject}
      onCancel={onCancel}
      submitLabel="Create"
    />
  );
};

CreateProjectFormModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default CreateProjectFormModal;
