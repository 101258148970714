import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import useApiCall from '../../../../hooks/useApiCall';
import Auth from '../../../../api/auth';
import Table from '../../../../components/Table';

const Dashboards = () => {
  const { organizationId } = useParams();
  const fetchProducts = useApiCall(Auth.fetchV2MonitoringProducts);
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);

  async function getProducts() {
    const [data] = await fetchProducts({ organizationId });
    if (data) setProducts(data);
  }

  useEffect(() => {
    getProducts();
  }, []);

  const rows = products.map((product) => ({
    key: product._id,
    onRowClick: () => navigate(`/monitoring-dashboards/${product.details.dashboardId}`),
    data: [
      { field: 'name', value: product.name },
    ],
  }));

  const productTableFields = [
    {
      title: 'Name',
      id: 'name',
    },
  ];

  return (
    <Table
      header="Monitoring Dashboards"
      fields={productTableFields}
      rows={rows}
    />

  );
};

export default Dashboards;
