import { useSelector } from 'react-redux';
import { CircularProgress } from '@mui/material';

const Overlay = () => {
  const isLoading = useSelector((state) => state.status.loading);

  return (
    <div
      style={{
        position: 'fixed',
        display: isLoading ? 'flex' : 'none',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(221, 221, 221, 0.47)',
        height: '100vh',
        width: '100vw',
        zIndex: 1000,
      }}
    >
      <CircularProgress size={60} />
    </div>
  );
};

export default Overlay;
