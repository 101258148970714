import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Breadcrumbs,
  Container,
  Grid,
  Typography,
} from '@mui/material';
import OverviewTable from '../../../components/OverviewTable/OverviewTable';
import rbacCheck from '../../../rbac/rbacCheck';
import EditBusinessUnitFormModal from './EditBusinessUnitFormModal';
import DeleteFormModal from '../../../components/DeleteFormModal/DeleteFormModal';
import useApiCall from '../../../hooks/useApiCall';
import Auth from '../../../api/auth';

const BusinessUnitDetails = () => {
  const { businessUnitId } = useParams();
  const userData = useSelector((state) => state.user);
  const navigate = useNavigate();

  const [businessUnitDetails, setBusinessUnitDetails] = useState(null);
  const [editModalOpened, setEditModalOpened] = useState(false);
  const [deleteModalOpened, setDeleteModalOpened] = useState(false);

  const getBusinessUnitCall = useApiCall(Auth.fetchV1BusinessUnit);
  const deleteBusinessUnitCall = useApiCall(Auth.deleteV1BusinessUnit);

  const canSeeOrganizations = rbacCheck(userData.roles, '/organizations:VIEW_ALL');
  const canEditBusinessUnit = rbacCheck(userData.roles, '/business-units:PUT');
  const canDeleteBusinessUnit = rbacCheck(userData.roles, '/business-units:DELETE');

  const getBusinessUnitDetails = async () => {
    const [data] = await getBusinessUnitCall(businessUnitId);
    if (data) setBusinessUnitDetails(data);
  };

  useEffect(() => {
    getBusinessUnitDetails();
  }, []);

  const deleteBusinessUnit = async () => {
    setDeleteModalOpened(false);
    const [data] = await deleteBusinessUnitCall(businessUnitId);

    if (data) {
      navigate(-1);
    }
  };

  if (!businessUnitDetails) return null;

  const {
    description, organizationId, organization, businessUnitManager, name,
  } = businessUnitDetails;

  const rows = [
    {
      title: 'Description',
      value: description,
    },
    {
      title: 'Organization',
      component: (
        <Link to={`/organizations/${organizationId}`}>
          {organization?.enterpriseName}
        </Link>
      ),
    },
    {
      title: 'BusinessUnitManager',
      component: (
        <Link to={`/users/${businessUnitManager?.uuid}`}>
          {businessUnitManager?.email}
        </Link>
      ),
    },
  ];

  return (
    <>
      {editModalOpened && (
        <EditBusinessUnitFormModal
          businessUnit={businessUnitDetails}
          onSubmit={getBusinessUnitDetails}
          onCancel={() => setEditModalOpened(false)}
        />
      )}
      {deleteModalOpened && (
        <DeleteFormModal
          fieldName="Business Unit"
          fieldValue={name}
          onSubmit={deleteBusinessUnit}
          onCancel={() => setDeleteModalOpened(false)}
        />
      )}
      <Grid
        container
        className="content-with-header"
        direction="column"
        rowGap={4}
      >
        <Grid item alignSelf="flex-start">
          <Breadcrumbs separator=" > ">
            {canSeeOrganizations ? (
              <Link to="/organizations">organizations</Link>
            ) : (
              <Link to={`/organizations/${organizationId}`}>organizations</Link>
            )}
            <Link to={`/organizations/${organizationId}`}>{organization?.enterpriseName}</Link>
            <Link to={`/organizations/${organizationId}/business-units`}>business units</Link>
            <Typography>{name}</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <Container maxWidth="lg">
            <OverviewTable
              header={name}
              rows={rows}
              editAction={{
                onClick: () => setEditModalOpened(true),
                disabled: !canEditBusinessUnit,
                tooltip: 'businessUnitEditPermissionErr',
              }}
              deleteAction={{
                onClick: () => setDeleteModalOpened(true),
                disabled: !canDeleteBusinessUnit,
                tooltip: 'businessUnitDeletePermissionErr',
              }}
            />
          </Container>
        </Grid>
      </Grid>
    </>
  );
};

export default BusinessUnitDetails;
