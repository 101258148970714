export const filterDataUpdates = (oldData, newData) => {
  const updates = {};

  Object.entries(newData).forEach(([key, value]) => {
    if (JSON.stringify(oldData[key]) !== JSON.stringify(value)) {
      updates[key] = value;
    }
  });

  return updates;
};
