import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Auth from '../../api/auth';
import useTableApiCall from '../../hooks/useTableApiCall';
import Table from '../../components/Table';

const JuiceGroups = () => {
  const navigate = useNavigate();

  const [rows, setRows] = useState([]);
  const fields = [
    { title: 'JuiceGroup', id: 'juicegroup' },
    { title: 'Type', id: 'type' },
    { title: 'Organization', id: 'organization' },
  ];

  const {
    setCurrentPage,
    ...tableData
  } = useTableApiCall(true, setRows, Auth.fetchV2JuiceGroups, {}, 'details.juiceGroup');


  const transformedRows = rows.map((juiceGroup) => ({
    key: juiceGroup.juiceGroup,
    onRowClick: () => navigate(`/products/${juiceGroup.productId}`),
    data: [
      { field: 'juicegroup', value: juiceGroup.juiceGroup },
      { field: 'type', value: juiceGroup.type === 'evaluation' ? 'compute' : juiceGroup.type },
      { field: 'organization', value: juiceGroup.organization.enterpriseName },
    ],
  }));

  return (
    <Table
      header="JuiceGroups"
      fields={fields}
      rows={transformedRows}
      {...tableData}
      onPageChange={(newPage) => setCurrentPage(newPage)}
      advancedSearch={false}
    />
  );
};

export default JuiceGroups;
