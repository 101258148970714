import { createBrowserRouter } from 'react-router-dom';
import { wrapCreateBrowserRouter } from '@sentry/react';

import AppLayout from './AppLayout';

import Home from './pages/Home/Home';
import UserPasswordUpdate from './pages/PasswordReset/UserPasswordUpdate';
import PasswordReset from './pages/PasswordReset/PasswordReset';
import UserActivation from './pages/UserActivation';
import AboutUs from './pages/AboutUs';
import ContactUs from './pages/ContactUs';
import ContactSecurity from './pages/ContactSecurity';
import Gtac from './pages/GTAC';
import Page404 from './pages/Error/Page404';
import DataPrivacy from './pages/DataPrivacy';
import Account from './pages/Account';
import AccountOverview from './pages/Account/AccountOverview';
import Security from './pages/Account/Security';

import ComputeOrder from './pages/Products/Compute/ComputeOrder';
import Evaluation from './pages/Products/Compute/Evaluation';
import ComputeSpecification from './pages/Products/Compute/Specification';
import SafescarfSpecification from './pages/Products/Safescarf/Specification';
import CaasSpecification from './pages/Products/Caas/Specification';
import Organizations from './pages/Organizations';

// Reports
import Reports from './pages/Reports';
import TenantsReport from './pages/Reports/TenantsReport';
import HypervisorReport from './pages/Reports/HypervisorReport';
import TenantDetails from './pages/Reports/TenantDetails';
import HypervisorDetails from './pages/Reports/HypervisorDetails';
import JuiceGroups from './pages/Reports/JuiceGroups';
import ContactDetails from './pages/Reports/ContactDetails';

// Organization
import OrganizationDetails from './pages/OrganizationDetails';
import Overview from './pages/OrganizationDetails/Overview';
import Users from './pages/OrganizationDetails/Users';
import UserDetails from './pages/OrganizationDetails/Users/UserDetails';
import BusinessUnits from './pages/OrganizationDetails/BusinessUnits';
import BusinessUnitDetails from './pages/OrganizationDetails/BusinessUnits/BusinessUnitDetails';
import Projects from './pages/OrganizationDetails/Projects';
import Project from './pages/OrganizationDetails/Projects/Project';

// User Products
import UserProducts from './pages/Products/UserProducts/Compute';

// Compute Product
import OsProject from './pages/Products/Compute/OsProject';
import ProductDetails from './pages/Products/UserProducts/Compute/ProductDetails';

// Safescarf Product
import SafescarfProduct from './pages/Products/UserProducts/Safescarf';
import SafescarfOverview from './pages/Products/UserProducts/Safescarf/Overview';
import SafescarfUsers from './pages/Products/UserProducts/Safescarf/Users';
import SafescarfMachineAccounts from './pages/Products/UserProducts/Safescarf/MachineAccounts';
import SafescarfProductTypes from './pages/Products/UserProducts/Safescarf/ProductTypes';
import SafescarfProductTypeDetails from './pages/Products/UserProducts/Safescarf/ProductTypes/ProductTypeDetails';
import ProductTypeMemberDetails from './pages/Products/UserProducts/Safescarf/ProductTypes/ProductTypeMemberDetails';
import SafescarfProducts from './pages/Products/UserProducts/Safescarf/Products';
import SafescarfProductDetails from './pages/Products/UserProducts/Safescarf/Products/ProductDetails';
import ProductMemberDetails from './pages/Products/UserProducts/Safescarf/Products/ProductMemberDetails';

// CaaSonOpenstack Product
import CaasCluster from './pages/Products/Caas/CaasCluster';

import FallBackError from './pages/Error/FallBackError';

import Documentation from './pages/Documentation';

import MonitoringDashboard from './pages/Products/UserProducts/Monitoring/MonitoringDashboard';
import Monitoring from './pages/Products/UserProducts/Monitoring';
import PanelDetails from './pages/Products/UserProducts/Monitoring/PanelDetails';
import Dashboards from './pages/Products/UserProducts/Monitoring/Dashboards';
import CaasClusters from './pages/Reports/CaasClusters';


const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter);

export const router = sentryCreateBrowserRouter([
  {
    path: '/',
    element: <AppLayout />,
    errorElement: <FallBackError />,
    children: [
      { index: true, element: <Home /> },
      { path: 'monitoring-dashboards', element: <Dashboards /> },
      {
        path: 'monitoring-dashboards/:dashboardId',
        element: <Monitoring />,
        children: [
          { index: true, element: <MonitoringDashboard /> },
          { path: ':panelId', element: <PanelDetails /> },
        ],
      },
      { path: 'new-password/:token', element: <UserPasswordUpdate /> },
      { path: 'reset-password', element: <PasswordReset /> },
      { path: 'user/activate/:token', element: <UserActivation /> },
      {
        path: 'account',
        element: <Account />,
        children: [
          { index: true, element: <AccountOverview />, handle: { isOverlayDisabled: true } },
          { path: 'security', element: <Security /> },
        ],
      },
      { path: 'about-us', element: <AboutUs /> },
      { path: 'contact-sec', element: <ContactSecurity /> },
      { path: 'contact-us', element: <ContactUs /> },
      { path: 'data-privacy', element: <DataPrivacy /> },
      { path: 'gtc', element: <Gtac /> },
      { path: 'docs/:pageTitle', element: <Documentation /> },
      {
        path: 'products',
        children: [
          {
            path: 'compute',
            children: [
              { index: true, element: <UserProducts type="compute" /> },
              { path: 'free-trial', element: <Evaluation /> },
              { path: 'order', element: <ComputeOrder /> },
              { path: ':productId', element: <ProductDetails /> },
              { path: ':productId/osprojects/:juiceGroup/:cloudId/:osprojectName', element: <OsProject /> },
            ],
          },
          {
            path: 'caas-on-openstack',
            children: [
              { index: true, element: <UserProducts type="caas" /> },
              { path: ':productId', element: <ProductDetails /> },
              { path: ':productId/clusters/:clusterName', element: <CaasCluster /> },
            ],
          },
          { path: 'caas/clusters', element: <CaasClusters /> },
          {
            path: 'safescarf',
            element: <SafescarfProduct />,
            children: [
              { index: true, element: <SafescarfOverview /> },
              { path: 'users', element: <SafescarfUsers /> },
              { path: 'machine-accounts', element: <SafescarfMachineAccounts /> },
              { path: 'product-types', element: <SafescarfProductTypes /> },
              { path: 'products', element: <SafescarfProducts /> },
            ],
          },
          {
            path: 'safescarf',
            children: [
              { path: 'product-types/:productTypeId', element: <SafescarfProductTypeDetails /> },
              { path: 'product-types/:productTypeId/members/:safescarfUserId', element: <ProductTypeMemberDetails /> },
              { path: 'products/:productId', element: <SafescarfProductDetails /> },
              { path: 'products/:productId/members/:safescarfUserId', element: <ProductMemberDetails /> },
            ],
          },
        ],
      },
      { path: 'products/compute/specification', element: <ComputeSpecification /> },
      { path: 'products/safescarf/specification', element: <SafescarfSpecification /> },
      { path: 'products/caas-on-openstack/specification', element: <CaasSpecification /> },
      { path: 'organizations', element: <Organizations /> },
      {
        path: 'organizations/:organizationId',
        element: <OrganizationDetails />,
        children: [
          { index: true, element: <Overview /> },
          { path: 'users', element: <Users /> },
          { path: 'business-units', element: <BusinessUnits /> },
          { path: 'projects', element: <Projects /> },
          { path: 'monitoring-dashboards', element: <Dashboards /> },
        ],
      },
      { path: 'users/:uuid', element: <UserDetails /> },
      { path: 'business-units/:businessUnitId', element: <BusinessUnitDetails /> },
      { path: 'projects/:projectId', element: <Project /> },
      {
        path: 'products/:productId',
        children: [
          { index: true, element: <ProductDetails /> },
          { path: 'contacts/:juiceGroup/:contactId', element: <ContactDetails /> },
          { path: 'osprojects/:juiceGroup/:cloudId/:osprojectName', element: <OsProject /> },
          { path: 'clusters/:clusterName', element: <CaasCluster /> },
        ],
      },
      {
        path: 'reports',
        element: <Reports />,
        children: [
          { path: 'tenants', element: <TenantsReport /> },
          { path: 'tenants/:cloudId/:juiceGroup/:tenant', element: <TenantDetails /> },
          { path: 'tenants/:cloudId/:juiceGroup/:tenant/contacts/:contactId', element: <ContactDetails /> },
          { path: 'hypervisors', element: <HypervisorReport /> },
          { path: 'hypervisors/:cloudId/:juiceGroup/:virtualMachine', element: <HypervisorDetails /> },
          { path: 'hypervisors/:cloudId/:juiceGroup/:virtualMachine/contacts/:contactId', element: <ContactDetails /> },
          { path: 'juicegroups', element: <JuiceGroups /> },
          { path: 'caas-clusters', element: <CaasClusters /> },
        ],
      },
    ],
  },
  { path: '*', element: <Page404 /> },
]);
