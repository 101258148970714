import { useState } from 'react';
import {
  Container,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import { useIntl } from 'react-intl';
import { Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { validationSchema } from './validation';
import ProjectSelect from './components/ProjectSelect';
import ProductSelect from './components/ProductSelect';
import CloudSelect from './components/CloudSelect';
import ResourceQuota from './components/ResourceQuota';
import NetworkConnectivity from './components/NetworkConnectivity';
import Provision from '../../../api/provision';
import SuccessModal from '../../../components/Modal/SuccessModal';
import useApiCall from '../../../hooks/useApiCall';
import { FormikTextField } from '../../../components/Formik';
import Button from '../../../components/Buttons/Button';

const ramToMB = (ram) => ram * 1024;

const initialValues = {
  osProjectName: '',
  projectId: '',
  productId: '',
  cloudName: '',
  cpuCores: '',
  ram: '',
  diskQuota: '',
  floatingIps: '',
  internetConnectivity: false,
  DTHConnectivity: false,
  DTHRedRouterConnectivity: false,
  juiceGroup: '',
  unusedQuota: {},
  externalNetworks: [],
};

const numberInputOnWheelPreventChange = (event) => {
  event.target.blur();
  event.stopPropagation();
  setTimeout(() => {
    event.target.focus();
  }, 0);
};

const ComputeOrder = () => {
  const intl = useIntl();
  const navigate = useNavigate();

  const [osProjectOrdered, setOsProjectOrdered] = useState(false);
  const [filteredProducts, setFilteredProducts] = useState([]);

  const createOsProject = useApiCall(Provision.createOsProject);

  const handleSubmit = async (values) => {
    const [data] = await createOsProject({
      type: 'compute',
      ...values,
      cloudId: values.cloudName,
      requestedQuota: {
        compute: {
          cores: values.cpuCores,
          ram: ramToMB(values.ram),
        },
        network: {
          floatingIps: values.floatingIps,
          DTHRedRouterConnectivity: values.DTHRedRouterConnectivity,
          DTHConnectivity: values.DTHConnectivity,
          internetConnectivity: values.internetConnectivity,
        },
        volume: {
          gigabytes: values.diskQuota,
          objectsMegabytes: 0,
        },
      },
    });

    if (data !== null) {
      setOsProjectOrdered(true);
    }
  };

  return (
    <>
      {osProjectOrdered && (
        <SuccessModal
          title="Compute OS-Project Ordered"
          messageId="createOsProjectFulfilled"
          onSubmit={() => navigate('/products/compute')}
          onClose={() => navigate('/products/compute')}

        />
      )}
      <Container maxWidth="lg">
        <Typography variant="h4" color="primary" gutterBottom>Openstack Project Request</Typography>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, values }) => (
            <Form>
              <Grid
                container
                justifyContent="space-between"
                alignItems="flex-start"
              >
                <Grid item xs={12} md={6}>
                  <Typography>
                    <strong>Project Name: </strong>
                    {intl.formatMessage({ id: 'projectName' })}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormikTextField
                    autoFocus
                    name="osProjectName"
                    label="OS Project Name"
                    placeholder="Enter a name for your Openstack Project"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Divider sx={{ my: 2 }} />
                </Grid>

                {/* Project Selection */}
                <Grid item xs={12} md={6}>
                  <Typography>
                    <strong>Project: </strong>
                    {intl.formatMessage({ id: 'projectDescription' })}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <ProjectSelect setFilteredProducts={setFilteredProducts} />
                </Grid>
                <Grid item xs={12}>
                  <Divider sx={{ my: 2 }} />
                </Grid>

                {/* Product Selection */}
                <Grid item xs={12} md={6}>
                  <Typography>
                    <strong>Product: </strong>
                    {intl.formatMessage({ id: 'productDescription' })}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <ProductSelect filteredProducts={filteredProducts} />
                </Grid>
                <Grid item xs={12}>
                  <Divider sx={{ my: 2 }} />
                </Grid>

                {/* Cloud Selection */}
                <Grid item xs={12} md={6}>
                  <Typography>
                    <strong>Data Center: </strong>
                    {intl.formatMessage({ id: 'dataCenterDescription' })}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <CloudSelect />
                </Grid>
                <Grid item xs={12}>
                  <Divider sx={{ my: 2 }} />
                </Grid>

                {/* Resource Quota */}
                <ResourceQuota
                  unusedQuota={values.unusedQuota}
                  onWheel={numberInputOnWheelPreventChange}
                />

                {/* Network Connectivity */}
                <Grid item xs={12}>
                  <Divider sx={{ my: 2 }} />
                </Grid>
                <NetworkConnectivity externalNetworks={values.externalNetworks} />
                <Grid item xs={12}>
                  <Divider sx={{ my: 2 }} />
                </Grid>
                <Grid
                  container
                  justifyContent="end"
                >
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    label="Submit"
                  />
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Container>
    </>
  );
};

export default ComputeOrder;
