import { useState } from 'react';
import {
  TextField as TextFieldMUI,
  InputAdornment,
  IconButton,
} from '@mui/material';
import PropTypes from 'prop-types';
import { VisibilityIcon, VisibilityOffIcon } from '../icons/IconLibrary';

export const PasswordField = ({
  id,
  name,
  value,
  onChange,
  onBlur,
  label,
  placeholder,
  autoFocus,
  disabled,
  helperText,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <TextFieldMUI
      type={showPassword ? 'text' : 'password'}
      id={id || name}
      name={name}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      label={label}
      placeholder={placeholder}
      autoFocus={autoFocus}
      disabled={disabled}
      helperText={helperText}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              edge="end"
              tabIndex={-1}
            >
              {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

PasswordField.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  helperText: PropTypes.string,
};

PasswordField.defaultProps = {
  id: '',
  value: '',
  onChange: () => {},
  onBlur: () => {},
  placeholder: '',
  autoFocus: false,
  disabled: false,
  helperText: '',
};
