import { useRef } from 'react';
import validator from 'validator';
import * as Yup from 'yup';
import { useIntl } from 'react-intl';
import Auth from '../../../api/auth';
import useApiCall from '../../../hooks/useApiCall';
import FormikForm from '../../../components/Formik/Form/FormikForm';

const UserPasswordChange = () => {
  const intl = useIntl();
  const formRef = useRef(null);
  const updatePassword = useApiCall(Auth.updateUserPassword, 'userPasswordChanged');

  const fields = [
    {
      fieldType: 'password',
      name: 'currentPassword',
      label: 'Current Password',
    },
    {
      fieldType: 'password',
      name: 'newPassword',
      label: 'New Password',
    },
    {
      fieldType: 'password',
      name: 'confirmPassword',
      label: 'Confirm Password',
    },
  ];

  const initialValues = {
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  };

  const validationSchema = Yup.object().shape({
    currentPassword: Yup.string()
      .required('Required'),
    newPassword: Yup.string()
      .test(
        'Strong password',
        intl.formatMessage({ id: 'ERR_PASSWORD_INVALID' }),
        (value) => (value ? validator.isStrongPassword(value, { minLength: 10 }) : true),
      ).required('Required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('newPassword'), null], intl.formatMessage({ id: 'userPasswordMismatch' }))
      .required('Required'),
  });

  const handleSubmit = async (formData) => {
    const newPasswordData = {
      password: formData.currentPassword,
      newPassword: formData.newPassword,
    };

    const [data] = await updatePassword(newPasswordData);
    if (data !== null) {
      formRef.current.resetForm();
    }
  };

  return (
    <FormikForm
      formRef={formRef}
      fields={fields}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      submitLabel="Change Password"
    />
  );
};

export default UserPasswordChange;
