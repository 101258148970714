import { Grid } from '@mui/material';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Provision from '../../api/provision';
import { ReportsContext } from './ReportsContext';
import useApiCall from '../../hooks/useApiCall';
import { Select } from '../../components/InputFields';
import Table from '../../components/Table';

const TenantsReport = () => {
  const navigate = useNavigate();
  const getVirtualCloudsCall = useApiCall(Provision.fetchVirtualClouds);
  const {
    juiceGroup: {
      cloudId,
      setCloudId,
      osProjects,
      setOsProjects,
      clouds,
    },
  } = useContext(ReportsContext);

  const tenantFields = [
    { id: 'name', title: 'Name' },
    { id: 'juiceGroup', title: 'Juice Group' },
  ];

  const getVirtualClouds = async (selectedCloudId) => {
    const [data] = await getVirtualCloudsCall({ cloudId: selectedCloudId });

    if (data) {
      const projects = data.flatMap(
        (virtualCloud) => virtualCloud.projects.map((project) => ({
          key: project.id.toString(),
          onRowClick: () => navigate(`${selectedCloudId}/${virtualCloud.juice_group.name}/${project.name}`),
          data: [
            {
              field: 'name',
              value: project.name,
            },
            {
              field: 'juiceGroup',
              value: virtualCloud.juice_group.name,
            },
          ],
        })),
      );
      setOsProjects(projects);
    }
  };

  return (
    <Grid
      container
      direction="column"
      gap={2}
    >
      <Grid item>
        <Select
          name="cloudSelect"
          label="Select a cloud to see the available tenants"
          value={cloudId}
          onChange={(value) => {
            setCloudId(value);
            getVirtualClouds(value);
          }}
          menuItems={clouds.map((item) => ({
            value: item.id,
            label: item.name,
          }))}
        />
      </Grid>
      <Grid item>
        <Table
          header="Tenants Report"
          fields={tenantFields}
          rows={osProjects}
        />
      </Grid>
    </Grid>
  );
};

export default TenantsReport;
