export const FETCH_USER_PROFILE_STARTED = 'FETCH_USER_PROFILE_STARTED';
export const FETCH_USER_PROFILE_FULFILLED = 'FETCH_USER_PROFILE_FULFILLED';

export const fetchUserProfile = () => (
  { type: FETCH_USER_PROFILE_STARTED }
);

export const fetchUserProfileFulfilled = (data) => (
  { type: FETCH_USER_PROFILE_FULFILLED, payload: data }
);
