import { useEffect, useMemo, useState } from 'react';
import CodeBlock from '../../components/CodeBlock/CodeBlock';
import Modal from '../../components/Modal';
import useApiCall from '../../hooks/useApiCall';
import Provision from '../../api/provision';
import Table from '../../components/Table';
import { useCopyToClipboard } from '../../hooks/useCopyToClipboard';

const CaasCluster = () => {
  const copyToClipboard = useCopyToClipboard();

  const [clusters, setClusters] = useState([]);
  const [modalData, setModalData] = useState(null);

  const getCaasClusters = useApiCall(Provision.fetchCaasClustersV1);
  const getCaasClusterCheck = useApiCall(Provision.fetchCaasClusterCheck);

  useEffect(() => {
    async function getProductResources() {
      const [data] = await getCaasClusters();
      if (data) setClusters(data);
    }
    getProductResources();
  }, []);

  const handleRowClick = async (cluster) => {
    const [data] = await getCaasClusterCheck(cluster.name);
    if (data) setModalData({ cluster, logs: data });
  };

  const fields = [
    { id: 'name', title: 'Name' },
    { id: 'type', title: 'Type', type: 'options' },
    { id: 'version', title: 'Version' },
    { id: 'group', title: 'Group' },
    { id: 'cloud_id', title: 'Cloud' },
    { id: 'created', title: 'Created at' },
    { id: 'last_updated', title: 'Last updated' },
    { id: 'device_count', title: 'Device count' },
    { id: 'juice_group', title: 'Juice group' },
    { id: 'owner', title: 'Owner' },
  ];

  const rows = useMemo(() => clusters.map((cluster) => ({
    key: `${cluster.name}-${cluster.cloud_id}-${cluster.type}`,
    onRowClick: () => handleRowClick(cluster),
    data: [
      { field: 'name', value: cluster.name },
      { field: 'type', value: cluster.type },
      { field: 'version', value: cluster.version },
      { field: 'group', value: cluster.group },
      { field: 'cloud_id', value: cluster.cloud_id },
      { field: 'created', value: cluster.created },
      { field: 'last_updated', value: cluster.last_updated },
      { field: 'device_count', value: cluster.device_count },
      { field: 'juice_group', value: cluster.juice_group },
      { field: 'owner', value: cluster.owner },
    ],
  })), [clusters]);

  return (
    <>
      {modalData && (
        <Modal
          maxWidth="md"
          title={`Cluster Status Check: ${modalData.cluster?.name}`}
          onSubmit={() => copyToClipboard(modalData.logs)}
          onClose={() => setModalData(null)}
          submitLabel="Copy"
        >
          {modalData.logs && (
            <CodeBlock code={modalData.logs} language="log" />
          )}
        </Modal>
      )}
      <Table
        header="Clusters"
        fields={fields}
        rows={rows}
      />
    </>
  );
};

export default CaasCluster;
