import { useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';
import validator from 'validator';
import { Typography } from '@mui/material';
import useApiCall from '../../../hooks/useApiCall';
import Auth from '../../../api/auth';
import { TextField } from '../../../components/InputFields';
import { FormikForm } from '../../../components/Formik';
import Modal from '../../../components/Modal';
import { useCopyToClipboard } from '../../../hooks/useCopyToClipboard';

const ApiKeyForm = () => {
  const intl = useIntl();
  const formRef = useRef(null);
  const copyToClipboard = useCopyToClipboard();

  const [apiKey, setApiKey] = useState(null);

  const createApiKey = useApiCall(Auth.createApiKey, null, 'apiKeyGenerationErr');

  const fields = [
    {
      fieldType: 'password',
      name: 'password',
      label: 'Password',
    },
  ];

  const initialValues = {
    password: '',
  };

  const validationSchema = Yup.object({
    password: Yup.string()
      .test(
        'Strong password',
        intl.formatMessage({ id: 'ERR_PASSWORD_INVALID' }),
        (value) => (value ? validator.isStrongPassword(value, { minLength: 10 }) : true),
      ).required('Required'),
  });

  const handleSubmit = async ({ password }) => {
    const [data] = await createApiKey(password);

    if (data) {
      setApiKey(data.apiKey);
      formRef.current.resetForm();
    }
  };

  return (
    <>
      {apiKey && (
        <Modal
          title="Your API key is ready"
          onSubmit={() => copyToClipboard(apiKey)}
          onClose={() => setApiKey(null)}
          submitLabel="Copy"
        >
          <Typography variant="body1" gutterBottom>
            {intl.formatMessage({ id: 'APIkeyCopy1' })}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {intl.formatMessage({ id: 'APIkeyCopy2' })}
          </Typography>
          <TextField
            label="API key"
            name="APIkey"
            value={apiKey}
            InputProps={{ readOnly: true }}
          />
        </Modal>
      )}
      <FormikForm
        formRef={formRef}
        fields={fields}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        submitLabel="Generate API key"
      />
    </>
  );
};

export default ApiKeyForm;
