import { Grid } from '@mui/material';
import useMarkdownFile from '../../../hooks/useMarkdownFile';
import computeSpecification from '../../../markdowns/Compute/specification.md';
import SidebarNavigation from '../../../components/SidebarNavigation/SidebarNavigation';

const ComputeSpecification = () => {
  const { markdownContent } = useMarkdownFile(computeSpecification);

  const menuItems = [
    { title: 'Commercial Product', path: '#Commercial' },
    { title: 'Evaluation Product', path: '#Evaluation' },
  ];

  return (
    <Grid
      container
      justifyContent="space-between"
    >
      <Grid item xs={2}>
        <SidebarNavigation menuItems={menuItems} />
      </Grid>
      <Grid item xs={9}>
        {markdownContent}
      </Grid>
    </Grid>
  );
};

export default ComputeSpecification;
