import {
  ContentCopyIcon,
  AddIcon,
  EditIcon,
  SaveIcon,
  DeleteIcon,
  ApplyIcon,
  CancelIcon,
} from './IconLibrary';

export const buttonIcons = {
  Copy: ContentCopyIcon,
  Create: AddIcon,
  Add: AddIcon,
  Edit: EditIcon,
  Save: SaveIcon,
  Delete: DeleteIcon,
  Apply: ApplyIcon,
  Cancel: CancelIcon,
  Clear: CancelIcon,
};

export default buttonIcons;
