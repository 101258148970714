import {
  useEffect,
  useState,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { FormModal } from '../../../components/Formik';
import ProductUnassignment from './ProductUnassignment';
import useApiCall from '../../../hooks/useApiCall';
import Auth from '../../../api/auth';
import { filterDataUpdates } from '../../../helpers/objects';

const EditUserFormModal = ({
  onSubmit,
  onCancel,
  user,
  roles,
  canEditOrg,
}) => {
  const originalOrgId = useRef(user.organization?._id || '');

  const [confirmSave, setConfirmSave] = useState(false);
  const [userResources, setUserResources] = useState({});
  const [organizations, setOrganizations] = useState([]);
  const [selectedOrgId, setSelectedOrgId] = useState(user.organization?._id || '');

  const fetchUserResources = useApiCall(Auth.fetchV1UserResources);
  const fetchOrganizations = useApiCall(Auth.fetchV1Organizations);
  const updateUserCall = useApiCall(Auth.updateV2User);

  useEffect(() => {
    const getOrganizations = async () => {
      const [data] = await fetchOrganizations();
      if (data) setOrganizations(data);
    };

    const getUserResources = async () => {
      if (!userResources.products?.length) {
        const [data] = await fetchUserResources(user.uuid);
        if (data) setUserResources(data);
      }
    };

    if (canEditOrg) getOrganizations();
    getUserResources();
  }, []);

  useEffect(() => {
    const orgChanged = selectedOrgId !== originalOrgId.current;
    const hasProducts = userResources?.products?.length > 0;
    const shouldConfirmSave = orgChanged && hasProducts;

    setConfirmSave(shouldConfirmSave);
  }, [selectedOrgId]);

  const fields = [
    {
      fieldType: 'select',
      name: 'roles',
      label: 'Role',
      menuItems: Object.keys(roles).map((key) => ({
        value: key,
        label: roles[key],
      })),
    },
    ...(canEditOrg && organizations.length
      ? [{
        fieldType: 'select',
        name: 'organizationId',
        label: 'Organization',
        menuItems: organizations.map(({ _id, enterpriseName }) => ({
          value: _id,
          label: enterpriseName,
        })),
        onChange: (e) => setSelectedOrgId(e),
      }]
      : []),
    ...(confirmSave
      ? [{
        fieldType: ProductUnassignment,
        name: 'productUnassignment',
        resources: userResources,
      },
      ] : []),
  ];

  const initialValues = {
    roles: user.role || '',
    organizationId: user.organization?._id || '',
  };

  const validationSchema = Yup.object().shape({
    roles: Yup.string().required('Required'),
    organizationId: Yup.string().required('Required'),
  });

  const updateUser = async (formValues) => {
    onCancel();

    const updatedUser = filterDataUpdates(user, formValues);

    if (updatedUser.roles) {
      updatedUser.roles = updatedUser.roles.split();
    }

    const [data] = await updateUserCall({
      ...updatedUser,
      uuid: user.uuid,
    });

    if (data) {
      onSubmit();
    }
  };

  return (
    <FormModal
      title={`Edit user ${user.email}`}
      fields={fields}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={updateUser}
      onCancel={onCancel}
      submitLabel="Save"
    />
  );
};

EditUserFormModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  roles: PropTypes.objectOf(PropTypes.string).isRequired,
  canEditOrg: PropTypes.bool.isRequired,
  user: PropTypes.shape({
    email: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired,
    uuid: PropTypes.string.isRequired,
    organization: PropTypes.shape({
      _id: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default EditUserFormModal;
