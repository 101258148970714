import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import rbacCheck from '../../../../../rbac/rbacCheck';
import EditProductFormModal from './EditProductFormModal';
import OverviewTable from '../../../../../components/OverviewTable/OverviewTable';
import Summaries from './Summaries';
import ForecastTable from './ForecastsTable';
import DeleteFormModal from '../../../../../components/DeleteFormModal/DeleteFormModal';

import useApiCall from '../../../../../hooks/useApiCall';
import Auth from '../../../../../api/auth';
import Chip from '../../../../../components/InputFields/Chip';
import Provision from '../../../../../api/provision';

const Overview = ({
  product,
  activeResources,
  onChange,
}) => {
  const navigate = useNavigate();
  const userData = useSelector((state) => state.user);

  const [summariesModalOpened, setSummariesModalOpened] = useState(false);
  const [forecastModalOpened, setForecastModalOpened] = useState(false);
  const [editModalOpened, setEditModalOpened] = useState(false);
  const [deleteModalOpened, setDeleteModalOpened] = useState(false);
  const [juiceTagsProduct, setJuiceTagsProduct] = useState(null);

  const deleteProductCall = useApiCall(Auth.deleteV2Product);
  const getJuiceGroupCall = useApiCall(Provision.fetchJuiceGroupByName);

  const canSeeForecast = rbacCheck(userData.roles, '/forecast:VIEW');
  const canEditProduct = rbacCheck(userData.roles, '/products:PUT');
  const canDeleteProduct = ['compute', 'caasonopenstack', 'caasonbaremetal'].includes(product.type)
    && rbacCheck(userData.roles, '/products:DELETE')
    && !activeResources;

  useEffect(() => {
    const getJuiceTagsProduct = async () => {
      const [data] = await getJuiceGroupCall(product.details?.juiceGroup);
      if (data?.data?.tags) {
        setJuiceTagsProduct(data.data.tags);
      }
    };

    if (product.details?.juiceGroup) getJuiceTagsProduct();
  }, [product]);

  const deleteProduct = async () => {
    setDeleteModalOpened(false);
    const [data] = await deleteProductCall(product._id);

    if (data) {
      navigate(-1);
    }
  };

  const cantDeleteProductMsg = {
    caasonopenstack: 'productDeleteDisabledCaasClusters',
    compute: 'productDeleteDisabledOsProjects',
    evaluation: 'productDeleteDisabledOsProjects',
    caasonbaremetal: 'productDeleteDisabledCaasBm',
  };

  const cantDeleteProductMessage = (productType) => {
    if (activeResources) {
      return cantDeleteProductMsg[productType];
    }
    if (productType === 'evaluation') {
      return 'productDeleteUndoable';
    }
    return 'productDeleteDisabled';
  };

  const rows = [
    {
      title: 'Description',
      value: product.description,
    },
    {
      title: 'Type',
      value: product.type,
    },
    {
      title: 'Organization',
      component: (
        <Link to={`/organizations/${product.organizationId}`}>
          {product.organization?.enterpriseName}
        </Link>
      ),
    },
    {
      title: 'Project',
      component: (
        <Link to={`/projects/${product.project?._id}`}>
          {product.project?.name}
        </Link>
      ),
    },
    {
      title: 'Juice Group',
      value: product.details?.juiceGroup,
    },
    {
      title: 'Approved',
      value: product.approved ? 'True' : 'False',
    },
    {
      title: 'Tags',
      component:
        juiceTagsProduct?.map((tag) => (
          <Chip
            key={tag}
            label={tag}
            sx={{ ml: 1 }}
          />
        )),
    },
  ];

  return (
    <>
      {forecastModalOpened && (
        <ForecastTable
          product={product}
          onSubmit={() => {
            setForecastModalOpened(false);
            onChange();
          }}
          onClose={() => setForecastModalOpened(false)}
        />
      )}
      {summariesModalOpened && (
        <Summaries
          juiceGroup={product.details.juiceGroup}
          onClose={() => setSummariesModalOpened(false)}
        />
      )}
      {editModalOpened && (
        <EditProductFormModal
          product={product}
          onSubmit={onChange}
          onCancel={() => setEditModalOpened(false)}
        />
      )}
      {deleteModalOpened && (
        <DeleteFormModal
          fieldName="Product"
          fieldValue={product.name}
          messages={['productDeleteMessage']}
          onSubmit={deleteProduct}
          onCancel={() => setDeleteModalOpened(false)}
        />
      )}
      <OverviewTable
        header={product.name}
        rows={rows}
        editAction={{
          onClick: () => setEditModalOpened(true),
          disabled: !canEditProduct,
          tooltip: 'editSafescarfProductDisabled',
        }}
        deleteAction={{
          onClick: () => setDeleteModalOpened(true),
          disabled: !canDeleteProduct,
          tooltip: cantDeleteProductMessage(product.type),
        }}
        extraActions={
          product.type !== 'caasonbaremetal'
            ? [
              {
                label: 'Summaries',
                onClick: () => setSummariesModalOpened(true),
              },
              {
                label: 'Forecast',
                onClick: () => setForecastModalOpened(true),
                disabled: !canSeeForecast && product.type?.includes('compute', 'caasonopenstack'),
              },
            ]
            : []
        }
      />
    </>
  );
};

Overview.propTypes = {
  product: PropTypes.shape({
    _id: PropTypes.string,
    type: PropTypes.string,
    description: PropTypes.string,
    approved: PropTypes.bool,
    details: PropTypes.shape({
      juiceGroup: PropTypes.string,
      tags: PropTypes.arrayOf(PropTypes.string),
    }),
    project: PropTypes.shape({
      name: PropTypes.string,
      _id: PropTypes.string,
    }),
    organization: PropTypes.shape({
      enterpriseName: PropTypes.string,
    }),
    organizationId: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  activeResources: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Overview;
