import {
  useState,
  useRef,
  useMemo,
  useCallback,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { Popover, Grid } from '@mui/material';
import debounce from 'lodash.debounce';
import { SearchField } from '../InputFields';
import FormikForm from '../Formik/Form/FormikForm';

const Search = ({
  setCurrentPage,
  setSearchedRows,
  rows,
  fields,
  advancedSearch,
  setFilter,
}) => {
  const searchFieldRef = useRef(null);
  const formRef = useRef(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const [searchInput, setSearchInput] = useState('');

  const initialFilters = useMemo(() => fields.reduce((acc, field) => ({
    ...acc,
    [field.id]: '',
  }), { search: '' }), [fields]);

  const [filters, setFilters] = useState(initialFilters);

  const hasFilters = useMemo(() => Object.entries(filters).some(
    ([key, value]) => key !== 'search' && value.length > 0 && value[0] !== '',
  ), [filters]);

  const filterRows = useCallback((query) => {
    const lowerQuery = query.toLowerCase();

    return rows.filter((row) => row.data.some(({ value }) => {
      const lowerValue = String(value).toLowerCase();
      return lowerValue.includes(lowerQuery);
    }));
  }, [rows]);

  useEffect(() => {
    if (!hasFilters) {
      setSearchedRows(filters.search.length > 2 ? filterRows(filters.search) : rows);
    }
  }, [filters.search, rows, hasFilters]);

  const extractMenuItems = useCallback((fieldId) => {
    const uniqueValues = new Set(
      rows.flatMap(({ data }) => data
        .filter(({ field, value }) => field === fieldId && value)
        .flatMap(({ value }) => (Array.isArray(value) ? value.filter(Boolean) : [value]))),
    );

    return [...uniqueValues]
      .map((value) => ({ value, label: value }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [rows]);

  const joinFilters = useMemo(() => Object.entries(filters)
    .filter(([key, value]) => key !== 'search' && value.length > 0 && value[0] !== '')
    .map(([key, value]) => `${key}: ${value.join(', ')}`)
    .join('; '), [filters]);

  const debouncedSetFilter = useCallback(debounce((value) => {
    setFilters((prev) => ({ ...prev, search: value }));
    setFilter(value);
    setCurrentPage(0);
  }, 100), [setFilter, setCurrentPage]);

  useEffect(() => () => debouncedSetFilter.cancel(), [debouncedSetFilter]);

  const filterRowsByAdvancedSearch = useCallback((updatedFilters) => rows.filter(
    (row) => fields.every((field) => {
      const filterValues = updatedFilters[field.id];
      if (!filterValues?.length || filterValues[0] === '') return true;

      return row.data.some((item) => {
        if (item.field !== field.id) return false;

        const lowerValue = String(item.value).toLowerCase();
        return field.type === 'options'
          ? filterValues.some((filter) => lowerValue === filter.toLowerCase())
          : filterValues.some((filter) => lowerValue.includes(filter.toLowerCase()));
      });
    }),
  ), [fields, rows]);


  const formikFields = useMemo(() => fields.map(({ id, title, type }) => {
    let fieldType = 'text';
    let menuItems = [];

    if (type === 'options') {
      menuItems = extractMenuItems(id);
      fieldType = menuItems.length > 2 ? 'multiSelect' : 'select';
    }

    return {
      fieldType,
      name: id,
      label: title,
      menuItems,
    };
  }), [fields, rows]);

  const getInitialFormikValues = useCallback(() => {
    const initialValues = fields.reduce((acc, { id }) => {
      const fieldValue = formikFields.find((f) => f.name === id);
      const value = fieldValue?.fieldType === 'multiSelect'
        ? filters[id] || []
        : filters[id]?.[0] || '';

      return { ...acc, [id]: value };
    }, { search: filters.search || '' });

    return initialValues;
  }, [fields, filters, formikFields]);

  const handleApplyFilters = (values) => {
    const updatedFilters = Object.fromEntries(
      Object.entries(values).map(([key, value]) => (
        Array.isArray(value) ? [key, value.filter(Boolean)] : [key, value ? [value] : []])),
    );

    setFilters((prevFilters) => ({ ...prevFilters, ...updatedFilters, search: '' }));
    setSearchedRows(filterRowsByAdvancedSearch(updatedFilters));
    setCurrentPage(0);
    setAnchorEl(null);
  };

  const handleClearFilters = () => {
    formRef.current?.resetForm({ values: initialFilters });
    setFilters(initialFilters);
    setSearchedRows(rows);
    setCurrentPage(0);
    setSearchInput('');
  };

  return (
    <>
      <SearchField
        name="search"
        label={hasFilters ? '' : 'Search'}
        value={hasFilters ? joinFilters : searchInput}
        disabled={hasFilters}
        onChange={(e) => {
          const { value } = e.target;
          setSearchInput(value);
          debouncedSetFilter(value);
        }}
        inputRef={searchFieldRef}
        onFilterClick={advancedSearch ? () => setAnchorEl(searchFieldRef.current) : undefined}
        advancedSearch={advancedSearch}
      />
      {advancedSearch && (
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        >
          <Grid container p={2}>
            <FormikForm
              formRef={formRef}
              fields={formikFields}
              initialValues={getInitialFormikValues()}
              onSubmit={handleApplyFilters}
              onCancel={handleClearFilters}
              submitLabel="Apply"
              cancelLabel="Clear"
            />
          </Grid>
        </Popover>
      )}
    </>
  );
};

Search.propTypes = {
  setCurrentPage: PropTypes.func.isRequired,
  setSearchedRows: PropTypes.func.isRequired,
  rows: PropTypes.arrayOf(PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({
      field: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.string,
        PropTypes.number,
        PropTypes.array,
      ]),
    })),
  })).isRequired,
  fields: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  })).isRequired,
  advancedSearch: PropTypes.bool.isRequired,
  setFilter: PropTypes.func.isRequired,
};

export default Search;
