import { ReactKeycloakProvider } from '@react-keycloak/web';
import { useDispatch } from 'react-redux';
import { RouterProvider } from 'react-router-dom';

import { ThemeProvider } from '@mui/material';
import { fetchUserData } from './redux/user/actions';
import { router } from './AppRouter';
import { createCustomTheme } from './muiTheme';


import keycloak from './keycloak';

import http from './api/httpClient';
import { setError } from './redux/status/actions';
import useAppContext from './hooks/useAppContext';

const App = () => {
  const dispatch = useDispatch();
  const { theme } = useAppContext();
  const customTheme = createCustomTheme(theme);


  async function onKeycloakEvent(event) {
    switch (event) {
      case 'onAuthSuccess':
        http.interceptors.request.use(async (config) => {
          const { headers } = config;
          headers.Authorization = `Bearer ${keycloak.token}`;
          return { ...config, headers };
        });
        dispatch(fetchUserData({ email: keycloak.tokenParsed.email }));
        break;
      case 'onInitError':
        dispatch(setError('KEYCLOAK_INIT_ERROR'));
        break;
      default:
        break;
    }
  }

  return (
    <ThemeProvider theme={customTheme}>
      <ReactKeycloakProvider
        authClient={keycloak}
        initOptions={{
          onLoad: 'check-sso',
          checkLoginIframe: false,
          silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`,
          messageReceiveTimeout: 5000,
        }}
        onEvent={(e) => onKeycloakEvent(e)}
      >
        <RouterProvider router={router} />
      </ReactKeycloakProvider>
    </ThemeProvider>
  );
};

export default App;
