import PropTypes from 'prop-types';
import {
  Grid,
  Typography,
  TextField,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import * as Yup from 'yup';
import Provision from '../../api/provision';
import useApiCall from '../../hooks/useApiCall';
import { FormModal } from '../../components/Formik';
import Button from '../../components/Buttons/Button';
import Tags from '../../components/Tags/Tags';

const JuiceGroupInfoFormModal = ({
  juiceGroupInfo, setJuiceGroupInfo, onClose,
}) => {
  const { juiceGroup } = useParams();

  const [geoRedundancyState, setGeoRedundancyState] = useState(juiceGroupInfo.geoRedundancy);
  const [newExternalService, setNewExternalService] = useState('');
  const [servicesToRemove, setServicesToRemove] = useState([]);
  const [localExternalServices, setLocalExternalServices] = useState(
    juiceGroupInfo.externalServices || [],
  );

  const setGeoRedundancyCall = useApiCall(Provision.setGeoRedundancy);
  const addExternalServiceCall = useApiCall(Provision.addExternalService);
  const removeExternalServiceCall = useApiCall(Provision.removeExternalService);

  useEffect(() => {
    setLocalExternalServices(juiceGroupInfo.externalServices || []);
    setGeoRedundancyState(juiceGroupInfo.geoRedundancy);
  }, []);

  const addExternalServices = () => {
    const serviceName = newExternalService.trim();

    if (serviceName && !localExternalServices.includes(serviceName)) {
      setLocalExternalServices([...localExternalServices, serviceName]);
      setNewExternalService('');
    }
  };

  const handleSubmit = async () => {
    if (geoRedundancyState !== juiceGroupInfo.geoRedundancy) {
      const [data] = await setGeoRedundancyCall({
        juiceGroup,
        geoRedundancy: geoRedundancyState,
      });
      if (data) setJuiceGroupInfo(data);
    }

    const servicesToAdd = localExternalServices.filter(
      (service) => !juiceGroupInfo.externalServices?.includes(service),
    );

    const addServicesPromises = servicesToAdd.map((service) => {
      if (service.trim()) {
        return addExternalServiceCall({
          juiceGroup,
          externalService: service,
        }).then(([data]) => {
          if (data) {
            setJuiceGroupInfo(data);
          }
        });
      }
      return null;
    });

    const removeServicesPromises = servicesToRemove.map(
      (service) => removeExternalServiceCall({
        juiceGroup,
        externalService: service,
      }).then(([data]) => {
        if (data) setJuiceGroupInfo(data);
      }),
    );

    await Promise.all([...addServicesPromises, ...removeServicesPromises]);
    onClose();
  };

  const removeExternalService = (serviceToRemove) => {
    if (juiceGroupInfo.externalServices?.includes(serviceToRemove)) {
      setServicesToRemove([...servicesToRemove, serviceToRemove]);
    }
    setLocalExternalServices(localExternalServices.filter(
      (s) => s !== serviceToRemove,
    ));
  };

  const fields = [
    {
      fieldType: Typography,
      name: 'geoRedundancyTitle',
      fieldContent: (
        <>Geo Redundancy State:</>
      ),
    },
    {
      fieldType: 'switch',
      name: 'geoRedundancy',
      label: {
        on: 'Active',
        off: 'Inactive',
      },
      checked: geoRedundancyState,
      onChange: () => setGeoRedundancyState((prev) => !prev),
    },
    {
      fieldType: Tags,
      name: 'externalServices',
      label: 'External Services',
      tags: localExternalServices,
      onDelete: (tag) => removeExternalService(tag),
    },
    {
      fieldType: Grid,
      name: 'externalServiceInput',
      fieldContent: (
        <Grid
          container
          spacing={1}
          alignItems="center"
          pb={2}
        >
          <Grid item xs>
            <TextField
              label="Add New External Service"
              value={newExternalService}
              onChange={(e) => setNewExternalService(e.target.value)}
            />
          </Grid>
          <Grid item>
            <Button
              disabled={!newExternalService.trim()}
              onClick={addExternalServices}
              label="Add"
            />
          </Grid>
        </Grid>
      ),
    },
  ];

  const initialValues = {
    geoRedundancy: geoRedundancyState,
  };

  const validationSchema = Yup.object().shape({
    externalService: Yup.string()
      .trim()
      .test(
        'is-unique',
        'External Service already exists',
        (value) => !juiceGroupInfo.externalServices?.includes(value),
      ),
  });

  return (
    <FormModal
      title="Edit Juice Group Info"
      fields={fields}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      onCancel={onClose}
      submitLabel="Save"
    />
  );
};

JuiceGroupInfoFormModal.propTypes = {
  juiceGroupInfo: PropTypes.shape({
    geoRedundancy: PropTypes.bool,
    externalServices: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  setJuiceGroupInfo: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default JuiceGroupInfoFormModal;
