import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import useApiCall from '../../../hooks/useApiCall';
import Auth from '../../../api/auth';
import rbacCheck from '../../../rbac/rbacCheck';
import { FormModal } from '../../../components/Formik';
import { filterDataUpdates } from '../../../helpers/objects';

const EditBusinessUnitFormModal = ({
  businessUnit, onSubmit, onCancel,
}) => {
  const userData = useSelector((state) => state.user);
  const [businessUnitManagers, setBusinessUnitManagers] = useState(null);

  const getUsersCall = useApiCall(Auth.fetchV2Users);
  const updateBusinessUnitCall = useApiCall(Auth.updateV1BusinessUnit);

  const canEditBusinessUnitManager = rbacCheck(userData.roles, '/business-units:PUT:BUM');

  useEffect(() => {
    const getBuManagers = async () => {
      const [data] = await getUsersCall({
        organizationId: businessUnit.organizationId,
        roles: 'BUSINESS_UNIT_MANAGER',
      });

      if (data) {
        const activatedBuManagers = data.filter((buManager) => buManager.activated);
        setBusinessUnitManagers(activatedBuManagers);
      }
    };

    getBuManagers();
  }, []);

  const fields = [
    {
      fieldType: 'text',
      name: 'name',
      label: 'Name',
    },
    {
      fieldType: 'text',
      name: 'description',
      label: 'Description',
    },
    ...(canEditBusinessUnitManager ? [
      {
        fieldType: 'select',
        name: 'businessUnitManager',
        label: 'Business Unit Manager',
        menuItems: businessUnitManagers?.map((manager) => ({
          value: manager._id,
          label: manager.email,
        })),
      },
    ] : []),
  ];

  const initialValues = {
    name: businessUnit?.name || '',
    description: businessUnit?.description || '',
    businessUnitManager: businessUnit?.businessUnitManager?._id || null,
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    description: Yup.string().required('Required'),
  });

  const updateBusinessUnit = async (formValues) => {
    onCancel();

    const updatedBusinessUnit = filterDataUpdates(businessUnit, formValues);
    const [data] = await updateBusinessUnitCall({
      ...updatedBusinessUnit,
      _id: businessUnit._id,
    });

    if (data) {
      onSubmit();
    }
  };

  return (
    <FormModal
      title={`Edit business unit ${businessUnit.name}`}
      fields={fields}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={updateBusinessUnit}
      onCancel={onCancel}
      submitLabel="Save"
    />
  );
};

EditBusinessUnitFormModal.propTypes = {
  businessUnit: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    organizationId: PropTypes.string.isRequired,
    businessUnitManager: PropTypes.shape({
      _id: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
    }),
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default EditBusinessUnitFormModal;
