import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, Outlet } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';

import safescarfLogo from './safescarf_logo.png';

import { fetchUserProfile } from '../../../../../redux/safescarf/userProfile/actions';
import rbacCheck from '../../../../../rbac/rbacCheck';
import SidebarNavigation from '../../../../../components/SidebarNavigation/SidebarNavigation';

const Instance = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const currentPath = pathname.split('/')[3];

  const userData = useSelector((state) => state.user);
  const safeScarfUserProfile = useSelector((state) => state.safescarf.userProfile);
  const isLoading = useSelector((state) => state.status.loading);

  const canSeeUsers = rbacCheck(userData.roles, '/products/safescarf/users:VIEW');

  useEffect(() => {
    dispatch(fetchUserProfile());
  }, []);

  const sidenavItems = [
    { title: 'Overview' },
    ...(canSeeUsers ? [{ title: 'Users', path: 'users' }] : []),
    { title: 'Machine Accounts', path: 'machine-accounts' },
    { title: 'Product Types', path: 'product-types' },
    { title: 'Products', path: 'products' },
    { title: 'Documentation', path: '/docs/SafeSCARF%20product' },
    { title: 'Contact Support', path: '/contact-us' },
  ];

  return Object.keys(safeScarfUserProfile).length > 0 ? (
    <Grid
      container
      direction="column"
      className="content-with-header"
    >
      <Grid item>
        <Typography variant="h4" color="primary">
          <img
            src={safescarfLogo}
            alt=""
            style={{ width: '2rem', marginBottom: '-0.5rem' }}
          />
          &nbsp;SafeSCARF
        </Typography>
      </Grid>
      <Grid item paddingTop="2rem">
        <Grid
          container
          justifyContent="space-between"
        >
          <Grid item xs={2}>
            <SidebarNavigation menuItems={sidenavItems} currentPath={currentPath} />
          </Grid>
          <Grid item xs={9}>
            <Outlet />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  ) : (
    <>
      <Typography
        variant="h2"
        color="primary"
        style={{ display: isLoading ? 'none' : 'flex', justifyContent: 'center' }}
      >
        You are not assigned to safescarf.
      </Typography>
      <Typography variant="h6" style={{ display: isLoading ? 'none' : 'flex', justifyContent: 'center' }}>
        Please contact the owner of the product.
      </Typography>
    </>
  );
};

export default Instance;
