import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import InstanceStatus from './Instance/InstanceStatus';
import Instance from './Instance/Instance';
import Page404 from '../../../Error/Page404';

import { fetchInstance } from '../../../../redux/safescarf/instance/actions';

const SafescarfProduct = () => {
  const dispatch = useDispatch();
  const instance = useSelector((state) => state.safescarf.instance.data);

  useEffect(() => {
    dispatch(fetchInstance());
  }, []);

  if (instance.status) {
    if (instance.status === 404) {
      return <Page404 />;
    }

    if (instance.status === 200) {
      if (parseFloat(instance.data.progress) !== 1.0) {
        return <InstanceStatus progress={instance.data.progress} />;
      }

      if (parseFloat(instance.data.progress) === 1.0 && instance.data.api) {
        return <Instance />;
      }
    }
  }

  return null;
};

export default SafescarfProduct;
