import { combineEpics } from 'redux-observable';

import instanceEpics from './instance/epics';
import usersEpics from './users/epics';
import machineAccountsEpics from './machineAccounts/epics';
import rolesEpics from './roles/epics';
import productTypesEpics from './productTypes/epics';
import productTypeMembersEpics from './productTypeMembers/epics';
import productsEpics from './products/epics';
import productMembersEpics from './productMembers/epics';
import userProfileEpics from './userProfile/epics';

export default combineEpics(
  instanceEpics,
  usersEpics,
  machineAccountsEpics,
  rolesEpics,
  productTypesEpics,
  productTypeMembersEpics,
  productsEpics,
  productMembersEpics,
  userProfileEpics,
);
