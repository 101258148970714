import PropTypes from 'prop-types';
import {
  Accordion as AccordionMUI,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@mui/material';
import { ExpandMoreIcon } from '../icons/IconLibrary';

const Accordion = ({
  defaultExpanded,
  title,
  details,
  children,
}) => (
  <AccordionMUI defaultExpanded={defaultExpanded}>
    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
      <Typography variant="h6" fontWeight="bold">
        {title}
      </Typography>
    </AccordionSummary>
    <AccordionDetails sx={{ pl: 2 }}>
      {details}
      {children}
    </AccordionDetails>
  </AccordionMUI>
);

Accordion.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  details: PropTypes.node,
  children: PropTypes.node,
  defaultExpanded: PropTypes.bool,
};

Accordion.defaultProps = {
  details: undefined,
  children: undefined,
  defaultExpanded: false,
};

export default Accordion;
