import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Container, Grid } from '@mui/material';
import EditContactFormModal from './EditContactFormModal';
import DeleteFormModal from '../../components/DeleteFormModal/DeleteFormModal';
import OverviewTable from '../../components/OverviewTable/OverviewTable';
import useApiCall from '../../hooks/useApiCall';
import Provision from '../../api/provision';
import rbacCheck from '../../rbac/rbacCheck';

const lookupType = {
  stakeHolder: 'Stakeholder',
  serviceOwner: 'Service Owner',
  tenantOwner: 'Tenant Owner',
  onboardingSupport: 'Onboarding Support',
};

const ContactDetails = () => {
  const { juiceGroup, contactId } = useParams();
  const navigate = useNavigate();
  const roles = useSelector((state) => state.user.roles);

  const [contactDetail, setContactDetail] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const getJuiceGroupInfoCall = useApiCall(Provision.fetchJuiceGroupInfo);
  const deleteContactCall = useApiCall(Provision.removeContact, 'ContactRemoved');

  const canAddEditContacts = rbacCheck(roles, '/products:CONTACTS_PATCH');
  const canDeleteContacts = rbacCheck(roles, '/products:CONTACTS_DELETE');

  const getContactDetails = async () => {
    const [data] = await getJuiceGroupInfoCall({ juiceGroup });
    if (data) {
      const contact = data.contacts.find((c) => c._id === contactId);
      setContactDetail(contact);
    }
  };

  useEffect(() => {
    getContactDetails();
  }, []);

  const deleteContact = async () => {
    setIsDeleteModalOpen(false);
    const [data] = await deleteContactCall({ id: contactId, juiceGroup });

    if (data) {
      navigate(-1);
    }
  };

  if (!contactDetail) return null;

  const rows = [
    {
      title: 'Name',
      value: contactDetail.name,
    },
    {
      title: 'Role',
      value: lookupType[contactDetail.type] || 'Unknown',
    },
    {
      title: 'Phone',
      value: contactDetail.phoneNumber,
    },
    {
      title: 'Slack',
      value: contactDetail.slackChannel,
    },
  ];

  return (
    <>
      {isEditModalOpen && (
        <EditContactFormModal
          contact={contactDetail}
          juiceGroup={juiceGroup}
          onSubmit={getContactDetails}
          onCancel={() => setIsEditModalOpen(false)}
        />
      )}
      {isDeleteModalOpen && (
        <DeleteFormModal
          fieldName="Contact"
          fieldValue={contactDetail.email}
          onSubmit={deleteContact}
          onCancel={() => setIsDeleteModalOpen(false)}
        />
      )}
      <Grid
        container
        className="content-with-header"
        direction="column"
        rowGap={4}
      >
        <Grid item>
          <Container maxWidth="lg">
            <OverviewTable
              header={contactDetail.email}
              rows={rows}
              editAction={{
                onClick: () => setIsEditModalOpen(true),
                disabled: !canAddEditContacts,
                tooltip: 'contactEditDisabled',
              }}
              deleteAction={{
                onClick: () => setIsDeleteModalOpen(true),
                disabled: !canDeleteContacts,
                tooltip: 'contactDeleteDisabled',
              }}
            />
          </Container>
        </Grid>
      </Grid>
    </>
  );
};

export default ContactDetails;
