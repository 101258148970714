import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { DoneIcon } from '../icons/IconLibrary';
import Modal from '.';

const SuccessModal = ({
  messageId,
  title,
  onSubmit,
  onClose,
}) => {
  const intl = useIntl();

  return (
    <Modal
      hideActions
      title={title}
      onSubmit={onSubmit}
      onClose={onClose}
      submitLabel="Okay"
    >
      <DoneIcon
        sx={{
          fontSize: '7rem',
          color: 'limegreen',
          display: 'flex',
          margin: 'auto',
        }}
      />
      <Typography>{intl.formatMessage({ id: messageId })}</Typography>
    </Modal>
  );
};

SuccessModal.propTypes = {
  messageId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default SuccessModal;
