import {
  FETCH_INSTANCE_FULFILLED,
} from './actions';

const initialState = { data: {} };

const instanceReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_INSTANCE_FULFILLED:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};

export { instanceReducer };

