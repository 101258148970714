import PropTypes from 'prop-types';
import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Collapse,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { useState, useEffect, useCallback } from 'react';
import { ExpandLessIcon, ExpandMoreIcon } from '../icons/IconLibrary';

const SidebarNavigationItem = ({
  item, currentPath, level,
}) => {
  const hasSubMenu = item.children?.length > 0;
  const isSelected = currentPath === (item.path || '');
  const [isOpen, setIsOpen] = useState(isSelected);

  const isAncestor = useCallback((path, menuItem) => {
    if (path === menuItem.path) return true;
    return menuItem.children?.some((child) => isAncestor(path, child));
  }, []);

  useEffect(() => {
    if (hasSubMenu && isAncestor(currentPath, item)) {
      setIsOpen(true);
    } else if (!isAncestor(currentPath, item)) {
      setIsOpen(false);
    }
  }, [currentPath]);

  return (
    <>
      <ListItem disablePadding>
        <ListItemButton
          component={Link}
          to={item.path}
          onClick={() => setIsOpen((prevOpen) => !prevOpen)}
          sx={{
            pl: level * 2 + 2,
            borderLeftColor: isSelected ? 'primary.main' : 'transparent',
          }}
        >
          <ListItemText
            primary={item.title}
            primaryTypographyProps={{ color: isSelected ? 'primary' : 'inherit' }}
          />
          {hasSubMenu && (isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />)}
        </ListItemButton>
      </ListItem>

      {hasSubMenu && (
        <Collapse
          in={isOpen}
          timeout="auto"
          unmountOnExit
        >
          <List component="div" disablePadding>
            {item.children.map((child) => (
              <SidebarNavigationItem
                key={child.title}
                item={child}
                currentPath={currentPath}
                level={level + 1}
              />
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
};

SidebarNavigationItem.propTypes = {
  item: PropTypes.shape({
    title: PropTypes.string.isRequired,
    path: PropTypes.string,
    children: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  currentPath: PropTypes.string.isRequired,
  level: PropTypes.number,
};

SidebarNavigationItem.defaultProps = {
  level: 0,
};

const SidebarNavigation = ({ menuItems, currentPath }) => (
  <List component="nav">
    {menuItems.map((item) => (
      <SidebarNavigationItem
        key={item.title}
        item={item}
        currentPath={currentPath}
      />
    ))}
  </List>
);

SidebarNavigation.propTypes = {
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      path: PropTypes.string,
      children: PropTypes.arrayOf(PropTypes.object),
    }),
  ).isRequired,
  currentPath: PropTypes.string,
};

SidebarNavigation.defaultProps = {
  currentPath: '',
};

export default SidebarNavigation;
