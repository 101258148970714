import { Container } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import Modal from '../../../components/Modal';
import OverviewTable from '../../../components/OverviewTable/OverviewTable';
import { requestFailed, requestFulfilled, requestStarted } from '../../../redux/status/actions';
import Caas from '../../../api/caas';
import { enqueueSnackbar } from '../../../redux/notifier/actions';
import Button from '../../../components/Buttons/Button';
import { useCopyToClipboard } from '../../../hooks/useCopyToClipboard';
import CodeBlock from '../../../components/CodeBlock/CodeBlock';

const CaasCluster = () => {
  const { clusterName } = useParams();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const copyToClipboard = useCopyToClipboard();

  const [cluster, setCluster] = useState(null);
  const [kubeconfig, setKubeconfig] = useState(null);
  const [kubeconfigModalOpened, setKubeconfigModalOpened] = useState(false);

  useEffect(() => {
    const getClusterDetails = async () => {
      try {
        dispatch(requestStarted());
        const response = await Caas.getClusterDetails(uuidv4());
        setCluster(response.data);
        dispatch(requestFulfilled());
      } catch (error) {
        dispatch(requestFailed());
      }
    };
    getClusterDetails();
  }, [clusterName]);

  const handleGetKubeConfig = async () => {
    try {
      dispatch(requestStarted());
      const response = await Caas.getClusterKubeConfig(uuidv4());
      setKubeconfig(response.data.value);
      dispatch(requestFulfilled());
      setKubeconfigModalOpened(true);
    } catch (error) {
      dispatch(requestFailed());
      dispatch(enqueueSnackbar({
        message: 'CaasClusterKubeconfigGetErr',
        options: { variant: 'error' },
      }));
    }
  };

  const rows = [
    {
      title: 'Owner',
      value: cluster.owner.email,
    },
    {
      title: 'Cloud',
      value: cluster.cloud,
    },
    {
      title: 'Environment',
      value: cluster.environment,
    },
    {
      title: 'Kubernetes Version',
      value: cluster.kubernetesVersion,
    },
    {
      title: 'Kube Config',
      component: (
        <Button
          variant="outlined"
          disabled={cluster.owner.email !== user.email}
          onClick={handleGetKubeConfig}
          icon="Copy"
          label="Copy Kube Config"
        />
      ),
    },
    {
      title: 'Groups',
      value: Object.keys(cluster.workerGroups).length,
    },
    {
      title: 'Status',
      value: cluster.status.operationStatus,
    },
  ];

  return cluster ? (
    <Container maxWidth="md">
      {kubeconfigModalOpened && (
        <Modal
          maxWidth="lg"
          title="Kube Config"
          onSubmit={() => copyToClipboard(kubeconfig)}
          onClose={() => setKubeconfigModalOpened(false)}
          submitLabel="Copy"
        >
          <CodeBlock code={kubeconfig} language="sh" />
        </Modal>
      )}
      <OverviewTable
        header={`${cluster.name} Cluster Details`}
        rows={rows}
      />
    </Container>
  ) : null;
};

export default CaasCluster;
