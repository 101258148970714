import { Typography, LinearProgress } from '@mui/material';
import { PropTypes } from 'prop-types';
import { Switch } from '../InputFields';
import Chip from '../InputFields/Chip';

const CellComponents = ({ cell }) => {
  if (cell.type === 'switch') {
    return <Switch {...cell.props} />;
  }
  if (cell.type === 'tags') {
    return cell.value?.map((tag) => (
      <Chip
        key={tag}
        label={tag}
        sx={{ ml: '.1rem' }}
      />
    )) || null;
  }
  if (cell.type === 'progressBar') {
    return (
      <div style={{ display: 'flex' }}>
        <div style={{ width: '100%', marginRight: '1rem' }}>
          <LinearProgress variant="determinate" value={cell.value} />
        </div>
        <Typography variant="body2">
          {`${cell.value}%`}
        </Typography>
      </div>
    );
  }
  return cell.value || 'N/A';
};

CellComponents.propTypes = {
  cell: PropTypes.shape({
    type: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.object,
      PropTypes.arrayOf(PropTypes.string),
    ]),
    props: PropTypes.shape({}),
  }).isRequired,
};

export default CellComponents;
