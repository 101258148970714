import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { FormModal } from '../../../../../components/Formik';
import { createProduct } from '../../../../../redux/safescarf/products/actions';
import { fetchProductTypes } from '../../../../../redux/safescarf/productTypes/actions';

const CreateProductFormModal = ({ onSubmit, onCancel }) => {
  const dispatch = useDispatch();
  const productTypes = useSelector((state) => state.safescarf.productTypes);

  useEffect(() => {
    if (!productTypes.length) dispatch(fetchProductTypes());
  }, []);

  const fields = [
    {
      fieldType: 'text',
      name: 'name',
      label: 'Name',
    },
    {
      fieldType: 'text',
      name: 'description',
      label: 'Description',
    },
    {
      fieldType: 'select',
      name: 'prod_type',
      label: 'Product Type',
      menuItems: productTypes?.map((productType) => ({
        value: String(productType.id),
        label: productType.name,
      })),
    },
  ];

  const initialValues = {
    name: '',
    description: '',
    prod_type: '',
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    description: Yup.string().required('Required'),
    prod_type: Yup.string().required('Required'),
  });

  const handleSubmit = async (formValues) => {
    onSubmit();
    dispatch(createProduct({ ...formValues }));
  };

  return (
    <FormModal
      title="Create New Product"
      fields={fields}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      onCancel={onCancel}
      submitLabel="Create"
    />
  );
};

CreateProductFormModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default CreateProductFormModal;
