import {
  FETCH_USER_DATA_FULFILLED,
} from './actions';

const beautifyRole = (role) => role
  .toLowerCase()
  .split('_')
  .map((el) => el[0].toUpperCase() + el.substr(1))
  .join(' ');

const userReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_USER_DATA_FULFILLED:
      return {
        ...state,
        ...action.payload,
        beautifiedRole: beautifyRole(action.payload.roles[0]),
      };
    default:
      return state;
  }
};

export { userReducer };
