import { useState, useMemo, useEffect } from 'react';
import { Grid } from '@mui/material';
import { useLocation, Outlet } from 'react-router-dom';
import { ReportsContext } from './ReportsContext';
import Provision from '../../api/provision';
import useApiCall from '../../hooks/useApiCall';
import SidebarNavigation from '../../components/SidebarNavigation/SidebarNavigation';

const Reports = () => {
  const { pathname } = useLocation();
  const currentPath = pathname.split('/')[2];

  const [hypervisorCloudId, setHypervisorCloudId] = useState();
  const [hypervisors, setHypervisors] = useState([]);
  const [juiceGroupCloudId, setJuiceGroupCloudId] = useState();
  const [osProjects, setOsProjects] = useState([]);
  const [hypervisorDetails, setHypervisorDetails] = useState([]);
  const [clouds, setClouds] = useState([]);

  const fetchClouds = useApiCall(Provision.fetchClouds);

  useEffect(() => {
    const getClouds = async () => {
      const [data] = await fetchClouds();

      if (data) {
        setClouds(data);
      }
    };
    getClouds();
  }, []);

  const menuItems = [
    { title: 'Tenants', path: 'tenants' },
    { title: 'Hypervisors', path: 'hypervisors' },
    { title: 'JuiceGroups', path: 'juicegroups' },
    { title: 'Caas Clusters', path: 'caas-clusters' },
  ];

  const contextValue = useMemo(() => ({
    juiceGroup: {
      cloudId: juiceGroupCloudId,
      setCloudId: setJuiceGroupCloudId,
      osProjects,
      setOsProjects,
      clouds,
    },
    hypervisor: {
      cloudId: hypervisorCloudId,
      setCloudId: setHypervisorCloudId,
      hypervisors,
      setHypervisors,
      hypervisorDetails,
      setHypervisorDetails,
      clouds,
    },
  }));

  return (
    <Grid
      container
      justifyContent="space-between"
    >
      <Grid item xs={2}>
        <SidebarNavigation menuItems={menuItems} currentPath={currentPath} />
      </Grid>
      <Grid item xs={9}>
        <ReportsContext.Provider value={contextValue}>
          <Outlet />
        </ReportsContext.Provider>
      </Grid>
    </Grid>
  );
};

export default Reports;
