import PropTypes from 'prop-types';
import { useState, useEffect, useMemo } from 'react';
import {
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import { useIntl } from 'react-intl';

import CellComponents from './CellComponents';
import Header from './Header';

const Table = ({
  isPaginated,
  header,
  fields,
  rows,
  footerRows,
  pageSize,
  setFilter,
  totalItems,
  createAction,
  exportData,
  onPageChange,
  search,
  advancedSearch,
  setItemsPerPage,
  pagination,
}) => {
  const intl = useIntl();
  const [currentPage, setCurrentPage] = useState(0);
  const [searchedRows, setSearchedRows] = useState(rows);
  const [tableSize, setTableSize] = useState(pageSize);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortedRows = useMemo(() => {
    if (!orderBy) return searchedRows;
    return [...searchedRows].sort((a, b) => {
      const aValue = a.data.find((item) => item.field === orderBy)?.value || '';
      const bValue = b.data.find((item) => item.field === orderBy)?.value || '';
      if (aValue < bValue) return order === 'asc' ? -1 : 1;
      if (aValue > bValue) return order === 'asc' ? 1 : -1;
      return 0;
    });
  }, [searchedRows, order, orderBy]);

  const filteredRows = isPaginated ? rows : sortedRows;

  useEffect(() => {
    setSearchedRows(rows);
  }, [rows]);

  const paginatedRows = isPaginated
    ? rows
    : sortedRows.slice(currentPage * tableSize, currentPage * tableSize + tableSize);

  return (
    <>
      <Header
        header={header}
        setFilter={setFilter}
        setCurrentPage={setCurrentPage}
        createAction={createAction}
        exportData={exportData}
        search={search}
        rows={rows}
        searchedRows={searchedRows}
        setSearchedRows={setSearchedRows}
        fields={fields}
        advancedSearch={advancedSearch}
        isPaginated={isPaginated}
      />
      <TableContainer>
        <MuiTable>
          <TableHead>
            <TableRow>
              {fields.map((field) => (
                <TableCell
                  key={field.id}
                  sortDirection={orderBy === field.id ? order : false}
                >
                  {!isPaginated ? (
                    <TableSortLabel
                      active={orderBy === field.id}
                      direction={orderBy === field.id ? order : 'asc'}
                      onClick={() => handleRequestSort(field.id)}
                      hideSortIcon={false}
                    >
                      <Typography style={{ fontWeight: orderBy === field.id ? 'bold' : 'normal' }}>
                        {field.title}
                      </Typography>
                    </TableSortLabel>
                  ) : (
                    field.title
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedRows.length > 0 ? (
              paginatedRows.map((row) => (
                <TableRow
                  hover
                  key={row.key}
                  style={{ cursor: row.onRowClick ? 'pointer' : 'default' }}
                  onClick={row.onRowClick ? () => row.onRowClick(row) : null}
                >
                  {row.data.map((cell) => (
                    <TableCell key={`${row.key}-${cell.field}`}>
                      <CellComponents cell={cell} />
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={fields.length}>
                  {intl.formatMessage({ id: 'EmptyTable' })}
                </TableCell>
              </TableRow>
            )}
          </TableBody>

          <TableFooter>
            {footerRows?.map((row) => (
              <TableRow
                hover
                style={{ cursor: row.onRowClick ? 'pointer' : 'not-allowed' }}
                key={row.key}
                onClick={row.onRowClick ? () => row.onRowClick(row) : null}
              >
                {row.data.map((cell) => (
                  <TableCell key={`${row.key}-${cell.field}`}>
                    <CellComponents cell={cell} />
                  </TableCell>
                ))}
              </TableRow>
            ))}
            {pagination && (
              <TableRow>
                <TablePagination
                  count={isPaginated ? totalItems : filteredRows.length}
                  rowsPerPage={tableSize}
                  page={currentPage}
                  onPageChange={(e, newPage) => {
                    setCurrentPage(newPage);
                    onPageChange(newPage);
                  }}
                  onRowsPerPageChange={(e) => {
                    setTableSize(e.target.value);
                    setItemsPerPage(e.target.value);
                  }}
                />
              </TableRow>
            )}
          </TableFooter>
        </MuiTable>
      </TableContainer>
    </>
  );
};

Table.propTypes = {
  isPaginated: PropTypes.bool,
  header: PropTypes.string,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    }),
  ).isRequired,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      data: PropTypes.arrayOf(
        PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
          PropTypes.object,
        ]),
      ),
      onRowClick: PropTypes.func,
    }),
  ),
  pageSize: PropTypes.number,
  setFilter: PropTypes.func,
  totalItems: PropTypes.number,
  createAction: PropTypes.shape({
    tooltip: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
  }),
  exportData: PropTypes.shape({
    file: PropTypes.instanceOf(Blob),
    fileName: PropTypes.string,
    name: PropTypes.string,
    tooltip: PropTypes.string,
  }),
  onPageChange: PropTypes.func,
  search: PropTypes.bool,
  advancedSearch: PropTypes.bool,
  setItemsPerPage: PropTypes.func,
  pagination: PropTypes.bool,
  footerRows: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    value: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.object,
    ])),
    onRowClick: PropTypes.func,
  })),
};

Table.defaultProps = {
  isPaginated: false,
  header: '',
  rows: [],
  pageSize: 10,
  createAction: null,
  exportData: null,
  onPageChange: () => {},
  search: true,
  advancedSearch: true,
  setFilter: () => {},
  totalItems: 0,
  setItemsPerPage: () => {},
  pagination: true,
  footerRows: [],
};

export default Table;
