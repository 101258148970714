import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { updateProductMemberRole } from '../../../../../redux/safescarf/productMembers/actions';
import { fetchRoles } from '../../../../../redux/safescarf/roles/actions';
import { FormModal } from '../../../../../components/Formik';

const roleLookup = (roles) => Object.fromEntries(
  (roles || []).map((role) => [role.id, role.name]),
);

const EditProductMemberFormModal = ({ productMember, onSubmit, onCancel }) => {
  const dispatch = useDispatch();
  const { productId } = useParams();
  const roles = useSelector((state) => state.safescarf.roles);

  useEffect(() => {
    if (!roles.length) dispatch(fetchRoles());
  }, []);

  const userTypeRoles = productMember?.isMachineAccount
    ? roleLookup(roles.filter((role) => role.id === 1))
    : roleLookup(roles.filter((role) => [2, 3, 5].includes(role.id)));

  const fields = [
    {
      fieldType: 'select',
      name: 'role',
      label: 'Product Member Role',
      menuItems: Object.keys(userTypeRoles).map((key) => ({
        value: key,
        label: userTypeRoles[key],
      })),
    },
  ];

  const initialValues = {
    role: productMember?.productMemberRole?.toString() || '',
  };

  const handleSubmit = ({ role }) => {
    onSubmit();
    dispatch(updateProductMemberRole({
      memberId: productMember.productMemberId,
      user: productMember.userId,
      product: productId,
      role: role || '',
    }));
  };

  return (
    <FormModal
      title={`Edit product member role ${productMember.username}`}
      fields={fields}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      onCancel={onCancel}
      submitLabel="Save"
    />
  );
};

EditProductMemberFormModal.propTypes = {
  productMember: PropTypes.shape({
    userId: PropTypes.number,
    username: PropTypes.string,
    createdBy: PropTypes.string,
    productMemberId: PropTypes.number,
    productMemberRole: PropTypes.number,
    isMachineAccount: PropTypes.bool,
  }),
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

EditProductMemberFormModal.defaultProps = {
  productMember: undefined,
};

export default EditProductMemberFormModal;
