import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { updateProduct } from '../../../../../redux/safescarf/products/actions';
import { fetchProductTypes } from '../../../../../redux/safescarf/productTypes/actions';
import { FormModal } from '../../../../../components/Formik';

const EditProductFormModal = ({ product, onSubmit, onCancel }) => {
  const dispatch = useDispatch();
  const { productId } = useParams();
  const productTypes = useSelector((state) => state.safescarf.productTypes);

  useEffect(() => {
    if (!productTypes.length) dispatch(fetchProductTypes());
  }, []);

  const fields = [
    {
      fieldType: 'text',
      name: 'name',
      label: 'Name',
    },
    {
      fieldType: 'text',
      name: 'description',
      label: 'Description',
    },
    {
      fieldType: 'select',
      name: 'prod_type',
      label: 'Product Type',
      menuItems: productTypes?.map((productType) => ({
        value: String(productType.id),
        label: productType.name,
      })),
    },
  ];

  const initialValues = {
    name: product.name,
    description: product.description,
    prod_type: product.prod_type.toString(),
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    description: Yup.string().required('Required'),
    prod_type: Yup.string().required('Required'),
  });

  const handleSubmit = async (formValues) => {
    onSubmit();
    dispatch(updateProduct({ ...formValues, id: productId }));
  };

  return productTypes && (
    <FormModal
      title={`Edit product ${product.name}`}
      fields={fields}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      onCancel={onCancel}
      submitLabel="Save"
    />
  );
};

EditProductFormModal.propTypes = {
  product: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    prod_type: PropTypes.number,
  }),
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

EditProductFormModal.defaultProps = {
  product: {},
};

export default EditProductFormModal;
