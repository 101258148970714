import { useMatches } from 'react-router-dom';

/**
 * Hook to determine if the overlay should be disabled based on the current route.
 * - It searches for the deepest (last matched) route that has `handle.isOverlayDisabled` defined.
 * - If no matching route sets `isOverlayDisabled`, the overlay remains enabled by default.
 */

const useOverlayDisabled = () => {
  const matches = useMatches();
  if (!matches.length) return false;
  const lastRouteWithOverlaySetting = [...matches]
    .reverse()
    .find((match) => match.handle?.isOverlayDisabled !== undefined);
  return lastRouteWithOverlaySetting?.handle?.isOverlayDisabled ?? false;
};

export default useOverlayDisabled;
