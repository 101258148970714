import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useApiCall from '../../../../hooks/useApiCall';
import Auth from '../../../../api/auth';
import { FormModal } from '../../../../components/Formik';

const EditProjectFormModal = ({
  project, onSubmit, onCancel,
}) => {
  const { projectId } = useParams();
  const [businessUnits, setBusinessUnits] = useState(null);
  const [technicalLeads, setTechnicalLeads] = useState(null);

  const getBusinessUnitsCall = useApiCall(Auth.fetchV1BusinessUnits);
  const getUsersCall = useApiCall(Auth.fetchV2Users);
  const updateProjectCall = useApiCall(Auth.updateV2Project);

  useEffect(() => {
    const getBusinessUnits = async () => {
      const [data] = await getBusinessUnitsCall({ organizationId: project.organizationId });
      if (data) setBusinessUnits(data);
    };

    const getTechnicalLeads = async () => {
      const [data] = await getUsersCall({
        organizationId: project.organizationId,
        roles: ['TECHNICAL_LEAD'],
      });

      if (data) {
        const activatedTechnicalLeads = data.filter((technicalLead) => technicalLead.activated);
        setTechnicalLeads(activatedTechnicalLeads);
      }
    };

    getBusinessUnits();
    getTechnicalLeads();
  }, []);

  const fields = [
    {
      fieldType: 'text',
      name: 'description',
      label: 'Description',
    },
    {
      fieldType: 'select',
      name: 'businessUnitId',
      label: 'Business Unit',
      menuItems: businessUnits?.map(({ _id, name }) => ({ value: _id, label: name })),
    },
    {
      fieldType: 'select',
      name: 'technicalLeadId',
      label: 'Technical Lead',
      menuItems: technicalLeads?.map(({ _id, email }) => ({ value: _id, label: email })),
    },
  ];

  const initialValues = {
    description: project.description,
    businessUnitId: project.businessUnitId,
    technicalLeadId: project.technicalLeadId,
  };

  const validationSchema = Yup.object().shape({
    description: Yup.string().required('Required'),
  });

  const updateProject = async (formValues) => {
    onCancel();

    const [data] = await updateProjectCall({
      ...formValues,
      technicalLeadId: formValues.technicalLeadId || null,
      _id: projectId,
    });

    if (data) {
      onSubmit(data);
    }
  };

  return (
    <FormModal
      title={`Edit project ${project.name}`}
      fields={fields}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={updateProject}
      onCancel={onCancel}
      submitLabel="Save"
    />
  );
};

EditProjectFormModal.propTypes = {
  project: PropTypes.shape({
    organizationId: PropTypes.string.isRequired,
    name: PropTypes.string,
    description: PropTypes.string,
    businessUnitId: PropTypes.string,
    technicalLeadId: PropTypes.string,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default EditProjectFormModal;
