import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Provision from '../../../../../api/provision';

import useApiCall from '../../../../../hooks/useApiCall';
import Table from '../../../../../components/Table';

const BareMetalClusters = ({ setActiveResources, product }) => {
  const [clusters, setClusters] = useState([]);
  const fetchClusters = useApiCall(Provision.fetchBareMetalClustersV1);

  useEffect(() => {
    const getProductResources = async () => {
      const [data] = await fetchClusters(product.details.juiceGroup);

      if (data) {
        setClusters(data);
        setActiveResources(data.some(
          (cluster) => ['Pending', 'Approved', 'Provisioned'].includes(cluster.status),
        ));
      }
    };

    if (product.details.juiceGroup) {
      getProductResources();
    }
  }, [product.details.juiceGroup]);


  const fields = [
    { id: 'name', title: 'Name' },
    { id: 'group', title: 'Group' },
    { id: 'cloud', title: 'Cloud' },
    { id: 'created', title: 'Created At' },
    { id: 'last_updated', title: 'Last Updated' },
    { id: 'device_count', title: 'Device Count' },
  ];

  const rows = clusters.map((cluster) => ({
    key: `${cluster.name}-${cluster.group}-${cluster.cloud}`,
    data: [
      { field: 'name', value: cluster.name },
      { field: 'group', value: cluster.group.name },
      { field: 'cloud', value: cluster.cloud.name },
      { field: 'created', value: cluster.created },
      { field: 'last_updated', value: cluster.last_updated },
      { field: 'device_count', value: cluster.device_count },
    ],
  }));


  return (
    <Table
      header="Clusters"
      fields={fields}
      rows={rows}
    />
  );
};

BareMetalClusters.propTypes = {
  setActiveResources: PropTypes.func.isRequired,
  product: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    details: PropTypes.shape({
      juiceGroup: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default BareMetalClusters;
