import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import rbacCheck from '../../../rbac/rbacCheck';
import Table from '../../../components/Table';
import CreateUserFormModal from './CreateUserFormModal';
import { assignableRoles, userRolesLookup } from '../../../helpers/lookups';
import useApiCall from '../../../hooks/useApiCall';
import Auth from '../../../api/auth';
import useTableApiCall from '../../../hooks/useTableApiCall';

const Users = () => {
  const { organizationId } = useParams();
  const intl = useIntl();
  const navigate = useNavigate();
  const userData = useSelector((state) => state.user);

  const [createModalOpened, setCreateModalOpened] = useState(false);
  const [rows, setRows] = useState([]);

  const createUserCall = useApiCall(Auth.createV2User);

  const userRoles = rbacCheck(userData.roles, '/users:PUT_ROLE_CHECK');
  const canCreateUser = rbacCheck(userData.roles, '/users:POST');

  const {
    fetchData,
    setCurrentPage,
    ...tableData
  } = useTableApiCall(true, setRows, Auth.fetchV2Users, { organizationId }, 'email');

  const toggleCreateModal = () => setCreateModalOpened((prev) => !prev);

  const handleCreateUser = async (formValues) => {
    toggleCreateModal();

    const [data] = await createUserCall({
      ...formValues,
      organizationId,
    });

    if (data) fetchData();
  };

  const fields = [
    { title: 'Email', id: 'email' },
    { title: 'Role', id: 'role' },
    { title: 'Activated', id: 'activated' },
  ];

  const transformedRows = rows.map((user) => ({
    key: user.uuid,
    onRowClick: () => navigate(`/users/${user.uuid}`),
    data: [
      { field: 'email', value: user.email },
      { field: 'role', value: userRolesLookup[user.role] },
      { field: 'activated', value: user.activated ? 'True' : 'False' },
    ],
  }));

  return (
    <>
      {createModalOpened && userRoles.length > 0 && (
        <CreateUserFormModal
          roles={assignableRoles(userRoles)}
          onSubmit={handleCreateUser}
          onCancel={toggleCreateModal}
        />
      )}
      <Table
        header="Users"
        fields={fields}
        rows={transformedRows}
        {...tableData}
        onPageChange={(newPage) => setCurrentPage(newPage)}
        createAction={{
          disabled: !canCreateUser,
          onClick: toggleCreateModal,
          tooltip: !canCreateUser
            ? intl.formatMessage({ id: 'userCreationPermissionErr' })
            : '',
        }}
        advancedSearch={false}
      />
    </>
  );
};

export default Users;
