import {
  FormikTextField,
  FormikSelect,
  FormikPasswordField,
  FormikSwitch,
  FormikMultiSelect,
} from '../InputFields/index';

export const FIELD_COMPONENTS = {
  text: FormikTextField,
  password: FormikPasswordField,
  select: FormikSelect,
  multiSelect: FormikMultiSelect,
  switch: FormikSwitch,
};
