import { useState, useEffect } from 'react';
import useApiCall from './useApiCall';
import Provision from '../api/provision';

const useCloudQuota = (juiceGroup) => {
  const [availableVirtualClouds, setAvailableVirtualClouds] = useState([]);
  const getVirtualCloudsCall = useApiCall(Provision.fetchVirtualClouds);
  const fetchNetworkPolicies = useApiCall(Provision.fetchNetworkPolicies);

  const getAvailableClouds = async () => {
    if (!juiceGroup) return;

    const [data] = await getVirtualCloudsCall({ juiceGroups: [juiceGroup] });
    if (data) {
      setAvailableVirtualClouds(data.map((virtualCloud) => ({
        ...virtualCloud,
        unusedQuota: {
          compute: {
            cores:
              virtualCloud.allowed_quota.compute.cores
              - virtualCloud.used_quota.compute.cores,
            ram:
              (virtualCloud.allowed_quota.compute.ram
                - virtualCloud.used_quota.compute.ram) / 1024,
          },
          volume: {
            gigabytes:
              virtualCloud.allowed_quota.volume.gigabytes
              - virtualCloud.used_quota.volume.gigabytes,
          },
          network: {
            floatingIps:
              virtualCloud.allowed_quota.network.floating_ips
              - virtualCloud.used_quota.network.floating_ips,
          },
        },
      })));
    }
  };

  const getExternalNetworks = async (cloudName) => {
    if (!juiceGroup || !cloudName) return [];

    const [data] = await fetchNetworkPolicies(juiceGroup, cloudName);
    return data?.[0]?.access_as_external?.map((external) => external.network_name) || [];
  };

  useEffect(() => {
    getAvailableClouds();
  }, [juiceGroup]);

  return {
    availableVirtualClouds,
    getExternalNetworks,
  };
};

export default useCloudQuota;
