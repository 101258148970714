import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';
import { useMemo } from 'react';
import {
  Typography,
  Grid,
} from '@mui/material';
import { FormModal } from '../Formik';
import Chip from '../InputFields/Chip';

const DeleteFormModal = ({
  fieldName,
  fieldValue,
  messages,
  onSubmit,
  onCancel,
  children,
}) => {
  const intl = useIntl();

  const fields = useMemo(() => [
    {
      fieldType: Typography,
      name: 'deleteMessage',
      fieldContent: (
        <>
          {intl.formatMessage({ id: 'deleteMessage1' }, { name: fieldName.toLowerCase(), value: fieldValue })}
        </>
      ),
    },
    ...(children ? [
      {
        fieldType: Grid,
        fieldContent: children,
      },
    ] : []),
    ...messages.map((messageId, index) => ({
      fieldType: Typography,
      name: `message-${index}`,
      fieldContent: (
        <>
          {intl.formatMessage({ id: messageId })}
        </>
      ),
    })),
    {
      fieldType: Grid,
      name: 'deleteMessage3:',
      item: true,
      justifyContent: 'center',
      fieldContent: (
        <strong>
          {intl.formatMessage({ id: 'deleteMessage2' })}
          <Chip
            label={fieldValue}
            sx={{ fontWeight: 'bold', fontSize: '1rem' }}
          />
        </strong>
      ),
    },
    {
      fieldType: 'text',
      name: 'deleteInput',
    },
  ], [fieldName, fieldValue, messages, children]);

  const initialValues = {
    deleteInput: '',
  };

  const validationSchema = Yup.object().shape({
    deleteInput: Yup.string()
      .oneOf(
        [fieldValue, null],
        `Entered value should match the current ${fieldName}`,
      )
      .required('Required'),
  });

  return (
    <FormModal
      title={`Delete ${fieldName}`}
      fields={fields}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      onCancel={onCancel}
      submitLabel="Delete"
    />
  );
};

DeleteFormModal.propTypes = {
  fieldName: PropTypes.string.isRequired,
  fieldValue: PropTypes.string.isRequired,
  messages: PropTypes.arrayOf(PropTypes.string),
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  children: PropTypes.node,
};

DeleteFormModal.defaultProps = {
  messages: [],
  children: null,
};

export default DeleteFormModal;
