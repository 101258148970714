import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { Grid, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { FormikDateTimeField } from '../../../../components/Formik';
import Button from '../../../../components/Buttons/Button';

const nowDate = new Date().toISOString().slice(0, 16);

const DateTimeFilter = ({ dateRange, setDateRange }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const initialValues = {
    fromDate: dateRange.from,
    toDate: dateRange.to,
  };

  const validationSchema = Yup.object({
    fromDate: Yup.date()
      .max(Yup.ref('toDate'), 'From date must be before To date')
      .required('Required'),
    toDate: Yup.date()
      .min(Yup.ref('fromDate'), 'To date must be after From date')
      .max(nowDate)
      .required('Required'),
  });

  const handleSubmit = (data) => {
    const newSearchParams = new URLSearchParams(location.search);

    newSearchParams.set('from', data.fromDate);
    newSearchParams.set('to', data.toDate);

    const newUrl = `${location.pathname}?${newSearchParams.toString()}`;

    navigate(newUrl, { replace: true });
    setDateRange({ from: data.fromDate, to: data.toDate });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      <Form style={{ margin: '1rem' }}>
        <Grid
          container
          spacing={1}
          alignItems="center"
        >
          <Grid item xs={0.5}>
            <Typography>Time Range: </Typography>
          </Grid>
          <Grid item xs={2}>
            <FormikDateTimeField
              name="fromDate"
              label="From"
              inputProps={{ max: nowDate }}
            />
          </Grid>
          <Grid item xs={2}>
            <FormikDateTimeField
              name="toDate"
              label="To"
              inputProps={{ max: nowDate }}
            />
          </Grid>
          <Grid item xs={1}>
            <Button
              type="submit"
              label="Submit"
            />
          </Grid>
        </Grid>
      </Form>
    </Formik>
  );
};

DateTimeFilter.propTypes = {
  setDateRange: PropTypes.func.isRequired,
  dateRange: PropTypes.shape({
    from: PropTypes.string,
    to: PropTypes.string,
  }).isRequired,
};

export default DateTimeFilter;
