import { useNavigate } from 'react-router-dom';
import { Typography, Grid } from '@mui/material';
import Button from '../../components/Buttons/Button';

const Page404 = () => {
  const navigate = useNavigate();

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      spacing={1}
      pt={20}
    >
      <Grid item>
        <Typography variant="h2" color="primary">
          Thank you for your interest!
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="h6">
          Unfortunately this page is not available.
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="h6" paragraph>
          Please check the address and try again.
        </Typography>
      </Grid>
      <Grid item>
        <Button
          type="submit"
          onClick={() => navigate('/')}
          label="Back to homepage"
        />
      </Grid>
    </Grid>
  );
};

export default Page404;
