import { Button as ButtonMUI } from '@mui/material';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { buttonIcons } from '../icons/ButtonIcons';

const Button = ({
  type,
  label,
  size,
  color,
  variant,
  disabled,
  href,
  download,
  onClick,
  icon,
}) => {
  const getIcon = (key) => {
    const IconComponent = buttonIcons[key];
    return IconComponent ? <IconComponent /> : null;
  };

  const computedIcon = useMemo(() => getIcon(icon) || getIcon(label), [icon, label]);

  return (
    <ButtonMUI
      type={type}
      size={size}
      color={color}
      variant={variant}
      disabled={disabled}
      href={href}
      download={download}
      endIcon={computedIcon}
      onClick={onClick}
    >
      {label}
    </ButtonMUI>
  );
};

Button.propTypes = {
  type: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.string,
  variant: PropTypes.string,
  href: PropTypes.string,
  download: PropTypes.string,
  onClick: PropTypes.func,
  label: PropTypes.string,
  icon: PropTypes.node,
  disabled: PropTypes.bool,
};

Button.defaultProps = {
  type: 'button',
  size: 'medium',
  color: 'primary',
  variant: 'contained',
  disabled: false,
  href: '',
  download: '',
  onClick: null,
  label: 'Submit',
  icon: null,
};

export default Button;
