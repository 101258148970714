import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import Provision from '../../api/provision';
import OverviewTable from '../../components/OverviewTable/OverviewTable';
import JuiceGroupInfoFormModal from './JuiceGroupInfoFormModal';
import useApiCall from '../../hooks/useApiCall';
import Chip from '../../components/InputFields/Chip';

const JuiceGroupInfo = ({ tenant }) => {
  const { cloudId, juiceGroup } = useParams();

  const [juiceGroupInfo, setJuiceGroupInfo] = useState({});
  const [editModalOpened, setEditModalOpened] = useState(false);

  const fetchJuiceGroupInfo = useApiCall(Provision.fetchJuiceGroupInfo);

  useEffect(() => {
    const fetchJuiceGroupInfoData = async () => {
      const [data] = await fetchJuiceGroupInfo({ juiceGroup });

      if (data) {
        setJuiceGroupInfo(data);
      }
    };

    fetchJuiceGroupInfoData();
  }, [juiceGroup]);

  const getJuiceGroupInfo = () => [
    {
      title: 'Cloud Id',
      value: cloudId,
    },
    {
      title: 'Juice Group',
      value: juiceGroup,
    },
    {
      title: 'Geo-Redundancy',
      value: juiceGroupInfo.geoRedundancy ? 'Active' : 'Inactive',
    },
    {
      title: 'External Services',
      component:
         juiceGroupInfo?.externalServices?.map((tag) => (
           <Chip
             key={tag}
             label={tag}
             sx={{ ml: 1 }}
           />
         )),
    },
  ];

  return (
    <>
      {editModalOpened && (
        <JuiceGroupInfoFormModal
          juiceGroupInfo={juiceGroupInfo}
          setJuiceGroupInfo={setJuiceGroupInfo}
          onClose={() => setEditModalOpened(false)}
        />
      )}
      <OverviewTable
        header={tenant}
        rows={getJuiceGroupInfo()}
        editAction={{
          onClick: () => setEditModalOpened(true),
          disabled: false,
          tooltip: 'EditJuiceGroupInfo',
        }}
      />
    </>
  );
};

JuiceGroupInfo.propTypes = {
  tenant: PropTypes.string.isRequired,
};

export default JuiceGroupInfo;
