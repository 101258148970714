import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Provision from '../../../../../api/provision';
import useApiCall from '../../../../../hooks/useApiCall';
import Table from '../../../../../components/Table';

const fields = [
  { title: 'CN Type', id: 'CNType' },
  { title: 'vCPU', id: 'vCPU' },
  { title: 'RAM (GB)', id: 'RAM' },
  { title: 'Root Disc (GB)', id: 'rootDisc' },
  { title: 'Standard Storage (GB)', id: 'standardStorage' },
  { title: 'CPU Vendor', id: 'cpuVendor' },
];

const getCNType = (computeNodeConstraints) => {
  if (computeNodeConstraints.cpu[0] === 'shared_cpus'
    && computeNodeConstraints.root_disk.length === 0) return 'shared';
  if (computeNodeConstraints.cpu[0] === 'dedicated_cpus'
    && computeNodeConstraints.root_disk.length === 0) return 'dedicated';
  if (computeNodeConstraints.cpu[0] === 'dedicated_cpus'
    && computeNodeConstraints.root_disk.length > 0) return 'dedicated-local';
  return 'shared-local';
};

const getRows = (vms) => vms.map((vm) => ({
  key: vm.name,
  data: [
    { field: 'CNType', value: getCNType(vm.compute_node_constraints) },
    { field: 'vCPU', value: vm.cpu_amount },
    { field: 'RAM', value: vm.ram_amount },
    { field: 'rootDisc', value: vm.storage.root_standard.amount },
    { field: 'standardStorage', value: vm.storage.standard.amount },
    {
      field: 'cpuVendor',
      value: vm.compute_node_constraints.cpu_vendor.length > 0 ? 'intel' : '-',
    },
  ],
}));

const getTotals = (vms) => {
  const totals = vms.reduce(
    (acc, vm) => {
      acc.cpu_amount += vm.cpu_amount;
      acc.ram_amount += vm.ram_amount;
      acc.root_standard += vm.storage.root_standard.amount;
      acc.standard += vm.storage.standard.amount;
      return acc;
    },
    {
      cpu_amount: 0,
      ram_amount: 0,
      root_standard: 0,
      standard: 0,
    },
  );

  return [{
    key: 'total',
    data: [
      { field: 'CNType', value: 'total' },
      { field: 'vCPU', value: totals.cpu_amount },
      { field: 'RAM', value: totals.ram_amount },
      { field: 'rootDisc', value: totals.root_standard },
      { field: 'standardStorage', value: totals.standard },
      { field: 'cpuVendor', value: '-' },
    ],
  }];
};

const ForecastSummary = ({ juiceGroup, cloudId }) => {
  const [forecasts, setForecasts] = useState([]);
  const fetchForecasts = useApiCall(Provision.fetchForecasts);

  useEffect(() => {
    async function getForecasts() {
      const [data] = await fetchForecasts(juiceGroup, cloudId);
      if (data) setForecasts(data);
    }
    if (cloudId && juiceGroup) getForecasts();
  }, [cloudId, juiceGroup]);

  if (!forecasts.length) return <p>No Forecast was found</p>;
  return (
    <Table
      fields={fields}
      rows={getRows(forecasts[0].vms)}
      footerRows={getTotals(forecasts[0].vms)}
      pagination={false}
      search={false}
    />
  );
};
ForecastSummary.propTypes = {
  juiceGroup: PropTypes.string.isRequired,
  cloudId: PropTypes.string.isRequired,
};

export default ForecastSummary;
