import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Grid, Typography } from '@mui/material';
import { utils, writeFile } from 'xlsx';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { FormikDateField } from '../../../../../components/Formik';
import Provision from '../../../../../api/provision';
import { requestStarted, requestFulfilled, requestFailed } from '../../../../../redux/status/actions';
import { enqueueSnackbar } from '../../../../../redux/notifier/actions';
import useApiCall from '../../../../../hooks/useApiCall';
import Button from '../../../../../components/Buttons/Button';

const [nowDate] = new Date().toISOString().split('T');

const CloudReport = ({ product }) => {
  const getVirtualCloudsCall = useApiCall(Provision.fetchVirtualClouds);
  const dispatch = useDispatch();
  const [virtualClouds, setVirtualClouds] = useState([]);

  useEffect(() => {
    const getVirtualClouds = async () => {
      const [data] = await getVirtualCloudsCall({ juiceGroups: [product.details.juiceGroup] });

      if (data) {
        setVirtualClouds(data);
      }
    };

    if (product.details.juiceGroup) getVirtualClouds();
  }, [product.details.juiceGroup]);

  const downloadReport = async (data) => {
    const cloudIds = virtualClouds.map((virtualCloud) => virtualCloud.cloud.name);
    try {
      dispatch(requestStarted());
      const constructCloudData = (cloudDetails) => cloudDetails.instances.map((instance) => ({
        'Project Name': cloudDetails.metadata.projects[instance.project_id].name,
        'Instance Name': instance.name,
        'Instance Id': instance.id,
        'Hypervisor Name': instance.host_name,
        'Hypervisor Type': cloudDetails.metadata.hosts[instance.host_name].compute_role,
        'CPU Allocation Ratio': cloudDetails.metadata.hosts[instance.host_name].cpu_ratio,
        vCPUs: instance.cpus_total,
        'Cpu Policy': cloudDetails.metadata.hosts[instance.host_name].cpu_policy,
        'Cpu Usage (hours)': instance.cpu_usage_hours,
        'Memory GB': instance.ram_gigabytes,
        'RAM Usage (hours)': instance.ram_usage_hours,
        'Root Disk GB': instance.disk_gigabytes,
        'Disk Usage (hours)': instance.disk_usage_hours,
        State: instance.status,
        Flavor: instance.flavor_name,
        'Created At': instance.created_at,
        'Deleted At': instance.deleted_at,
      }));
      const instanceDetails = await Provision.fetchInstanceDetails({
        juice_group: product.details.juiceGroup,
        cloudIds,
        begin: new Date(data.fromDate).setUTCHours(0, 0, 0),
        end: new Date(data.toDate).setUTCHours(23, 59, 59),
      });

      // Check if there are any details to be exported, and inform the user if not
      const fetchedInstances = instanceDetails.data.map((detail) => detail.instances).flat();
      if (!fetchedInstances.length) {
        dispatch(requestFulfilled());
        dispatch(enqueueSnackbar({
          message: 'noUsageData',
          options: { variant: 'info' },
        }));
        return;
      }

      const workbook = utils.book_new();
      instanceDetails.data.forEach((cloudDetails) => {
        const rows = constructCloudData(cloudDetails);
        const worksheet = utils.json_to_sheet(rows);
        utils.book_append_sheet(workbook, worksheet, cloudDetails.context.cloud_id);
      });

      dispatch(requestFulfilled());
      writeFile(workbook, `cloud-report-${data.fromDate}-${data.toDate}.xls`);
    } catch (error) {
      dispatch(requestFailed());
    }
  };

  const initialValues = {
    fromDate: '',
    toDate: '',
  };

  const validationSchema = Yup.object().shape({
    fromDate: Yup.date()
      .max(Yup.ref('toDate'), 'From date must be before To date')
      .required('Required'),
    toDate: Yup.date().max(nowDate)
      .min(Yup.ref('fromDate'), 'To date must be after From date')
      .required('Required'),
  });

  return (
    <>
      <Typography variant="h4" color="primary" gutterBottom>Cloud Report</Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(data) => downloadReport(data)}
      >
        {({ isSubmitting }) => (
          <Form>
            <Grid
              container
              alignItems="center"
              gap={1}
            >
              <Grid item xs={2}>
                <FormikDateField
                  name="fromDate"
                  label="From"
                  inputProps={{ max: nowDate }}
                />
              </Grid>
              <Grid item xs={2}>
                <FormikDateField
                  name="toDate"
                  label="To"
                  inputProps={{ max: nowDate }}
                />
              </Grid>
              <Grid item>
                <Button
                  type="submit"
                  label="Submit"
                  disabled={isSubmitting}
                />
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};

CloudReport.propTypes = {
  product: PropTypes.shape({
    details: PropTypes.shape({
      juiceGroup: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default CloudReport;
