import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import {
  List,
  ListItem,
  ListItemText,
  Alert,
  AlertTitle,
} from '@mui/material';

const ProductUnassignment = ({ resources }) => {
  const intl = useIntl();

  return (
    <Alert severity="warning">
      <AlertTitle>{intl.formatMessage({ id: 'userUnassignment1' })}</AlertTitle>
      <List dense>
        {resources.products.map((product) => (
          <ListItem key={product._id} disablePadding>
            <ListItemText primary={`• ${product.name} (${product.type})`} disableTypography />
          </ListItem>
        ))}
      </List>
      <AlertTitle>{intl.formatMessage({ id: 'userUnassignment2' })}</AlertTitle>
    </Alert>
  );
};

ProductUnassignment.propTypes = {
  resources: PropTypes.shape({
    products: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        _id: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }).isRequired,
};

export default ProductUnassignment;
