import { Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import OverviewTable from '../../../../components/OverviewTable/OverviewTable';

const Overview = () => {
  const instance = useSelector((state) => state.safescarf.instance.data);

  return (
    <>
      <OverviewTable
        rows={[
          {
            title: 'SafeSCARF instance:',
            component: (
              <Link to={`https://${instance.data.fqdn}`} target="_blank" rel="noopener noreferrer">
                {`https://${instance.data.fqdn}`}
              </Link>
            ),
          },
        ]}
      />
      <Typography style={{ marginTop: '1rem' }} gutterBottom align="center">
        To cancel this product, please contact &nbsp;
        <Link to="mailto:commercial.support@pan-net.cloud">
          commercial.support@pan-net.cloud
        </Link>
      </Typography>
    </>
  );
};

export default Overview;
