import {
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Switch as SwitchMUI,
} from '@mui/material';
import PropTypes from 'prop-types';

export const Switch = ({
  id,
  name,
  label,
  checked,
  disabled,
  onChange,
  onBlur,
  helperText,
}) => {
  let displayedLabel = label;
  if (typeof label === 'object') {
    displayedLabel = checked ? label.on : label.off;
  }

  return (
    <FormControl>
      <FormGroup>
        <FormControlLabel
          control={(
            <SwitchMUI
              color="primary"
              size="small"
              id={id || name}
              name={name}
              checked={checked}
              onChange={onChange}
              onBlur={onBlur}
              disabled={disabled}
            />
          )}
          label={displayedLabel}
          sx={{ height: '0.1rem' }}
        />
      </FormGroup>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

Switch.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.shape({
      on: PropTypes.string.isRequired,
      off: PropTypes.string.isRequired,
    }),
  ]).isRequired,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  helperText: PropTypes.string,
};

Switch.defaultProps = {
  id: '',
  checked: false,
  disabled: false,
  onBlur: () => {},
  helperText: '',
};
