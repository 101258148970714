import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';
import validator from 'validator';
import {
  Grid,
  Container,
  Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { GroupIcon, InfoIcon } from '../../components/icons/IconLibrary';
import { FormikCheckbox, FormikPasswordField } from '../../components/Formik';
import Button from '../../components/Buttons/Button';

const terms = (
  <>
    <Typography gutterBottom>
      By accessing this web portal, You explicitly confirm and acknowledge that
      You are officially authorized and designated as &quot; IaaS &quot; user to represent
      Your Employer Company (as &quot;Service Receiver&quot;),
      whilst undertaking activities in accordance
      to the General Terms and Conditions given on the following
      &nbsp;
      <Link to="/gtc"> General Terms and Conditions </Link>
      &nbsp;
      which must be explicitly accepted by You as requested below.
    </Typography>
    <Typography gutterBottom>
      Prior accepting the above stated General Terms and Conditions
      and Agreement on the Processing of Personal Data,
      please read it carefully and make sure that you understand the terms,
      conditions and provisions thereof,
      since by accepting these terms and conditions,
      You expressly agree to apply thereof when evaluating &quot;IaaS&quot;.
    </Typography>
    <Typography gutterBottom>
      By accepting the above stated General Terms and Conditions,
      please be informed that on the following
      &nbsp;
      <Link to="/data-privacy">Privacy</Link>
      &nbsp;
      you will find our &quot;Data privacy information for DT Cloud Services portal&quot;,
      for your own informative purposes and awareness, only.
    </Typography>
  </>
);

const SetPassword = (props) => {
  const { onSubmit, organizationName } = props;
  const intl = useIntl();
  const validationSchema = Yup.object().shape({
    termsAccepted: Yup.boolean()
      .oneOf([true], 'Required'),
    newPassword: Yup.string()
      .test(
        'Strong pasword',
        intl.formatMessage({ id: 'ERR_PASSWORD_INVALID' }),
        (value) => (value ? validator.isStrongPassword(value, { minLength: 10 }) : true),
      ).required('Required'),
    newPasswordConfirm: Yup.string()
      .oneOf([Yup.ref('newPassword'), null], intl.formatMessage({ id: 'userPasswordMismatch' }))
      .required('Required'),
  });

  const initialValues = {
    newPassword: '',
    newPasswordConfirm: '',
    termsAccepted: false,
  };

  return (
    <Container maxWidth="md">
      <Typography variant="h4" color="primary" gutterBottom>
        User activation
      </Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(data) => onSubmit(data.newPassword)}
      >
        {({ isSubmitting }) => (
          <Form>
            <Grid
              container
              direction="column"
              gap={1}
            >
              <Grid item display="flex">
                <GroupIcon />
                <Typography>
                  {`You were invited to ${organizationName}. Please create your password.`}
                </Typography>
              </Grid>
              <Grid item display="flex">
                <InfoIcon />
                <Typography>
                  Read about DT Cloud Services&nbsp;
                  <Link to="/about-us">here</Link>
                </Typography>
              </Grid>
              <Grid
                container
                direction="column"
                width={300}
                py={2}
              >
                <Grid item>
                  <FormikPasswordField
                    label="New Password"
                    name="newPassword"
                  />
                </Grid>
                <Grid item>
                  <FormikPasswordField
                    label="Verify password"
                    name="newPasswordConfirm"
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              justifyContent="flex-end"
            >
              <Grid item>
                {terms}
                <FormikCheckbox
                  label="Accept"
                  name="termsAccepted"
                />
              </Grid>
              <Grid item>
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  label="Submit"
                />
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

SetPassword.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  organizationName: PropTypes.string.isRequired,
};

export default SetPassword;
