import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { FormModal } from '../../../components/Formik';
import useApiCall from '../../../hooks/useApiCall';
import Auth from '../../../api/auth';

const CreateBusinessUnitFormModal = ({ onSubmit, onCancel }) => {
  const { organizationId } = useParams();
  const [businessUnitManagers, setBusinessUnitManagers] = useState([]);

  const fetchBusinessUnitManagers = useApiCall(Auth.fetchV2Users);
  const createBusinessUnitCall = useApiCall(Auth.createV1BusinessUnit);

  useEffect(() => {
    const getBuManagers = async () => {
      const [data] = await fetchBusinessUnitManagers({ organizationId, roles: 'BUSINESS_UNIT_MANAGER' });

      if (data) {
        const activatedBuManagers = data.filter((buManager) => buManager.activated);
        setBusinessUnitManagers(activatedBuManagers);
      }
    };

    getBuManagers();
  }, []);

  const fields = [
    {
      fieldType: 'text',
      name: 'name',
      label: 'Name',
    },
    {
      fieldType: 'text',
      name: 'description',
      label: 'Description',
    },
    {
      fieldType: 'select',
      name: 'businessUnitManager',
      label: 'Business Unit Manager',
      menuItems: businessUnitManagers.map(({ _id, email }) => ({ value: _id, label: email })),
    },
  ];

  const initialValues = {
    name: '',
    description: '',
    businessUnitManager: null,
  };

  const validationSchema = Yup.object({
    name: Yup.string().required('Required'),
    description: Yup.string().required('Required'),
  });

  const createBusinessUnit = async (formValues) => {
    onCancel();

    const [data] = await createBusinessUnitCall({
      ...formValues,
      organizationId,
    });

    if (data) {
      onSubmit(data);
    }
  };

  return (
    <FormModal
      title="Create New Business Unit"
      fields={fields}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={createBusinessUnit}
      onCancel={onCancel}
      submitLabel="Create"
    />
  );
};

CreateBusinessUnitFormModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default CreateBusinessUnitFormModal;
