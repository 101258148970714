import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';

import MonitoringMs from '../../../../api/monitoring';
import useApiCall from '../../../../hooks/useApiCall';
import Table from '../../../../components/Table';

const mapTableData = (panelData, fields) => panelData?.map((row, index) => ({
  key: index.toString(),
  data: fields.map((field) => {
    if (typeof row[field.id] === 'number') {
      return {
        type: 'progressBar',
        field: field.id,
        value: Math.floor(row[field.id] * 1000) / 1000,
      };
    }
    return {
      field: field.id,
      value: row[field.id],
    };
  }),
}));

const MonitoringTable = ({
  panelInfo, dateRange, styles, filterParams,
}) => {
  const { dashboardId } = useParams();
  const [value, setValue] = useState();
  const getPanelDataApiCall = useApiCall(MonitoringMs.getPanel);

  const getPanel = async (targets) => {
    const [data] = await getPanelDataApiCall(targets, dashboardId);
    if (data) {
      const { refId } = panelInfo.targets.filter((target) => !target.hide)[0];
      const panelData = data.results[refId]?.frames[0];
      const transformations = panelInfo.transformations.find((transformation) => transformation.id === 'organize').options;

      const formatedData = panelData.data.values[0]?.map(
        (_, i) => panelData.data.values.map((row) => row[i]).reduce((acc, v, index) => {
          acc[panelData.schema.fields[index].name] = v;
          return acc;
        }, {}),
      );

      const fields = transformations ? Object.keys(transformations.indexByName).map((key) => ({
        id: key, title: transformations.renameByName[key],
      })).sort(
        (a, b) => transformations.indexByName[a.id] - transformations.indexByName[b.id],
      ) : [];


      setValue({
        fields,
        data: mapTableData(formatedData, fields),
      });
    }
  };

  useEffect(() => {
    const toDate = new Date(dateRange.to).getTime();
    const fromDate = new Date(dateRange.from).getTime();
    const targets = panelInfo.targets.filter((target) => !target.hide);
    if (targets) {
      getPanel({
        queries: targets,
        to: toDate.toString(),
        from: fromDate.toString(),
        filterParams,
      });
    }
  }, []);

  return value ? (
    <div style={{ height: styles.height, overflowY: 'scroll' }}>
      <Table
        fields={value.fields}
        rows={value.data}
        styles={{ height: styles.height }}
        search={false}
        pagination={false}
        pageSize={value.data?.length}
      />
    </div>
  ) : (
    <div style={{
      height: styles.height,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    }}
    >
      <Typography variant="h4" color="primary" align="center">No Data</Typography>
    </div>
  );
};

MonitoringTable.propTypes = {
  panelInfo: PropTypes.shape({
    title: PropTypes.string,
    targets: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
    transformations: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
    gridPos: PropTypes.shape({
      h: PropTypes.number,
      w: PropTypes.number,
      x: PropTypes.number,
      y: PropTypes.number,
    }),
  }).isRequired,
  dateRange: PropTypes.shape({
    from: PropTypes.string,
    to: PropTypes.string,
  }).isRequired,
  styles: PropTypes.shape({
    height: PropTypes.string,
  }).isRequired,
  filterParams: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.object,
  ])).isRequired,
};


export default MonitoringTable;
