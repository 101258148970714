import PropTypes from 'prop-types';
import * as Yup from 'yup';
import validator from 'validator';
import Provision from '../../api/provision';
import useApiCall from '../../hooks/useApiCall';
import { FormModal } from '../../components/Formik';

const CreateContactFormModal = ({ juiceGroup, onSubmit, onCancel }) => {
  const createContactCall = useApiCall(Provision.addContact, 'ContactAdded');

  const roles = {
    stakeHolder: 'Stakeholder',
    serviceOwner: 'Service Owner',
    tenantOwner: 'Tenant Owner',
    onboardingSupport: 'Onboarding Support',
  };

  const fields = [
    {
      fieldType: 'text',
      name: 'email',
      label: 'Email',
    },
    {
      fieldType: 'select',
      name: 'type',
      label: 'Role',
      menuItems: Object.entries(roles).map(([key, label]) => ({ value: key, label })),
    },
    {
      fieldType: 'text',
      name: 'name',
      label: 'Name',
    },
    {
      fieldType: 'text',
      name: 'phoneNumber',
      label: 'Phone Number',
    },
    {
      fieldType: 'text',
      name: 'slackChannel',
      label: 'Slack Channel',
    },
  ];

  const initialValues = {
    email: '',
    type: '',
    name: '',
    phoneNumber: '',
    slackChannel: '',
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required('Required')
      .email('Invalid email address'),
    type: Yup.string()
      .required('Required'),
    phoneNumber: Yup.string()
      .test(
        'is-valid-phone',
        'Invalid phone number',
        (value) => !value || validator.isMobilePhone(value, 'any', { strictMode: true }),
      ),
  });

  const createContact = async (formValues) => {
    onCancel();

    const [data] = await createContactCall({
      ...formValues,
      juiceGroup,
    });

    if (data) {
      onSubmit(data.contacts);
    }
  };

  return (
    <FormModal
      title="Create New Contact"
      fields={fields}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={createContact}
      onCancel={onCancel}
      submitLabel="Create"
    />
  );
};

CreateContactFormModal.propTypes = {
  juiceGroup: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default CreateContactFormModal;
