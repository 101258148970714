import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { removeMachineAccountTokens } from '../../../../../redux/safescarf/machineAccounts/actions';
import Modal from '../../../../../components/Modal';
import Button from '../../../../../components/Buttons/Button';
import { useCopyToClipboard } from '../../../../../hooks/useCopyToClipboard';
import { CopyField, TextField } from '../../../../../components/InputFields';

const MachineAccountsApiKeyModal = ({ machineAccountName, machineAccountToken }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const copyToClipboard = useCopyToClipboard();

  return (
    <Modal
      hideActions
      title="New Machine Account created"
      onClose={() => dispatch(removeMachineAccountTokens())}
      submitLabel="Save"
    >
      <Typography gutterBottom>
        {intl.formatMessage({ id: 'SafescarfMachineAccountCreated' })}
      </Typography>
      <Grid
        container
        direction="column"
        gap={2}
      >
        <Grid item>
          <CopyField
            label="Username"
            name="username"
            value={machineAccountName}
          />
        </Grid>
        <Grid item>
          <Typography fontWeight="bold" gutterBottom>
            {intl.formatMessage({ id: 'APIkeyGenerated' })}
          </Typography>
          <Typography>
            {intl.formatMessage({ id: 'APIkeyCopy1' })}
          </Typography>
          <Typography gutterBottom>
            {intl.formatMessage({ id: 'APIkeyCopy2' })}
          </Typography>
          <Grid
            container
            alignItems="center"
            gap={1}
          >
            <Grid item xs>
              <TextField
                label="API key"
                name="APIkey"
                value={machineAccountToken}
                InputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item>
              <Button
                label="Copy"
                onClick={() => copyToClipboard(machineAccountToken)}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
};

MachineAccountsApiKeyModal.propTypes = {
  machineAccountName: PropTypes.string,
  machineAccountToken: PropTypes.string,
};
MachineAccountsApiKeyModal.defaultProps = {
  machineAccountName: '',
  machineAccountToken: '',
};

export default MachineAccountsApiKeyModal;
