import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import Provision from '../../api/provision';
import CreateContactFormModal from './CreateContactFormModal';
import useApiCall from '../../hooks/useApiCall';
import rbacCheck from '../../rbac/rbacCheck';
import Table from '../../components/Table';

const JuiceGroupContacts = ({ juiceGroup: propJuiceGroup }) => {
  const { juiceGroup: paramJuiceGroup } = useParams();
  const intl = useIntl();
  const navigate = useNavigate();
  const roles = useSelector((state) => state.user.roles);

  const [contacts, setContacts] = useState(null);
  const [createModalOpened, setCreateModalOpened] = useState(false);

  const getJuicegroupInfoCall = useApiCall(Provision.fetchJuiceGroupInfo);
  const canAddEditContacts = rbacCheck(roles, '/products:CONTACTS_PATCH');

  const juiceGroup = propJuiceGroup || paramJuiceGroup;

  useEffect(() => {
    const getContacts = async () => {
      const [data] = await getJuicegroupInfoCall({ juiceGroup });

      if (data) setContacts(data.contacts);
    };

    getContacts();
  }, []);

  const lookupType = {
    stakeHolder: 'Stakeholder',
    serviceOwner: 'Service Owner',
    tenantOwner: 'Tenant Owner',
    onboardingSupport: 'Onboarding Support',
  };

  const fields = [
    { id: 'email', title: 'Email' },
    { id: 'role', title: 'Role' },
    { id: 'name', title: 'Name' },
    { id: 'phone', title: 'Phone' },
    { id: 'slack', title: 'Slack' },
  ];

  const rows = contacts?.map((contact) => ({
    key: contact._id,
    onRowClick: () => (paramJuiceGroup ? navigate(`contacts/${contact._id}`) : navigate(`contacts/${propJuiceGroup}/${contact._id}`)),
    data: [
      {
        field: 'email',
        value: contact.email,
      },
      {
        field: 'role',
        value: lookupType[contact.type],
      },
      {
        field: 'name',
        value: contact.name || '',
      },
      {
        field: 'phone',
        value: contact.phoneNumber || '',
      },
      {
        field: 'slack',
        value: contact.slackChannel || '',
      },
    ],
  }));

  const emailFile = new Blob([contacts?.map((contact) => contact.email).join(';')], { type: 'text/plain' });

  return (
    <>
      {createModalOpened && (
        <CreateContactFormModal
          juiceGroup={juiceGroup}
          onSubmit={(newContact) => setContacts(newContact)}
          onCancel={() => setCreateModalOpened(false)}
        />
      )}
      <Table
        header="Contacts"
        fields={fields}
        rows={rows}
        createAction={{
          disabled: !canAddEditContacts,
          onClick: () => setCreateModalOpened(true),
          tooltip: !canAddEditContacts ? intl.formatMessage({ id: 'contactCreateDisabled' }) : '',
        }}
        exportData={paramJuiceGroup ? {
          name: 'Export Emails',
          fileName: `${paramJuiceGroup}_emails.txt`,
          file: emailFile,
        } : null}
      />
    </>
  );
};

JuiceGroupContacts.defaultProps = {
  juiceGroup: null,
};

JuiceGroupContacts.propTypes = {
  juiceGroup: PropTypes.string,
};

export default JuiceGroupContacts;
