import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import {
  Breadcrumbs,
  Container,
  Grid,
  Typography,
} from '@mui/material';
import rbacCheck from '../../../../../rbac/rbacCheck';
import OverviewTable from '../../../../../components/OverviewTable/OverviewTable';
import EditProductTypeMemberFormModal from './EditProductTypeMemberFormModal';

import { fetchUserProfile } from '../../../../../redux/safescarf/userProfile/actions';
import { fetchMachineAccounts } from '../../../../../redux/safescarf/machineAccounts/actions';
import { fetchRoles } from '../../../../../redux/safescarf/roles/actions';
import { fetchProductTypes } from '../../../../../redux/safescarf/productTypes/actions';
import { fetchProductTypeMembers } from '../../../../../redux/safescarf/productTypeMembers/actions';
import { fetchUsers as fetchSafescarfUsers } from '../../../../../redux/safescarf/users/actions';

function getMember(user, productTypeMembers, isMachineAccount, productTypeId) {
  const productTypeMember = productTypeMembers.find((m) => m.product_type === Number(productTypeId)
    && m.user === user.id);

  return {
    userId: user.id,
    username: user.username,
    createdBy: user.email,
    globalRole: user.globalRole,
    productTypeMemberId: productTypeMember?.id,
    productTypeMemberRole: productTypeMember?.role,
    isMachineAccount,
  };
}

const ProductTypeMemberDetails = () => {
  const { productTypeId, safescarfUserId } = useParams();
  const dispatch = useDispatch();

  const [editModalOpened, setEditModalOpened] = useState(false);

  const userData = useSelector((state) => state.user);
  const roles = useSelector((state) => state.safescarf.roles);
  const productTypes = useSelector((state) => state.safescarf.productTypes);
  const productTypeMembers = useSelector((state) => state.safescarf.productTypeMembers);
  const safeScarfUserProfile = useSelector((state) => state.safescarf.userProfile);
  const machineAccounts = useSelector((state) => state.safescarf.machineAccounts);
  const safescarfUsers = useSelector((state) => state.safescarf.users);

  // Find specific user, product, and machine account
  const safescarfUser = safescarfUsers.find((user) => user.id === Number(safescarfUserId));
  const machineAccount = machineAccounts.find((ma) => ma.id === Number(safescarfUserId));
  const productType = productTypes.find((pt) => pt.id === Number(productTypeId));

  const canGetSafeScarfUsers = rbacCheck(userData.roles, '/products/safescarf/product-types:GET_USERS', {
    safescarfUser: safeScarfUserProfile, productType,
  });

  useEffect(() => {
    dispatch(fetchUserProfile());
    dispatch(fetchProductTypes());
    dispatch(fetchMachineAccounts());
    dispatch(fetchRoles());
    dispatch(fetchProductTypeMembers());
  }, []);

  useEffect(() => {
    if (canGetSafeScarfUsers && !safescarfUsers.length) {
      dispatch(fetchSafescarfUsers({ productTypeId }));
    }
  }, [safescarfUsers, safeScarfUserProfile]);

  const currentUser = useMemo(() => {
    if (safeScarfUserProfile && productTypeMembers.length) {
      return getMember(safeScarfUserProfile, productTypeMembers, false, productTypeId);
    }
    return null;
  }, [safeScarfUserProfile, productTypeMembers, productTypeId]);

  const member = useMemo(() => {
    if (machineAccount && productTypeMembers.length) {
      return getMember(machineAccount, productTypeMembers, true, productTypeId);
    }
    if (safescarfUser && productTypeMembers.length) {
      return getMember(safescarfUser, productTypeMembers, false, productTypeId);
    }
    return null;
  }, [machineAccount, productTypeMembers, safescarfUser, productTypeId]);

  const canEditProductTypeMembers = useMemo(() => currentUser && rbacCheck(
    userData.roles,
    '/products/safescarf/product-types:PUT_MEMBERS',
    currentUser,
  ), [currentUser, userData.roles]);

  const roleLookup = useMemo(() => roles.reduce((acc, role) => {
    acc[role.id] = role.name;
    return acc;
  }, {}), [roles]);

  return (
    <>
      {editModalOpened && (
        <EditProductTypeMemberFormModal
          productTypeMember={member}
          onSubmit={() => setEditModalOpened(false)}
          onCancel={() => setEditModalOpened(false)}
        />
      )}
      <Grid
        container
        className="content-with-header"
        direction="column"
        rowGap={4}
      >
        <Grid item alignSelf="flex-start">
          <Breadcrumbs key={productType?.name} separator=">">
            <Link to="/products/safescarf">Safescarf</Link>
            <Link to="/products/safescarf/product-types">product types</Link>
            <Link to={`/products/safescarf/product-types/${productType?.id}`}>{productType?.name}</Link>
            <Link to={`/products/safescarf/product-types/${productType?.id}`}>members</Link>
            <Typography>{member?.username}</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <Container maxWidth="lg">
            {member ? (
              <OverviewTable
                header={member?.username}
                rows={[
                  { title: 'Product type:', value: productType?.name },
                  { title: 'Machine account:', value: member?.isMachineAccount ? 'Yes' : 'No' },
                  { title: 'Created by:', value: member?.createdBy },
                  { title: 'Role:', value: roleLookup[member?.productTypeMemberRole] || 'Not assigned' },
                  member?.globalRole && { title: 'Global role:', value: roleLookup[member?.globalRole] },
                ].filter((r) => r)}
                editAction={{
                  onClick: () => setEditModalOpened(true),
                  disabled: !canEditProductTypeMembers,
                  tooltip: 'editProductTypeMemberDisabled',
                }}
              />
            ) : (
              <Typography>Member information unavailable</Typography>
            )}
          </Container>
        </Grid>
      </Grid>
    </>
  );
};


export default ProductTypeMemberDetails;
