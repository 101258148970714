import { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Container, Typography } from '@mui/material';
import Auth from '../../../../api/auth';
import Provision from '../../../../api/provision';
import useApiCall from '../../../../hooks/useApiCall';
import Accordion from '../../../../components/Accordion/Accordion';
import EvaluationForm from './EvaluationForm';
import SuccessModal from '../../../../components/Modal/SuccessModal';

const trialQuota = {
  compute: { cores: 10, ram: 1024 },
  network: { floatingIps: 1 },
  volume: { gigabytes: 10, objectsMegabytes: 0 },
};

const Description = () => (
  <>
    <Typography gutterBottom>
      The evaluation product enables user to place workloads on the pan-net.cloud
      using Cloud APIs and a full set of user documentation.
      The product includes 10 vCPUs, 10GB RAM and 10GB storage.
      The product is available for a 3 month testing period starting from order confirmation.
      An organization can order 1 evaluation products in total within a time frame of
      one year starting from the creation of the organization in the pan-net.cloud portal.
    </Typography>
    <Typography gutterBottom>
      This product is governed by the General Terms & Conditions (GT&C) for using pan-net.cloud.
    </Typography>
    <Typography gutterBottom>
      The Product Specification for evaluation products
      is applicable as published on the pan-net.cloud portal.
    </Typography>
    <Typography gutterBottom>
      The Service Level Agreement is not applicable for evaluation products.
    </Typography>
  </>
);

const Evaluation = () => {
  const navigate = useNavigate();
  const userData = useSelector((state) => state.user);

  const [trialProduct, setTrialProduct] = useState(null);
  const [virtualCloud, setVirtualCloud] = useState({});
  const [osProjectOrdered, setOsProjectOrdered] = useState(false);

  const fetchProducts = useApiCall(Auth.fetchV2Products);
  const getVirtualCloudsCall = useApiCall(Provision.fetchVirtualClouds);
  const createOsProject = useApiCall(Provision.createOsProject);

  const trialYearRevoked = useMemo(() => {
    const oneYearMs = 365 * 24 * 3600 * 1000;
    return new Date() - new Date(userData.organization.createdAt) > oneYearMs;
  }, [userData.organization.createdAt]);

  const trialEnabled = useMemo(
    () => userData.organization.marketplaceItemIds?.some((item) => item.name === 'Evaluation'),
    [userData.organization.marketplaceItemIds],
  );

  useEffect(() => {
    if (trialYearRevoked || !trialEnabled) return;

    const getTrialProduct = async () => {
      const [data] = await fetchProducts({ type: 'evaluation' });
      if (data?.length) setTrialProduct(data[0]);
    };

    getTrialProduct();
  }, [trialYearRevoked, trialEnabled]);

  useEffect(() => {
    if (!trialProduct) return;

    const getTrialVirtualCloud = async () => {
      const { juiceGroup } = trialProduct.details;
      const [data] = await getVirtualCloudsCall({ juiceGroups: [juiceGroup] });

      if (data) {
        setVirtualCloud({
          id: data[0].cloud.name,
          projects: data[0].projects,
        });
      }
    };

    getTrialVirtualCloud();
  }, [trialProduct]);

  const handleSubmit = async ({ osProjectName }) => {
    const [data] = await createOsProject({
      osProjectName,
      cloudId: virtualCloud.id,
      type: 'computeTrial',
      juiceGroup: trialProduct.details.juiceGroup,
      projectId: trialProduct._id,
      productId: trialProduct._id,
      organizationId: userData.organizationId,
      requestedQuota: trialQuota,
    });

    if (data) setOsProjectOrdered(true);
  };

  const renderEvaluationDetails = () => {
    if (trialYearRevoked) {
      return <Typography>Your right to use the free trial product has expired.</Typography>;
    }
    if (!trialEnabled) {
      return <Typography>Trial product is not enabled for your organization.</Typography>;
    }
    if (!trialProduct) {
      return <Typography>You are not assigned to trial product.</Typography>;
    }

    return virtualCloud.projects?.length ? (
      <>
        <Typography display="inline">
          Your compute package is allocated to the trial product of your organization,
        </Typography>
        <Typography variant="h6" display="inline">
          {' '}
          {trialProduct.name}
        </Typography>
        <Typography gutterBottom>
          Navigate to the products page by clicking
          {' '}
          <Link to="/products/compute">
            <Typography component="span" display="inline">here</Typography>
          </Link>
        </Typography>
      </>
    ) : (
      <>
        <Typography display="inline">
          Your compute package will be allocated to the trial product of your organization,
        </Typography>
        <Typography variant="h6" display="inline">
          {' '}
          {trialProduct?.name}
        </Typography>
      </>
    );
  };

  return (
    <Container maxWidth="md">
      {osProjectOrdered && (
        <SuccessModal
          title="Evaluation OS-Project Ordered"
          messageId="createOsProjectFulfilled"
          onSubmit={() => navigate('/products/compute')}
          onClose={() => navigate('/products/compute')}
        />
      )}
      <Typography variant="h4" color="primary" display="inline">
        Evaluation product
      </Typography>
      <Typography variant="h5" display="inline"> (Free of charge)</Typography>
      <Accordion
        defaultExpanded
        title="Project Description"
        details={<Description />}
      />
      <br />
      {renderEvaluationDetails()}
      <Typography>Define a name for the OpenStack project (tenant):</Typography>
      <EvaluationForm
        disabled={
            !trialProduct
            || trialYearRevoked
            || !trialEnabled
            || virtualCloud.projects?.length > 0
          }
        onSubmit={handleSubmit}
      />
    </Container>
  );
};

export default Evaluation;
