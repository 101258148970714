import PropTypes from 'prop-types';
import { useField, useFormikContext } from 'formik';
import Chip from '../../InputFields/Chip';

export const FormikChip = ({
  name,
  label,
  value,
  color,
  size,
  variant,
  disabled,
  onDelete,
}) => {
  const [field] = useField(name);
  const { setFieldValue } = useFormikContext();

  let fieldValue = [];
  if (Array.isArray(field.value)) {
    fieldValue = field.value;
  } else if (field.value) {
    fieldValue = [field.value];
  }

  const isSelected = fieldValue.includes(value);

  const handleClick = () => {
    if (isSelected) {
      setFieldValue(name, fieldValue.filter((item) => item !== value));
    } else {
      setFieldValue(name, [...fieldValue, value]);
    }
  };

  return (
    <Chip
      label={label}
      color={(!isSelected && !onDelete) ? color : 'primary'}
      size={size}
      variant={variant}
      disabled={disabled}
      onClick={handleClick}
      onDelete={onDelete}
    />
  );
};

FormikChip.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  color: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium']),
  variant: PropTypes.oneOf(['filled', 'outlined']),
  disabled: PropTypes.bool,
  onDelete: PropTypes.func,
};

FormikChip.defaultProps = {
  color: 'default',
  size: 'small',
  variant: 'filled',
  disabled: false,
  onDelete: null,
};
