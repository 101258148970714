import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFormikContext } from 'formik';
import Auth from '../../../../api/auth';
import useApiCall from '../../../../hooks/useApiCall';
import { FormikSelect } from '../../../../components/Formik';

const ProjectSelect = ({ setFilteredProducts }) => {
  const fetchProjects = useApiCall(Auth.fetchV2Projects);
  const fetchProducts = useApiCall(Auth.fetchV2Products);
  const userData = useSelector((state) => state.user);
  const [projects, setProjects] = useState([]);
  const { values: { osProjectName }, setValues } = useFormikContext();

  useEffect(() => {
    async function getProjectsAndProducts() {
      const [projectsData] = await fetchProjects({}, false);
      const [productsData] = await fetchProducts({ type: 'compute' });

      if (projectsData && productsData) {
        const filteredProducts = productsData.filter((product) => (
          product.approved && userData?.productIds?.includes(product._id)
        ));
        const projectIds = new Set(filteredProducts.map((product) => product.projectId));
        const filteredProjects = projectsData.filter((project) => projectIds.has(project._id));

        setFilteredProducts(filteredProducts);
        setProjects(filteredProjects);
      }
    }
    getProjectsAndProducts();
  }, [userData, setFilteredProducts]);

  return (
    <FormikSelect
      name="projectId"
      label="Project"
      menuItems={projects.map((project) => ({
        value: project._id,
        label: project.name,
      }))}
      onChange={(value) => {
        setValues({
          osProjectName,
          projectId: value,
          productId: '',
          cloudName: '',
          cpuCores: '',
          ram: '',
          diskQuota: '',
          floatingIps: '',
          internetConnectivity: false,
          DTHConnectivity: false,
          DTHRedRouterConnectivity: false,
          juiceGroup: '',
          unusedQuota: {},
          externalNetworks: [],
        });
      }}
    />
  );
};

ProjectSelect.propTypes = {
  setFilteredProducts: PropTypes.func.isRequired,
};

export default ProjectSelect;
