import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { regexValidator } from '../../../helpers/regexValidator';
import useApiCall from '../../../hooks/useApiCall';
import Auth from '../../../api/auth';
import { FormModal } from '../../../components/Formik';

const EditOrganizationFormModal = ({ onSubmit, onCancel, organization }) => {
  const [accountOwners, setAccountOwners] = useState();

  const getAccountOwnersCall = useApiCall(Auth.fetchV2Users);

  useEffect(() => {
    const getAccountOwners = async () => {
      const [data] = await getAccountOwnersCall({
        organizationId: organization._id,
        roles: ['ACCOUNT_OWNER'],
      });

      if (data) {
        const activatedAccountOwners = data.filter((accountOwner) => accountOwner.activated);
        setAccountOwners(activatedAccountOwners);
      }
    };

    getAccountOwners();
  }, []);

  const fields = [
    {
      fieldType: 'text',
      name: 'enterpriseName',
      label: 'Name',
    },
    {
      fieldType: 'text',
      name: 'CRMId',
      label: 'CRM Id',
    },
    ...(accountOwners ? [
      {
        fieldType: 'select',
        name: 'accountOwnerId',
        label: 'Account Owner',
        menuItems: accountOwners?.map((accountOwner) => ({
          value: accountOwner._id,
          label: accountOwner.email,
        })),
      },
    ] : []),
  ];

  const initialValues = {
    enterpriseName: organization.enterpriseName,
    CRMId: organization.CRMId,
    accountOwnerId: organization.accountOwnerId || '',
  };

  const validationSchema = Yup.object().shape({
    enterpriseName: Yup.string()
      .required('Required')
      .matches(
        regexValidator.name,
        'Start with letter, all lower-case, no spaces, no special characters(dash only)',
      ),
  });

  return (
    <FormModal
      title={`Edit organization ${organization.enterpriseName}`}
      fields={fields}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      onCancel={onCancel}
      submitLabel="Save"
    />
  );
};

EditOrganizationFormModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  organization: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
      PropTypes.array,
      PropTypes.object,
    ]),
  ).isRequired,
};

export default EditOrganizationFormModal;
