import { useState, useEffect } from 'react';
import {
  Outlet, useLocation, useNavigate, useParams,
} from 'react-router-dom';

import MonitoringMs from '../../../../api/monitoring';
import useApiCall from '../../../../hooks/useApiCall';


const nowDate = new Date().toISOString().slice(0, 16);
const nowMinus30Days = new Date(
  new Date().setDate(new Date().getDate() - 30),
).toISOString().slice(0, 16);
const Monitoring = () => {
  const { dashboardId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const getDashboardApiCall = useApiCall(MonitoringMs.getDashboard);
  const [dashboardData, setDashboardData] = useState();

  const getDashboardData = async () => {
    const [data] = await getDashboardApiCall(dashboardId);
    if (data) {
      setDashboardData(data);
    }
  };

  const searchParams = new URLSearchParams(location.search);
  const from = searchParams.get('from');
  const to = searchParams.get('to');

  useEffect(() => {
    getDashboardData();
  }, []);

  useEffect(() => {
    if (dashboardData) {
      let newUrl = `${location.pathname}?from=${nowMinus30Days}&to=${nowDate}`;
      const templates = dashboardData.templating.filter((template) => template.hide === 0);
      templates.forEach((template) => {
        const value = searchParams.get(template.name) || template.current.value;
        newUrl += `&${template.name}=${value}`;
      });
      navigate(newUrl, { replace: true });
    }
  }, [dashboardData]);

  return dashboardData && (from && to) ? (
    <div>
      <Outlet context={[dashboardData, setDashboardData]} />
    </div>
  ) : null;
};

export default Monitoring;
