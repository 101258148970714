import PropTypes from 'prop-types';
import * as Yup from 'yup';
import FormikForm from '../../../../components/Formik/Form/FormikForm';
import { regexValidator } from '../../../../helpers/regexValidator';

const EvaluationForm = ({ disabled, onSubmit }) => {
  const fields = [
    {
      fieldType: 'text',
      name: 'osProjectName',
      label: 'Project Name',
      disabled,
    },
  ];

  const initialValues = {
    osProjectName: '',
  };

  const validationSchema = Yup.object().shape({
    osProjectName: Yup.string()
      .required('Required')
      .matches(regexValidator.noSpace, 'Spaces not allowed'),
  });

  return (
    <FormikForm
      fields={fields}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      submitLabel="Submit"
    />
  );
};

EvaluationForm.propTypes = {
  disabled: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default EvaluationForm;
