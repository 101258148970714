import { useKeycloak } from '@react-keycloak/web';
import {
  ScaleTelekomNavList,
  ScaleTelekomNavItem,
} from '@telekom/scale-components-react';
import { Link, useLocation } from 'react-router-dom';
import { Typography, Tooltip } from '@mui/material';
import useAppContext from '../../hooks/useAppContext';
import {
  Brightness4Icon,
  Brightness7Icon,
  PersonOutlineIcon,
  LogoutIcon,
} from '../icons/IconLibrary';

const FunctionNavigation = () => {
  const { keycloak, initialized } = useKeycloak();
  const { pathname } = useLocation();
  const { toggleTheme, cookiesAccepted, theme } = useAppContext();

  const isActive = (path) => pathname.startsWith(path) || undefined;
  const { authenticated, idTokenParsed } = keycloak;

  return initialized ? (
    <ScaleTelekomNavList slot="functions" alignment="right" aria-label="Functions Menu">
      {!authenticated && (
        <Tooltip
          title={(
            <Typography>
              Please confirm you&apos;ve read and understood our Privacy Policy
              on data use and protection.
            </Typography>
          )}
          disableHoverListener={cookiesAccepted}
        >
          <ScaleTelekomNavItem aria-label="Login" variant="functions">
            <Link
              to="#/"
              onClick={
                cookiesAccepted
                  ? () => keycloak.login({ redirectUri: window.location.origin })
                  : undefined
            }
              role="menuitem"
              style={{
                pointerEvents: cookiesAccepted ? 'auto' : 'none',
                color: cookiesAccepted ? 'inherit' : 'gray',
              }}
            >
              <PersonOutlineIcon />
              <Typography>Login</Typography>
            </Link>
          </ScaleTelekomNavItem>
        </Tooltip>
      )}

      {authenticated && (
        <ScaleTelekomNavItem aria-label="myAccount" variant="functions" active={isActive('/account')}>
          <Link to="/account" role="menuitem">
            <PersonOutlineIcon />
            <Typography>
              {idTokenParsed?.email}
            </Typography>
          </Link>
        </ScaleTelekomNavItem>
      )}

      {authenticated && (
        <ScaleTelekomNavItem aria-label="Logout" variant="functions">
          <Link to="#/" onClick={() => keycloak.logout({ redirectUri: window.location.origin })} role="menuitem">
            <LogoutIcon />
            <Typography>Logout</Typography>
          </Link>
        </ScaleTelekomNavItem>
      )}

      <ScaleTelekomNavItem aria-label="Switch Mode" variant="functions">
        <button
          type="button"
          onClick={toggleTheme}
          aria-label="Switch between light and dark mode"
        >
          {theme === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
        </button>
      </ScaleTelekomNavItem>
    </ScaleTelekomNavList>
  ) : null;
};

export default FunctionNavigation;
