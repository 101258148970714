import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Markdown from 'markdown-to-jsx';
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  Tooltip,
  IconButton,
} from '@mui/material';
import { useNavigate, useLocation, useOutletContext } from 'react-router-dom';
import {
  InfoIcon,
  OpenInFullIcon,
} from '../../../../components/icons/IconLibrary';
import Accordion from '../../../../components/Accordion/Accordion';
import DateTimeFilter from './DateTimeFilter';
import Filter from './Filters';
import Stat from './Stat';
import BarChartComponent from './BarChart';
import TimeSeries from './TimeSeries';
import GaugeComponent from './Gauge';
import MonitoringTable from './Table';
import GeoMap from './GeoMap';
import './index.css';

const MonitoringComponents = ({ panelInfo, dateRange, filterParams }) => {
  switch (panelInfo.type) {
    case 'text':
      return <Markdown>{panelInfo.content}</Markdown>;
    case 'stat':
      return (
        <Stat
          panelInfo={panelInfo}
          dateRange={dateRange}
          filterParams={filterParams}
          styles={{
            fontSize: `${panelInfo.gridPos.h * 7}px`,
            height: panelInfo.gridPos.h * 40,
          }}
        />
      );
    case 'barchart':
      return (
        <BarChartComponent
          panelInfo={panelInfo}
          dateRange={dateRange}
          filterParams={filterParams}
          height={panelInfo.gridPos.h * 45}
        />
      );
    case 'timeseries':
      return (
        <TimeSeries
          panelInfo={panelInfo}
          dateRange={dateRange}
          filterParams={filterParams}
          height={panelInfo.gridPos.h * 45}
        />
      );
    case 'gauge':
      return (
        <GaugeComponent
          panelInfo={panelInfo}
          dateRange={dateRange}
          filterParams={filterParams}
          height={panelInfo.gridPos.h * 40}
        />
      );
    case 'table':
      return (
        <MonitoringTable
          panelInfo={panelInfo}
          dateRange={dateRange}
          filterParams={filterParams}
          styles={{ height: `${panelInfo.gridPos.h + 3}rem` }}
        />
      );
    case 'geomap':
      return (
        <GeoMap
          panelInfo={panelInfo}
          dateRange={dateRange}
          filterParams={filterParams}
          mapZoom={4}
          styles={{ height: `${panelInfo.gridPos.h * 3}rem`, width: '100%' }}
        />
      );
    default:
      return null;
  }
};

MonitoringComponents.propTypes = {
  panelInfo: PropTypes.shape({
    type: PropTypes.string,
    title: PropTypes.string,
    content: PropTypes.string,
    gridPos: PropTypes.shape({
      h: PropTypes.number,
      w: PropTypes.number,
      x: PropTypes.number,
      y: PropTypes.number,
    }).isRequired,
  }).isRequired,
  dateRange: PropTypes.shape({
    from: PropTypes.string,
    to: PropTypes.string,
  }).isRequired,
  filterParams: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
      PropTypes.object,
    ]),
  ).isRequired,
};

const MonitoringDashboard = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const context = useOutletContext();
  const [dashboardData, setDashboardData] = context;
  const searchParams = new URLSearchParams(location.search);
  const templates = dashboardData.templating.filter(
    (template) => template.hide === 0,
  );

  const [dateRange, setDateRange] = useState({
    from: searchParams.get('from'),
    to: searchParams.get('to'),
  });

  const [filterParams, setFilterParams] = useState(
    templates.reduce((acc, template) => {
      acc[template.name] = searchParams.get(template.name) || template.current.value;
      return acc;
    }, {}),
  );

  useEffect(() => {
    if (dashboardData) {
      setDashboardData(dashboardData);
    }
  }, [searchParams]);

  return dashboardData && searchParams ? (
    <div style={{ margin: '1rem -10%' }}>
      <Typography variant="h4" color="primary" gutterBottom>
        {dashboardData.title}
      </Typography>
      <DateTimeFilter
        dateRange={dateRange}
        setDateRange={setDateRange}
      />
      {templates?.length > 0 && <Filter setFilterParams={setFilterParams} templates={templates} />}
      {dashboardData.rows.map((accordion) => (
        <Accordion
          key={accordion.title}
          title={accordion.title}
          defaultExpanded
        >
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(24, 1fr)',
            }}
          >
            {accordion.panels.map((panel) => (
              <Card
                key={`${accordion.title}-${panel.title}-${panel.id}`}
                style={{
                  gridColumn: `${panel.gridPos.x + 1} / span ${panel.gridPos.w}`,
                  gridRow: `${panel.gridPos.y} / span ${panel.gridPos.h}`,
                  margin: '0.5rem',
                }}
              >
                <CardHeader
                  title={panel.title}
                  titleTypographyProps={{
                    style: {
                      width: `${panel.gridPos.w * 2}em`,
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    },
                  }}
                  action={(
                    <div style={{ marginTop: '0.5rem' }}>
                      <Tooltip title={panel.description}>
                        <IconButton size="small">
                          <InfoIcon fontSize="inherit" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="view details">
                        <IconButton
                          size="small"
                          onClick={() => {
                            let newUrl = `${panel.id.toString()}?from=${dateRange.from}&to=${dateRange.to}`;
                            templates.forEach((template) => {
                              const value = searchParams.get(template.name)
                                || template.current.value;
                              newUrl += `&${template.name}=${value}`;
                            });
                            navigate(newUrl, { replace: true });
                          }}
                        >
                          <OpenInFullIcon fontSize="inherit" />
                        </IconButton>
                      </Tooltip>
                    </div>
                  )}
                />
                <CardContent>
                  <MonitoringComponents
                    panelInfo={panel}
                    dateRange={dateRange}
                    filterParams={filterParams}
                  />
                </CardContent>
              </Card>
            ))}
          </div>
        </Accordion>
      ))}
    </div>
  ) : null;
};

export default MonitoringDashboard;
