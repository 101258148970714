import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { FormModal } from '../../../components/Formik';

const CreateUserFormModal = ({
  roles, onSubmit, onCancel,
}) => {
  const fields = [
    {
      fieldType: 'text',
      name: 'email',
      label: 'Email',
    },
    {
      fieldType: 'select',
      name: 'role',
      label: 'Role',
      menuItems: Object.keys(roles).map((key) => ({
        value: key,
        label: roles[key],
      })),
    },
  ];

  const initialValues = {
    email: '',
    role: '',
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().required('Required').email('Invalid email address'),
    role: Yup.string().required('Required'),
  });

  return (
    <FormModal
      title="Create New User"
      fields={fields}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      onCancel={onCancel}
      submitLabel="Create"
    />
  );
};

CreateUserFormModal.propTypes = {
  roles: PropTypes.objectOf(PropTypes.string).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default CreateUserFormModal;
