import { createTheme } from '@mui/material/styles';

export function createCustomTheme(mode) {
  return createTheme({
    palette: {
      mode,
      primary: {
        main: '#E20074',
      },
      secondary: {
        main: '#D90000',
      },
      error: {
        main: '#E82010',
      },
    },
    components: {
      MuiTableSortLabel: {
        styleOverrides: {
          root: {
            '& .MuiTableSortLabel-icon': {
              opacity: 1,
              color: mode === 'dark' ? '#444444' : '#D3D3D3',
            },
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            color: mode === 'dark' ? '#7d94f9' : 'blue',
            fontWeight: 'bold',
          },
        },
      },
      MuiAccordion: {
        styleOverrides: {
          root: {
            border: 'none',
            '&:before': {
              display: 'none',
            },
          },
        },
      },
      MuiAccordionSummary: {
        styleOverrides: {
          expandIconWrapper: {
            transform: 'rotate(-90deg)',
            '&.Mui-expanded': {
              transform: 'rotate(0deg)',
            },
          },
          root: {
            padding: 0,
            '& .MuiAccordionSummary-expandIconWrapper': {
              order: -1,
              marginRight: 5,
            },
          },
        },
      },
      MuiAccordionDetails: {
        styleOverrides: {
          root: {
            padding: 0,
          },
        },
      },
      MuiSwitch: {
        styleOverrides: {
          root: {
            width: 40,
            height: 24,
            padding: 3,
            margin: 5,
          },
          switchBase: {
            padding: 1,
            '&.Mui-checked': {
              transform: 'translateX(160px)',
              color: 'white',
              '& + .MuiSwitch-track': {
                backgroundColor: 'primary',
                opacity: 1,
                border: 'none',
              },
            },
            '&.Mui-disabled': {
              '& + .MuiSwitch-track': {
                backgroundColor: mode === 'dark' ? '#444444' : '#D3D3D3',
              },
              '& .MuiSwitch-thumb': {
                backgroundColor: mode === 'dark' ? '#777777' : '#E0E0E0',
              },
            },
          },
          track: {
            borderRadius: 13,
            backgroundColor: mode === 'dark' ? '#464646' : '#D9D9D9',
            opacity: 1,
            transition: 'background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
          },
        },
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            paddingTop: 0,
            paddingBottom: 0,
          },
        },
      },
      MuiListItemButton: {
        styleOverrides: {
          root: {
            borderLeft: '4px solid transparent',
            borderBottom: '1px solid',
            borderBottomColor: mode === 'dark' ? '#464646' : '#D9D9D9',
            backgroundColor: 'transparent',
          },
        },
      },
      MuiListItemText: {
        styleOverrides: {
          primary: {
            fontWeight: 'bold',
          },
        },
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            paddingBottom: '1.5rem',
            paddingRight: '1.5rem',
          },
        },
      },
      MuiFormControl: {
        defaultProps: {
          size: 'small',
          fullWidth: true,
          margin: 'dense',
        },
        variants: [{
          props: { 'data-variant': 'padded' },
          style: {
            paddingBottom: '1rem',
          },
        }],
        styleOverrides: {
          root: {
            '& input:-webkit-autofill': {
              WebkitBoxShadow: '0 0 0 1000px transparent inset !important',
            },
            '& .MuiInputBase-root.Mui-disabled': {
              backgroundColor: mode === 'dark' ? '#262626' : '#cfcfcf',
              cursor: 'not-allowed',
            },
            '& label.Mui-focused': {
              color: '#777',
            },
            '& .MuiInput-underline:after': {
              borderBottomColor: '#777',
            },
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#E0E3E7',
              },
              '&:hover fieldset': {
                borderColor: '#B2BAC2',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#777',
              },
            },
            '& .MuiInputBase-input': {
              color: mode === 'dark' ? 'white' : 'black',
            },
            color: '#777',
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          label: {
            color: mode === 'dark' ? 'white' : 'black',
          },
        },
      },
      MuiTextField: {
        defaultProps: {
          size: 'small',
          fullWidth: true,
          margin: 'dense',
        },
        styleOverrides: {
          root: {
            '& label.Mui-focused': {
              color: '#777',
            },
            '& .MuiInput-underline:after': {
              borderBottomColor: '#777',
            },
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#E0E3E7',
              },
              '&:hover fieldset': {
                borderColor: '#B2BAC2',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#777',
              },
            },
            color: '#777',
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          option: {
            '&[aria-selected="true"]': {
              backgroundColor: '#D0D0D2 !important',
            },
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            '&.Mui-disabled': {
              backgroundColor: '#D0D0D2',
              borderRadius: '.5rem',
              border: 'none',
            },
          },
          input: {
            '&::placeholder': {
              color: '#777',
              opacity: 1,
            },
            color: '#777',
          },
          inputSizeSmall: {
            '&::placeholder': {
              color: '#777',
              opacity: 1,
            },
            color: '#777',
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            '.MuiOutlinedInput-notchedOutline': {
              borderRadius: '.5rem',
              border: '.0625rem solid rgba(0, 0, 0, 0.44)',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: '#777',
            },
          },
          input: {
            '&::placeholder': {
              color: '#777',
              opacity: 1,
            },
            color: '#777',
          },
          inputSizeSmall: {
            '&::placeholder': {
              color: '#777',
              opacity: 1,
            },
            color: '#777',
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: '#777',
            '&.Mui-focused': {
              color: '#777',
            },
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            color: '#777',
            '&.Mui-focused': {
              color: '#777',
            },
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            '&.Mui-selected': {
              backgroundColor: mode === 'dark' ? '#5c5c5c !important' : '#D0D0D2 !important',
              '&:hover': {
                backgroundColor: '#D0D0D2',
              },
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            '& .MuiButton-endIcon': {
              '& .MuiSvgIcon-root': {
                fontSize: '0.8rem',
              },
            },
            textTransform: 'capitalize',
            fontWeight: 'bold',
            borderRadius: '.5rem',
            boxShadow: 'none',
            '&:hover': {
              boxShadow: 'none',
            },
          },
        },
      },
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true,
        },
      },
      MuiPaper: {
        defaultProps: {
          square: true,
          variant: 'outlined',
        },
        styleOverrides: {
          root: {
            borderRadius: '.5rem',
          },
        },
      },
      MuiBreadcrumbs: {
        styleOverrides: {
          root: {
            color: '#E20074',
          },
          ol: {
            '& .MuiBreadcrumbs-separator': {
              color: '#E20074',
            },
            '& .MuiBreadcrumbs-li': {
              color: '#E20074',
            },
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            borderRadius: '.3rem',
          },
          label: {
            fontWeight: 'bold',
          },
        },
      },
      MuiTableContainer: {
        styleOverrides: {
          root: {
            borderRadius: '.5rem',
          },
        },
      },
      MuiTableHead: {
        styleOverrides: {
          root: {
            backgroundColor: mode === 'dark' ? '#262626' : '#F5F5F5',
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            padding: '.3rem',
            fontFamily: 'TeleNeoWeb',
            fontSize: '1rem',
          },
          head: {
            fontSize: '0.8rem',
            fontWeight: 'bold',
          },
        },
      },
      MuiTablePagination: {
        styleOverrides: {
          root: {
            borderBottom: 'none',
          },
        },
      },
    },
    typography: {
      fontFamily: 'TeleNeoWeb',
    },
  });
}
