import { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  Link,
  Outlet,
  useLocation,
  useParams,
} from 'react-router-dom';
import {
  Breadcrumbs, Grid, Typography,
} from '@mui/material';
import SidebarNavigation from '../../components/SidebarNavigation/SidebarNavigation';
import rbacCheck from '../../rbac/rbacCheck';
import useApiCall from '../../hooks/useApiCall';
import Auth from '../../api/auth';

const OrganizationDetails = () => {
  const { pathname } = useLocation();
  const currentPath = pathname.split('/')[3];

  const { organizationId } = useParams();
  const userRoles = useSelector((state) => state.user.roles);

  const [organization, setOrganization] = useState({});

  const fetchOrganizationById = useApiCall(Auth.fetchV1OrganizationById);

  useEffect(() => {
    const getOrganization = async () => {
      const [data] = await fetchOrganizationById(organizationId);
      if (data) setOrganization(data);
    };

    getOrganization();
  }, []);

  const permissions = useMemo(() => ({
    canSeeUsers: rbacCheck(userRoles, '/users:VIEW'),
    canSeeBusinessUnits: rbacCheck(userRoles, '/business-units:VIEW'),
    canSeeProjects: rbacCheck(userRoles, '/projects:VIEW'),
    canSeeMonitoring: rbacCheck(userRoles, '/monitoring-dashboards', organization),
    canSeeOrganizations: rbacCheck(userRoles, '/organizations:VIEW_ALL'),
  }), [userRoles, organization]);

  const menuItems = useMemo(() => [
    { title: 'Overview' },
    permissions.canSeeUsers && { title: 'Users', path: 'users' },
    permissions.canSeeBusinessUnits && { title: 'Business Units', path: 'business-units' },
    permissions.canSeeProjects && { title: 'Projects', path: 'projects' },
    permissions.canSeeMonitoring && { title: 'Monitoring Dashboards', path: 'monitoring-dashboards' },
  ].filter(Boolean), [permissions]);

  return (
    <Grid
      container
      className="content-with-header"
      direction="column"
    >
      <Grid item>
        <Breadcrumbs separator=" > ">
          {permissions.canSeeOrganizations
            ? <Link to="/organizations">organizations</Link>
            : <Link to={`/organizations/${organizationId}`}>organizations</Link>}
          <Typography>{organization.enterpriseName}</Typography>
          {currentPath && <Typography>{currentPath}</Typography>}
        </Breadcrumbs>
      </Grid>
      <Grid item paddingTop="2rem">
        <Grid container justifyContent="space-between">
          <Grid item xs={2}>
            <SidebarNavigation menuItems={menuItems} currentPath={currentPath} />
          </Grid>
          <Grid item xs={9}>
            <Outlet context={[organization, setOrganization]} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OrganizationDetails;
