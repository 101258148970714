import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { FormModal } from '../../../../../components/Formik';
import { fetchRoles } from '../../../../../redux/safescarf/roles/actions';
import { updateProductTypeMemberRole } from '../../../../../redux/safescarf/productTypeMembers/actions';

const roleLookup = (roles) => roles?.reduce((acc, role) => {
  acc[role.id] = role.name;
  return acc;
}, {}) || {};

const EditProductTypeMemberFormModal = ({
  productTypeMember, onSubmit, onCancel,
}) => {
  const dispatch = useDispatch();
  const { productTypeId } = useParams();
  const roles = useSelector((state) => state.safescarf.roles);

  useEffect(() => {
    if (!roles.length) dispatch(fetchRoles());
  }, []);

  const userTypeRoles = productTypeMember.isMachineAccount
    ? roleLookup(roles.filter((role) => role.id === 1))
    : roleLookup(roles.filter((role) => [2, 5].includes(role.id)));

  const fields = [
    {
      fieldType: 'select',
      name: 'role',
      label: 'Role',
      menuItems: Object.keys(userTypeRoles).map((key) => ({
        value: key,
        label: userTypeRoles[key],
      })),
    },
  ];

  const initialValues = {
    role: productTypeMember.productTypeMemberRole?.toString() || '',
  };

  const handleSubmit = ({ role }) => {
    onSubmit();
    dispatch(updateProductTypeMemberRole({
      memberId: productTypeMember.productTypeMemberId,
      user: productTypeMember.userId,
      product_type: productTypeId,
      role: role || '',
    }));
  };

  return (
    <FormModal
      title={`Edit product type member ${productTypeMember.username}`}
      fields={fields}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      onCancel={onCancel}
      submitLabel="Save"
    />
  );
};

EditProductTypeMemberFormModal.propTypes = {
  productTypeMember: PropTypes.shape({
    username: PropTypes.string,
    userId: PropTypes.number,
    productTypeMemberId: PropTypes.number,
    productTypeMemberRole: PropTypes.number,
    isMachineAccount: PropTypes.bool,
  }),
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

EditProductTypeMemberFormModal.defaultProps = {
  productTypeMember: undefined,
};

export default EditProductTypeMemberFormModal;
