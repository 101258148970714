import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { updateProductType } from '../../../../../redux/safescarf/productTypes/actions';
import { FormModal } from '../../../../../components/Formik';

const EditProductTypeFormModal = ({ productType, onSubmit, onCancel }) => {
  const dispatch = useDispatch();
  const { productTypeId } = useParams();

  const fields = [
    {
      fieldType: 'text',
      name: 'name',
      label: 'Name',
    },
    {
      fieldType: 'text',
      name: 'description',
      label: 'Description',
    },
  ];

  const initialValues = {
    name: productType.name,
    description: productType.description,
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    description: Yup.string(),
  });

  const handleSubmit = async (formValues) => {
    onSubmit();
    dispatch(updateProductType({ ...formValues, id: productTypeId }));
  };

  return (
    <FormModal
      title={`Edit product type ${productType.name}`}
      fields={fields}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      onCancel={onCancel}
      submitLabel="Save"
    />
  );
};

EditProductTypeFormModal.propTypes = {
  productType: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
  }),
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

EditProductTypeFormModal.defaultProps = {
  productType: {},
};

export default EditProductTypeFormModal;
