import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { useShikiHighlighter } from 'react-shiki';
import useAppContext from '../../hooks/useAppContext';

const CodeBlock = ({ code, language }) => {
  const { theme } = useAppContext();
  const themeMode = theme === 'dark' ? 'github-dark' : 'github-light';
  const highlighted = useShikiHighlighter(code, language, themeMode);

  return (
    <Grid
      component="pre"
      sx={{
        margin: 0,
        padding: '1rem',
        borderRadius: '0.5rem',
        fontSize: '0.75rem',
        overflowX: 'auto',
        whiteSpace: 'pre-wrap',
        wordBreak: 'break-word',
        backgroundColor: (themeContext) => themeContext.palette.background.paper,
      }}
    >
      {highlighted}
    </Grid>
  );
};

CodeBlock.propTypes = {
  code: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired,
};

export default CodeBlock;
