import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@mui/material';
import { Select } from '../../../../../components/InputFields/index';
import ForecastSummary from './ForecastSummary';
import VirtualCloudsSummary from './VirtualCloudsSummary';
import UsageSummary from './UsageSummary';
import Modal from '../../../../../components/Modal';
import Provision from '../../../../../api/provision';
import useApiCall from '../../../../../hooks/useApiCall';

const Summaries = ({ juiceGroup, onClose }) => {
  const [cloudId, setCloudId] = useState('');
  const [virtualClouds, setVirtualClouds] = useState([]);

  const getVirtualCloudsCall = useApiCall(Provision.fetchVirtualClouds);

  useEffect(() => {
    const getVirtualClouds = async () => {
      const [data] = await getVirtualCloudsCall({ juiceGroups: [juiceGroup] });

      if (data?.length > 0) {
        setVirtualClouds(data);
      }
    };

    if (juiceGroup) getVirtualClouds();
  }, [juiceGroup]);

  return (
    <Modal
      hideActions
      title="Virtual Cloud Summaries"
      maxWidth={cloudId ? 'lg' : 'xs'}
      onClose={onClose}
      submitLabel="Save"
    >
      <Grid
        container
        direction="column"
        pb={2}
      >
        <Grid item>
          <Select
            id="cloudDropdown"
            name="cloudDropdown"
            value={cloudId}
            label="Select a cloud to see the available hypervisors"
            onChange={(value) => setCloudId(value)}
            menuItems={virtualClouds?.map((virtualCloud) => ({
              value: virtualCloud.cloud.name,
              label: virtualCloud.cloud.name,
            }))}
          />
        </Grid>
        {cloudId && (
          <Grid
            container
            direction="column"
            gap={2}
          >
            <Grid item>
              <Typography variant="h5" color="primary">
                Forecast Summary
              </Typography>
              <ForecastSummary juiceGroup={juiceGroup} cloudId={cloudId} />
            </Grid>
            <Grid item>
              <Typography variant="h5" color="primary">
                Virtual Cloud Summary
              </Typography>
              <VirtualCloudsSummary
                virtualCloud={virtualClouds.find((vc) => vc.cloud.name === cloudId)}
              />
            </Grid>
            <Grid item>
              <Typography variant="h5" color="primary">
                Usage Summary
              </Typography>
              <UsageSummary juiceGroup={juiceGroup} cloudId={cloudId} />
            </Grid>
          </Grid>
        )}
      </Grid>
    </Modal>
  );
};

Summaries.propTypes = {
  juiceGroup: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Summaries;
