import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import useApiCall from '../../../../../hooks/useApiCall';
import rbacCheck from '../../../../../rbac/rbacCheck';
import Auth from '../../../../../api/auth';
import { FormModal } from '../../../../../components/Formik';
import Tags from '../../../../../components/Tags/Tags';
import Provision from '../../../../../api/provision';

const EditProductFormModal = ({ product, onSubmit, onCancel }) => {
  const userData = useSelector((state) => state.user);

  const [projects, setProjects] = useState(null);
  const [juiceTags, setJuiceTags] = useState(null);

  const updateProductCall = useApiCall(Auth.updateV2Product);
  const getProjectsCall = useApiCall(Auth.fetchV2Projects);
  const getTagsCall = useApiCall(Provision.fetchTags);

  const canEditTags = rbacCheck(userData.roles, '/products:PUT:TAGS');

  useEffect(() => {
    const getTags = async () => {
      const [data] = await getTagsCall();
      if (data) setJuiceTags(data);
    };

    const getProjects = async () => {
      const [data] = await getProjectsCall({ organizationId: product.organizationId });
      if (data) setProjects(data);
    };

    getTags();
    getProjects();
  }, []);

  const fields = [
    {
      fieldType: 'text',
      name: 'description',
      label: 'Description',
    },
    {
      fieldType: 'select',
      name: 'projectId',
      label: 'Project',
      menuItems: projects?.map((project) => ({
        value: project._id,
        label: project.name,
      })),
    },
    ...(canEditTags ? [
      {
        fieldType: Tags,
        name: 'tags',
        label: 'Juice Group Tags',
        tags: juiceTags,
      },
    ] : []),
  ];

  const initialValues = {
    description: product.description || '',
    projectId: product.projectId || '',
    tags: product.details?.tags || [],
  };

  const validationSchema = Yup.object().shape({
    description: Yup.string().required('Required'),
    projectId: Yup.string().required('Required'),
  });

  const handleSubmit = async (payload) => {
    onCancel();

    const [data] = await updateProductCall({
      ...payload,
      id: product?._id,
    });

    if (data) onSubmit();
  };

  return projects && (
    <FormModal
      title={`Edit product ${product.name}`}
      fields={fields}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      onCancel={onCancel}
      submitLabel="Save"
    />
  );
};

EditProductFormModal.propTypes = {
  product: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
      PropTypes.object,
    ]),
  ).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default EditProductFormModal;
