import PropTypes from 'prop-types';
import { Divider, Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { FormikNumberField } from '../../../../components/Formik';

const QuotaField = ({
  name, label, available, onWheel,
}) => (
  <FormikNumberField
    name={name}
    label={label}
    disabled={!available}
    onWheel={onWheel}
    placeholder={`Enter ${label} (${available} available)`}
  />
);

QuotaField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  available: PropTypes.number,
  onWheel: PropTypes.func,
};

QuotaField.defaultProps = {
  available: 0,
  onWheel: () => {},
};

const ResourceQuota = ({ unusedQuota, onWheel }) => {
  const intl = useIntl();
  return (
    <>
      <Grid item xs={12} md={6}>
        <Typography>
          <strong>Virtual CPUs and Memory: </strong>
          {intl.formatMessage({ id: 'virtualCPUsAndMemory' })}
          <br />
          Calculate the total number of needed resources
          by adding up the resources of the instances
          that you plan to deploy.
          The list of available instance types can be found&nbsp;
          <Link to="https://portal.pan-net.cloud/docs/Compute">here</Link>
        </Typography>
      </Grid>
      <Grid item xs={12} md={4}>
        <Grid container direction="column">
          <Grid item padding=".5rem 0">
            <QuotaField
              name="cpuCores"
              label="CPU Cores"
              available={unusedQuota.compute?.cores}
              onWheel={onWheel}
            />
          </Grid>
          <Grid item padding=".5rem 0">
            <QuotaField
              name="ram"
              label="RAM (GB)"
              available={unusedQuota.compute?.ram}
              onWheel={onWheel}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider sx={{ my: 2 }} />
      </Grid>

      <Grid item xs={12} md={6}>
        <Typography>
          <strong>Storage: </strong>
          Add block storage to your OpenStack project.
          Block storage can then be attached to the instances you create.
        </Typography>
      </Grid>
      <Grid item xs={12} md={4}>
        <QuotaField
          name="diskQuota"
          label="Disk Quota (GB)"
          available={unusedQuota.volume?.gigabytes}
          onWheel={onWheel}
        />
      </Grid>
      <Grid item xs={12}>
        <Divider sx={{ my: 2 }} />
      </Grid>

      <Grid item xs={12} md={6}>
        <Typography>
          <strong>Floating IPs: </strong>
          Using a floating IP, you can rapidly provide access
          from an external network to the instance with no public network interface.
        </Typography>
      </Grid>
      <Grid item xs={12} md={4}>
        <QuotaField
          name="floatingIps"
          label="Floating IPs"
          available={unusedQuota.network?.floatingIps}
          onWheel={onWheel}
        />
      </Grid>
    </>
  );
};

ResourceQuota.propTypes = {
  unusedQuota: PropTypes.shape({
    compute: PropTypes.shape({
      cores: PropTypes.number,
      ram: PropTypes.number,
    }),
    volume: PropTypes.shape({
      gigabytes: PropTypes.number,
    }),
    network: PropTypes.shape({
      floatingIps: PropTypes.number,
    }),
  }).isRequired,
  onWheel: PropTypes.func,
};

ResourceQuota.defaultProps = {
  onWheel: () => {},
};


export default ResourceQuota;
