import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Breadcrumbs,
  Container,
  Grid,
  Typography,
} from '@mui/material';
import { assignableRoles, userRolesLookup } from '../../../helpers/lookups';
import OverviewTable from '../../../components/OverviewTable/OverviewTable';
import rbacCheck from '../../../rbac/rbacCheck';
import EditUserFormModal from './EditUserFormModal';
import DeleteUserFormModal from './DeleteUserFormModal';
import useApiCall from '../../../hooks/useApiCall';
import Auth from '../../../api/auth';

const UserDetails = () => {
  const { uuid } = useParams();
  const user = useSelector((state) => state.user);

  const [userDetails, setUserDetails] = useState({});
  const [createdBy, setCreatedBy] = useState({});
  const [editModalOpened, setEditModalOpened] = useState(false);
  const [deleteModalOpened, setDeleteModalOpened] = useState(false);

  const getUserCall = useApiCall(Auth.fetchV2Users);
  const getUserDetailsCall = useApiCall(Auth.fetchV2UserByUuid);

  const userRoles = rbacCheck(user.roles, '/users:PUT_ROLE_CHECK');
  const canEditRole = rbacCheck(user.roles, '/users:PUT', {
    userToBeUpdatedRole: userDetails.role,
    userToBeUpdatedEmail: userDetails.email,
    userEmail: user.email,
  });
  const canSeeOrganizations = rbacCheck(user.roles, '/organizations:VIEW_ALL');
  const canEditOrg = rbacCheck(user.roles, '/users:PUT:ORG');
  const canDeleteUser = rbacCheck(user.roles, '/users:DELETE', {
    userToBeDeletedRole: userDetails.role,
    userToBeDeletedEmail: userDetails.email,
    userEmail: user.email,
  });

  const getUserDetails = async () => {
    const [data] = await getUserDetailsCall(uuid);
    if (data) setUserDetails(data);
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  useEffect(() => {
    const getCreatedBy = async () => {
      const [data] = await getUserCall({ email: userDetails.createdByEmail });
      if (data) setCreatedBy(data[0]);
    };

    if (userDetails) getCreatedBy();
  }, [userDetails]);

  const rows = [
    {
      title: 'Role',
      value: userRolesLookup[userDetails.role],
    },
    {
      title: 'Organization',
      component: (
        <Link to={`/organizations/${userDetails.organizationId}`}>
          {userDetails.organization?.enterpriseName}
        </Link>
      ),
    },
    {
      title: 'Activated',
      value: userDetails.activated ? 'True' : 'False',
    },
    {
      title: 'Created by',
      component: createdBy ? (
        <Link to={`/users/${createdBy.uuid}`}>
          {createdBy.email}
        </Link>
      ) : (
        userDetails.createdByEmail
      ),
    },
  ];

  return (
    <>
      {editModalOpened && (
        <EditUserFormModal
          roles={assignableRoles(userRoles)}
          user={userDetails}
          canEditOrg={canEditOrg}
          onSubmit={getUserDetails}
          onCancel={() => setEditModalOpened(false)}
        />
      )}
      {deleteModalOpened && (
        <DeleteUserFormModal
          user={userDetails}
          onSubmit={() => setDeleteModalOpened(false)}
          onCancel={() => setDeleteModalOpened(false)}
        />
      )}
      <Grid
        container
        className="content-with-header"
        direction="column"
        rowGap={4}
      >
        <Grid item alignSelf="flex-start">
          <Breadcrumbs key={userDetails.uuid} separator=">">
            {canSeeOrganizations
              ? <Link to="/organizations">organizations</Link>
              : <Link to={`/organizations/${userDetails.organizationId}`}>organizations</Link>}
            <Link to={`/organizations/${userDetails.organizationId}`}>{userDetails.organization?.enterpriseName}</Link>
            <Link to={`/organizations/${userDetails.organizationId}/users`}>users</Link>
            <Typography>{userDetails.email}</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <Container maxWidth="lg">
            <OverviewTable
              header={userDetails.email}
              rows={rows}
              editAction={{
                onClick: () => setEditModalOpened(true),
                disabled: !canEditRole,
                tooltip: 'userEditPermissionErr',
              }}
              deleteAction={{
                onClick: () => setDeleteModalOpened(true),
                disabled: !canDeleteUser,
                tooltip: 'userDeletePermissionErr',
              }}
            />
          </Container>
        </Grid>
      </Grid>
    </>
  );
};

export default UserDetails;
