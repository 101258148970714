import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Container } from '@mui/material';
import rbacCheck from '../../rbac/rbacCheck';
import useApiCall from '../../hooks/useApiCall';
import CreateOrganizationFormModal from './CreateOrganizationFormModal';
import Auth from '../../api/auth';
import Table from '../../components/Table';

const Organizations = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const userData = useSelector((state) => state.user);

  const [organizations, setOrganizations] = useState([]);
  const [createModalOpened, setCreateModalOpened] = useState(false);

  const getOrganizationsCall = useApiCall(Auth.fetchV1Organizations);

  const canCreateOrganization = rbacCheck(userData.roles, '/organization:POST');

  useEffect(() => {
    const getOrganizations = async () => {
      const [data] = await getOrganizationsCall();
      if (data) setOrganizations(data);
    };

    getOrganizations();
  }, []);

  const fields = [
    {
      title: 'Name',
      id: 'enterpriseName',
    },
    {
      title: 'CRMid',
      id: 'crmid',
    },
    {
      title: 'Account Owner',
      id: 'accountowner',
    },
  ];

  const rows = organizations.map((org) => ({
    key: org._id,
    onRowClick: () => navigate(org._id),
    data: [
      {
        field: 'enterpriseName',
        value: org.enterpriseName,
      },
      {
        field: 'crmid',
        value: org.CRMId,
      },
      {
        field: 'accountowner',
        value: org.accountOwner?.email,
      },
    ],
  }));

  return (
    <>
      {createModalOpened && (
        <CreateOrganizationFormModal
          onSubmit={(newOrganization) => setOrganizations((prev) => [...prev, newOrganization])}
          onCancel={() => setCreateModalOpened(false)}
        />
      )}
      <Container maxWidth="lg">
        <Table
          header="Organizations"
          fields={fields}
          rows={rows}
          createAction={{
            disabled: !canCreateOrganization,
            onClick: () => setCreateModalOpened(true),
            tooltip: !canCreateOrganization
              ? intl.formatMessage({ id: 'organizationCreationPermissionErr' })
              : '',
          }}
        />
      </Container>
    </>
  );
};

export default Organizations;
