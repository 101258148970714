import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
  Grid,
} from '@mui/material';
import { CloseIcon } from '../icons/IconLibrary';
import Button from '../Buttons/Button';

const Modal = ({
  open,
  hideActions,
  disabled,
  title,
  maxWidth,
  submitLabel,
  onClose,
  onSubmit,
  children,
}) => (
  <Dialog
    fullWidth
    open={open}
    maxWidth={maxWidth}
    onClose={onClose}
  >
    <DialogTitle>
      <Grid
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="h6">{title}</Typography>
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </Grid>
    </DialogTitle>
    <DialogContent sx={{ pb: hideActions ? 3 : 0 }}>
      {children}
    </DialogContent>
    {!hideActions && (
      <DialogActions>
        <Button
          disabled={disabled}
          onClick={onSubmit}
          label={submitLabel}
        />
      </DialogActions>
    )}
  </Dialog>
);

Modal.propTypes = {
  open: PropTypes.bool,
  hideActions: PropTypes.bool,
  disabled: PropTypes.bool,
  title: PropTypes.string.isRequired,
  submitLabel: PropTypes.string,
  maxWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  children: PropTypes.node,
};

Modal.defaultProps = {
  open: true,
  hideActions: false,
  disabled: false,
  submitLabel: 'Save',
  maxWidth: 'xs',
  onSubmit: null,
  children: null,
};

export default Modal;
