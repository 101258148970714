import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Grid,
  Typography,
} from '@mui/material';
import Button from '../Buttons/Button';
import Tooltip from '../Tooltip/Tooltip';

const OverviewTable = ({
  header,
  rows,
  editAction,
  deleteAction,
  extraActions,
}) => {
  const intl = useIntl();

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
    >
      <Grid
        container
        justifyContent="space-between"
        pb={1}
      >
        <Grid item>
          <Typography variant="h4" color="primary">
            {header}
          </Typography>
        </Grid>
        <Grid item>
          <Grid
            container
            gap={1}
          >
            {extraActions.map((action) => (
              <Grid item key={action.label}>
                <Tooltip title={(
                  action.disabled && action.tooltip)
                  ? intl.formatMessage({ id: action.tooltip })
                  : ''}
                >
                  <Button
                    disabled={action.disabled}
                    onClick={action.onClick}
                    label={action.label}
                  />
                </Tooltip>
              </Grid>
            ))}
            {editAction && (
              <Grid item>
                <Tooltip title={(
                  editAction.disabled && editAction.tooltip)
                  ? intl.formatMessage({ id: editAction.tooltip })
                  : ''}
                >
                  <Button
                    disabled={editAction.disabled}
                    onClick={editAction.onClick}
                    label="Edit"
                  />
                </Tooltip>
              </Grid>
            )}
            {deleteAction && (
              <Grid item>
                <Tooltip title={(
                  deleteAction.disabled && deleteAction.tooltip)
                  ? intl.formatMessage({ id: deleteAction.tooltip })
                  : ''}
                >
                  <Button
                    variant="outlined"
                    disabled={deleteAction.disabled}
                    onClick={deleteAction.onClick}
                    label="Delete"
                  />
                </Tooltip>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <TableContainer>
          <Table>
            <TableBody>
              {rows?.map((row) => (
                <TableRow key={row.title}>
                  <TableCell><b>{row.title}</b></TableCell>
                  <TableCell align="right">
                    {row.component || <Typography>{row.value || 'N/A'}</Typography>}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

OverviewTable.propTypes = {
  header: PropTypes.string,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      path: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
        PropTypes.node,
      ]),
    }),
  ),
  editAction: PropTypes.shape({
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    tooltip: PropTypes.string,
    label: PropTypes.string,
  }),
  deleteAction: PropTypes.shape({
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    tooltip: PropTypes.string,
    label: PropTypes.string,
  }),
  extraActions: PropTypes.arrayOf(
    PropTypes.shape({
      onClick: PropTypes.func,
      disabled: PropTypes.bool,
      label: PropTypes.string,
    }),
  ),
};

OverviewTable.defaultProps = {
  header: '',
  rows: [{}],
  editAction: undefined,
  deleteAction: undefined,
  extraActions: [],
};

export default OverviewTable;
