import { useKeycloak } from '@react-keycloak/web';
import {
  ScaleTelekomNavList,
  ScaleTelekomNavItem,
  ScaleTelekomNavFlyout,
  ScaleTelekomMegaMenu,
  ScaleTelekomMegaMenuColumn,
} from '@telekom/scale-components-react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import rbacCheck from '../../rbac/rbacCheck';

const MainNavigation = () => {
  const { keycloak } = useKeycloak();
  const { pathname } = useLocation();
  const user = useSelector((state) => state.user);
  const { organization, roles } = user;

  const isActive = (path) => pathname.startsWith(path) || undefined;

  const permissions = {
    canSeeProducts: rbacCheck(roles, '/products', organization) || !keycloak.authenticated,
    canSeeOrganizations: rbacCheck(roles, '/organizations:VIEW_ALL'),
    canSeeOwnOrganization: rbacCheck(roles, '/organizations:VIEW_OWN_DETAILS'),
    canOrderCompute: rbacCheck(roles, '/products/compute/order', organization),
    canUseSafescarfInstance: rbacCheck(roles, '/products/safescarf', organization),
    canSeeReports: rbacCheck(roles, '/reports'),
    canSeeMonitoringDashboards: rbacCheck(roles, '/monitoring', organization),
  };

  const renderNavItem = (condition, label, to, children) => {
    if (condition) {
      return (
        <ScaleTelekomNavItem aria-label={label} active={isActive(to)}>
          {children || <Link to={to}>{label}</Link>}
        </ScaleTelekomNavItem>
      );
    }
    return null;
  };

  return (
    <ScaleTelekomNavList
      slot="main-nav"
      variant="main-nav"
      aria-label="Main Navigation Links"
    >
      {renderNavItem(true, 'Documentation', `/docs/${import.meta.env.VITE_CONFLUENCE_HOMEPAGE}`)}

      {permissions.canSeeProducts && (
        <ScaleTelekomNavItem aria-label="Products" variant="main-nav" active={isActive('/products')}>
          <button type="button" aria-current aria-haspopup="true" aria-label="Products">
            Products
          </button>
          <ScaleTelekomNavFlyout hover className="headerFlyOutMenu">
            <ScaleTelekomMegaMenu>
              <ScaleTelekomMegaMenuColumn>
                <span slot="heading">Compute</span>
                <ul>
                  {keycloak.authenticated && <li><Link to="/products/compute">My products</Link></li>}
                  <li><Link to="/products/compute/specification">Product Specification</Link></li>
                  {permissions.canOrderCompute && <li><Link to="/products/compute/order">Order</Link></li>}
                  {keycloak.authenticated && <li><Link to="/products/compute/free-trial">Free Trial</Link></li>}
                </ul>
              </ScaleTelekomMegaMenuColumn>

              <ScaleTelekomMegaMenuColumn>
                <span slot="heading">SafeSCARF</span>
                <ul>
                  {keycloak.authenticated && permissions.canUseSafescarfInstance && (
                    <li><Link to="/products/safescarf">My instance</Link></li>
                  )}
                  <li><Link to="/products/safescarf/specification">Product Specification</Link></li>
                </ul>
              </ScaleTelekomMegaMenuColumn>

              <ScaleTelekomMegaMenuColumn>
                <span slot="heading">CaaS</span>
                <ul>
                  {keycloak.authenticated && <li><Link to="/products/caas/clusters">My Clusters</Link></li>}
                  {keycloak.authenticated && <li><Link to="/products/caas-on-openstack">My products</Link></li>}
                  <li><Link to="/products/caas-on-openstack/specification">Product Specification</Link></li>
                </ul>
              </ScaleTelekomMegaMenuColumn>

              <ScaleTelekomMegaMenuColumn>
                <span slot="heading">Monitoring</span>
                <ul>
                  {keycloak.authenticated && permissions.canSeeMonitoringDashboards && (
                    <li><Link to="/monitoring-dashboards">My dashboards</Link></li>
                  )}
                </ul>
              </ScaleTelekomMegaMenuColumn>
            </ScaleTelekomMegaMenu>
          </ScaleTelekomNavFlyout>
        </ScaleTelekomNavItem>
      )}

      {renderNavItem(permissions.canSeeOrganizations, 'Organizations', '/organizations')}
      {renderNavItem(permissions.canSeeOwnOrganization, 'Organization', `/organizations/${organization?._id}`)}
      {renderNavItem(permissions.canSeeReports, 'Reports', '/reports/tenants')}
    </ScaleTelekomNavList>
  );
};

export default MainNavigation;
