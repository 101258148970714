import PropTypes from 'prop-types';
import {
  Grid, Typography,
} from '@mui/material';
import { FormikChip } from '../Formik';

const Tags = ({
  name,
  label,
  size,
  tags,
  onDelete,
}) => (
  <Grid
    container
    direction="column"
    gap={1}
    py={1}
  >
    {label && (
      <Typography component="span">
        {label}
        {label.endsWith(':') ? ' ' : ': '}
        {tags?.length ? null : <Typography component="span" color="text.secondary">No tags available</Typography>}
      </Typography>
    )}
    <Grid container gap={1}>
      {tags?.map((tag) => (
        <Grid item key={tag}>
          <FormikChip
            name={name}
            label={tag}
            value={tag}
            size={size}
            {...(onDelete && { onDelete: () => onDelete(tag) })}
          />
        </Grid>
      ))}
    </Grid>
  </Grid>
);

Tags.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium']),
  tags: PropTypes.arrayOf(PropTypes.string),
  onDelete: PropTypes.func,
};

Tags.defaultProps = {
  name: 'tags',
  label: 'Tags',
  size: 'small',
  tags: null,
  onDelete: null,
};

export default Tags;
