import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { FormikSelect } from '../../../../components/Formik';

const ProductSelect = ({ filteredProducts }) => {
  const { values: { projectId, osProjectName }, setValues } = useFormikContext();

  const products = projectId
    ? filteredProducts.filter((product) => product.projectId === projectId)
    : [];

  return (
    <FormikSelect
      name="productId"
      label="Product"
      disabled={!projectId}
      menuItems={products.map((product) => ({
        value: product._id,
        label: product.name,
      }))}
      onChange={(value) => {
        const selectedProduct = products.find((p) => p._id === value);
        setValues({
          osProjectName,
          projectId,
          productId: value,
          juiceGroup: selectedProduct ? selectedProduct.details.juiceGroup : '',
          cloudName: '',
          cpuCores: '',
          ram: '',
          diskQuota: '',
          floatingIps: '',
          internetConnectivity: false,
          DTHConnectivity: false,
          DTHRedRouterConnectivity: false,
          unusedQuota: {},
          externalNetworks: [],
        });
      }}
    />
  );
};

ProductSelect.propTypes = {
  filteredProducts: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      projectId: PropTypes.string.isRequired,
      details: PropTypes.shape({
        juiceGroup: PropTypes.string,
      }).isRequired,
    }),
  ).isRequired,
};

export default ProductSelect;
