import { useContext } from 'react';
import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Provision from '../../api/provision';
import { ReportsContext } from './ReportsContext';
import useApiCall from '../../hooks/useApiCall';
import { Select } from '../../components/InputFields';
import Table from '../../components/Table';

const HYPERVISOR_FIELDS = [
  { id: 'hostName', title: 'Host Name' },
  { id: 'availabilityZone', title: 'Availability Zone' },
  { id: 'computeRole', title: 'Compute Role' },
  { id: 'cpuRatio', title: 'CPU Ratio' },
  { id: 'ramRatio', title: 'RAM Ratio' },
  { id: 'diskRatio', title: 'Disk Ratio' },
];

const HYPERVISOR_DETAILS_FIELDS = [
  { id: 'vmName', title: 'Virtual Machine Name' },
  { id: 'status', title: 'Status' },
  { id: 'cpu', title: 'CPU' },
  { id: 'ram', title: 'RAM (GB)' },
  { id: 'disk', title: 'Disk (GB)' },
  { id: 'flavorName', title: 'Flavor Name' },
  { id: 'osProjectName', title: 'OS Project Name' },
  { id: 'osDomainName', title: 'OS Domain Name [Juice Group]' },
  { id: 'createdAt', title: 'Created At' },
  { id: 'deletedAt', title: 'Deleted At' },
];

const HypervisorReport = () => {
  const navigate = useNavigate();
  const fetchInstanceDetails = useApiCall(Provision.fetchInstanceDetails);
  const fetchHostsCall = useApiCall(Provision.fetchHosts);

  const {
    hypervisor: {
      cloudId,
      setCloudId,
      hypervisors,
      setHypervisors,
      hypervisorDetails,
      setHypervisorDetails,
      clouds,
    },
  } = useContext(ReportsContext);

  const formatDate = (date) => (date ? new Date(date).toISOString().slice(0, 16) : 'N/A');

  async function getHypervisorDetails(hostName, selectedCloudId) {
    const [data] = await fetchInstanceDetails({ host_name: hostName, cloudIds: [selectedCloudId] });
    if (data?.length > 0) {
      const { instances, metadata } = data[0];
      const cloudData = instances.map((instance) => {
        const domain = metadata.domains[instance.domain_id];
        const project = metadata.projects[instance.project_id];

        return {
          key: instance.name,
          onRowClick: () => navigate(`${cloudId}/${domain.name}/${instance.name}`),
          data: [
            { field: 'vmName', value: instance.name },
            { field: 'status', value: instance.status },
            { field: 'cpu', value: instance.cpus_total },
            { field: 'ram', value: instance.ram_gigabytes },
            { field: 'disk', value: instance.disk_gigabytes },
            { field: 'flavorName', value: instance.flavor_name },
            { field: 'osProjectName', value: project.name },
            { field: 'osDomainName', value: domain.name },
            { field: 'createdAt', value: formatDate(instance.created_at) },
            { field: 'deletedAt', value: formatDate(instance.deleted_at) },
          ],
        };
      });
      setHypervisorDetails(cloudData);
    }
  }

  async function fetchHosts(selectedCloudId) {
    const [data] = await fetchHostsCall(selectedCloudId);
    if (data) {
      const tableRows = data.map((d) => ({
        key: d.host,
        onRowClick: () => getHypervisorDetails(d.host, selectedCloudId),
        data: [
          { field: 'hostName', value: d.host },
          { field: 'availabilityZone', value: d.availability_zone },
          { field: 'computeRole', value: d.compute_type },
          { field: 'cpuRatio', value: d.cpu_overcommit_ratio_used },
          { field: 'ramRatio', value: d.ram_mb },
          { field: 'diskRatio', value: d.local_disk_gb },
        ],
      }));
      setHypervisors(tableRows);
    }
  }

  return (
    <Grid
      container
      direction="column"
      gap={2}
    >
      <Grid item>
        <Select
          name="cloudDropdown"
          label="Select a cloud to see the available hypervisors"
          value={cloudId}
          onChange={(value) => {
            setCloudId(value);
            setHypervisorDetails([]);
            fetchHosts(value);
          }}
          menuItems={clouds.map((item) => ({
            value: item.id,
            label: item.name,
          }))}
        />
      </Grid>
      <Grid item>
        <Table
          header="Hypervisors"
          fields={HYPERVISOR_FIELDS}
          rows={hypervisors}
        />
      </Grid>
      <Grid item>
        <Table
          header="Hypervisor Details"
          fields={HYPERVISOR_DETAILS_FIELDS}
          rows={hypervisorDetails}
        />
      </Grid>
    </Grid>
  );
};

export default HypervisorReport;
