import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import rbacCheck from '../../../../../rbac/rbacCheck';
import CreateProductTypeFormModal from './CreateProductTypeFormModal';
import { fetchProductTypes } from '../../../../../redux/safescarf/productTypes/actions';
import Table from '../../../../../components/Table';

const ProductTypes = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = useSelector((state) => state.user);
  const productTypes = useSelector((state) => state.safescarf.productTypes);

  const [createModalOpened, setCreateModalOpened] = useState(false);

  const canCreateProductType = rbacCheck(userData.roles, '/products/safescarf/product-types:POST');

  useEffect(() => {
    if (!productTypes.length) dispatch(fetchProductTypes());
  }, [productTypes.length]);


  const fields = [
    {
      id: 'name',
      title: 'Name',
    },
    {
      id: 'description',
      title: 'Description',
    },
  ];

  const rows = productTypes.map((productType) => ({
    key: productType.id.toString(),
    onRowClick: () => navigate(productType.id.toString()),
    data: [
      {
        field: 'name',
        value: productType.name,
      },
      {
        field: 'description',
        value: productType.description,
      },
    ],
  }));

  return (
    <>
      {createModalOpened && (
        <CreateProductTypeFormModal
          onCancel={() => setCreateModalOpened(false)}
          onSubmit={() => setCreateModalOpened(false)}
        />
      )}
      <Table
        header="Product Types"
        fields={fields}
        rows={rows}
        createAction={{
          disabled: !canCreateProductType,
          onClick: () => setCreateModalOpened(true),
          tooltip: !canCreateProductType ? intl.formatMessage({ id: 'createProductTypeDisabled' }) : '',
        }}
      />
    </>
  );
};


export default ProductTypes;
