import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createMachineAccount, fetchMachineAccounts } from '../../../../../redux/safescarf/machineAccounts/actions';
import { updateUser } from '../../../../../redux/safescarf/users/actions';
import rbacCheck from '../../../../../rbac/rbacCheck';
import MachineAccountsApiKeyModal from './MachineAccountsApiKeyModal';
import Table from '../../../../../components/Table';

const MachineAccounts = () => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user);
  const machineAccounts = useSelector((state) => state.safescarf.machineAccounts);

  useEffect(() => {
    if (!machineAccounts.length) dispatch(fetchMachineAccounts());
  }, []);

  const machineAccountToken = machineAccounts.length > 0
    ? machineAccounts[machineAccounts.length - 1]?.token : null;
  const machineAccountName = machineAccounts.length > 0
    ? machineAccounts[machineAccounts.length - 1]?.username : '';

  const fields = [
    { id: 'username', title: 'Username' },
    { id: 'createdBy', title: 'Created by' },
    { id: 'status', title: 'Status', type: 'options' },
  ];

  const rows = machineAccounts
    .filter((ma) => {
      const canViewMachineAccount = rbacCheck(
        userData.roles,
        '/products/safescarf/machine-accounts:VIEW',
        { createdBy: ma.email, userEmail: userData.email },
      );
      return canViewMachineAccount;
    })
    .map((ma) => {
      const canUpdateMachineAccount = rbacCheck(
        userData.roles,
        '/products/safescarf/users:PUT',
        { userEmail: userData.email, rowEmail: ma.email },
      );
      return {
        key: ma.username,
        data: [
          { field: 'username', value: ma.username },
          { field: 'createdBy', value: ma.email },
          {
            field: 'status',
            type: 'switch',
            value: ma.is_active ? 'Active' : 'Inactive',
            props: {
              disabled: !canUpdateMachineAccount,
              checked: ma.is_active,
              label: ma.is_active ? 'Active' : 'Inactive',
              name: ma.username,
              onChange: () => {
                dispatch(updateUser({
                  id: ma.id,
                  username: ma.username,
                  is_active: !ma.is_active,
                }));
              },
            },
          },
        ],
      };
    });


  return (
    <>
      {machineAccountToken && (
        <MachineAccountsApiKeyModal
          machineAccountName={machineAccountName}
          machineAccountToken={machineAccountToken}
        />
      )}
      <Table
        header="Machine Accounts"
        fields={fields}
        rows={rows}
        createAction={{
          onClick: () => dispatch(createMachineAccount()),
        }}
      />
    </>
  );
};


export default MachineAccounts;
