import { useField, useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { Switch } from '../../InputFields';

export const FormikSwitch = ({
  name,
  label,
  disabled,
  onChange: propOnChange,
}) => {
  const [field, meta, helpers] = useField(name);
  const { values } = useFormikContext();
  const isDisabled = typeof disabled === 'string' ? !values[disabled] : disabled;

  const handleChange = (event) => {
    helpers.setValue(event.target.checked);
    if (propOnChange) {
      propOnChange(event);
    }
  };

  return (
    <Switch
      id={field.name}
      name={field.name}
      checked={field.value}
      onChange={handleChange}
      onBlur={field.onBlur}
      label={label}
      disabled={isDisabled}
      helperText={meta.touched ? meta.error : ''}
    />
  );
};

FormikSwitch.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.shape({
      on: PropTypes.string.isRequired,
      off: PropTypes.string.isRequired,
    }),
  ]).isRequired,
  disabled: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]),
  onChange: PropTypes.func,
};

FormikSwitch.defaultProps = {
  disabled: false,
  onChange: null,
};
