import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { FormikSelect } from '../../../../components/Formik';
import useCloudQuota from '../../../../hooks/useCloudQuota';

const CloudSelect = () => {
  const { values, setValues } = useFormikContext();
  const { availableVirtualClouds, getExternalNetworks } = useCloudQuota(values.juiceGroup);

  useEffect(() => {
    async function updateNetworks() {
      if (values.cloudName) {
        const networks = await getExternalNetworks(values.cloudName);
        setValues({
          ...values,
          externalNetworks: networks,
          unusedQuota: availableVirtualClouds.find(
            (cloud) => cloud.cloud.name === values.cloudName,
          )?.unusedQuota || {},
        });
      }
    }
    updateNetworks();
  }, [values.cloudName]);

  return (
    <FormikSelect
      name="cloudName"
      label="Cloud"
      disabled={!values.productId}
      menuItems={availableVirtualClouds.map((virtualCloud) => ({
        value: virtualCloud.cloud.name,
        label: virtualCloud.cloud.name,
      }))}
    />
  );
};

export default CloudSelect;
