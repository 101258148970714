import PropTypes from 'prop-types';
import { Chip as ChipMUI } from '@mui/material';

const Chip = ({
  label,
  color,
  size,
  variant,
  disabled,
  onClick,
  onDelete,
  sx,
}) => (
  <ChipMUI
    clickable={!!onClick}
    label={label}
    size={size}
    color={color}
    variant={variant}
    disabled={disabled}
    onClick={onClick}
    onDelete={onDelete}
    sx={sx}
  />
);

Chip.propTypes = {
  label: PropTypes.string.isRequired,
  color: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium']),
  variant: PropTypes.oneOf(['filled', 'outlined']),
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  onDelete: PropTypes.func,
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.object,
        PropTypes.bool,
      ]),
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};

Chip.defaultProps = {
  color: 'default',
  size: 'small',
  variant: 'filled',
  disabled: false,
  onClick: null,
  onDelete: undefined,
  sx: {},
};

export default Chip;
