import { useParams } from 'react-router-dom';
import { Typography } from '@mui/material';
import JuiceGroupContacts from './JuiceGroupContacts';
import JuiceGroupInfo from './JuiceGroupInfo';


const HypervisorDetails = () => {
  const { virtualMachine } = useParams();

  return (
    <>
      <Typography variant="h4" color="primary" gutterBottom>{virtualMachine}</Typography>
      <JuiceGroupInfo />
      <JuiceGroupContacts />
    </>
  );
};

export default HypervisorDetails;
