import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import useApiCall from '../../../hooks/useApiCall';
import Auth from '../../../api/auth';
import DeleteFormModal from '../../../components/DeleteFormModal/DeleteFormModal';
import ProductUnassignment from './ProductUnassignment';

const DeleteUserFormModal = ({
  onSubmit, onCancel, user,
}) => {
  const navigate = useNavigate();

  const [userResources, setUserResources] = useState({});

  const getUserResourcesCall = useApiCall(Auth.fetchV1UserResources);
  const deleteUserCall = useApiCall(Auth.deleteV2User);

  useEffect(() => {
    const getUserResources = async () => {
      if (!userResources.products?.length) {
        const [data] = await getUserResourcesCall(user.uuid);
        if (data) setUserResources(data);
      }
    };
    getUserResources();
  }, []);

  const deleteUser = async () => {
    onSubmit();
    const [data] = await deleteUserCall(user.uuid);

    if (data) {
      navigate(-1);
    }
  };

  return (
    <DeleteFormModal
      fieldName="User"
      fieldValue={user.email}
      onSubmit={deleteUser}
      onCancel={onCancel}
    >
      {userResources.products?.length > 0 && (
        <ProductUnassignment resources={userResources} />
      )}
    </DeleteFormModal>
  );
};

DeleteUserFormModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  user: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  }).isRequired,
};

export default DeleteUserFormModal;
