import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { Grid, Typography } from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { FormikSelect } from '../../../../components/Formik/index';
import MonitoringMs from '../../../../api/monitoring';
import useApiCall from '../../../../hooks/useApiCall';

const Filters = ({ templates, setFilterParams }) => {
  const { dashboardId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const [menuItems, setMenuItems] = useState({});

  const getPanelDataApiCall = useApiCall(MonitoringMs.getPanel);

  const getTemplateData = async (targets) => {
    const [data] = await getPanelDataApiCall(targets, dashboardId);
    if (data) {
      const values = data.results[targets.queries[0].refId].frames[0].data.values[0];
      setMenuItems((prev) => (
        {
          ...prev,
          [targets.queries[0].refId]: values.map((item) => ({ value: item, label: item })),
        }));
    }
  };

  const initialValues = templates.reduce(
    (acc, template) => {
      const searchParams = new URLSearchParams(location.search);
      const value = searchParams.get(template.name) || template.current.value;
      return { ...acc, [template.name]: value };
    },
    {},
  );

  useEffect(() => {
    if (templates) {
      templates.forEach((template) => {
        getTemplateData({
          queries: [{
            datasource: template.datasource,
            rawSql: template.query,
            refId: template.name,
            format: 'table',
          }],
        });
      });
    }
  }, [templates]);

  const handleSelectChange = (name, value) => {
    const newSearchParams = new URLSearchParams(location.search);
    newSearchParams.set(name, value);
    const newUrl = `${location.pathname}?${newSearchParams.toString()}`;
    navigate(newUrl, { replace: true });
    setFilterParams({ [name]: value });
  };

  return (
    <Formik initialValues={initialValues}>
      {({ setFieldValue }) => (
        <Form style={{ margin: '1rem' }}>
          <Grid container spacing={1}>
            <Grid item xs={0.5} display="flex" alignItems="center">
              <Typography>Filters: </Typography>
            </Grid>
            {templates.map((template) => (
              <Grid item xs={1} key={template.name}>
                <FormikSelect
                  name={template.name}
                  label={template.label}
                  menuItems={menuItems[template.name]}
                  onChange={(value) => {
                    setFieldValue(template.name, value);
                    handleSelectChange(template.name, value);
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

Filters.propTypes = {
  templates: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  setFilterParams: PropTypes.func.isRequired,
};

export default Filters;

