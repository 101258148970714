import { useField, useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { MultiSelect } from '../../InputFields';

export const FormikMultiSelect = ({
  name,
  label,
  menuItems,
  disabled,
}) => {
  const [field, meta, helpers] = useField(name);
  const { values } = useFormikContext();
  const isDisabled = typeof disabled === 'string' ? !values[disabled] : disabled;

  const handleChange = (newValue) => {
    const newValues = Array.isArray(newValue) && !newValue.includes('') ? newValue : [];
    helpers.setValue(newValues);
  };

  return (
    <MultiSelect
      id={field.name}
      name={field.name}
      value={Array.isArray(values[field.name]) ? values[field.name] : []}
      onChange={handleChange}
      onBlur={field.onBlur}
      label={label}
      menuItems={menuItems}
      disabled={isDisabled}
      helperText={meta.touched ? meta.error : ''}
    />
  );
};

FormikMultiSelect.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]).isRequired,
      label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]).isRequired,
    }),
  ),
  disabled: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]),
};

FormikMultiSelect.defaultProps = {
  disabled: false,
  menuItems: [],
};
