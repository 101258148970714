import {
  useEffect,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useOutletContext } from 'react-router-dom';
import * as Yup from 'yup';
import validator from 'validator';
import Provision from '../../../api/provision';
import Auth from '../../../api/auth';
import useApiCall from '../../../hooks/useApiCall';
import { FormikForm } from '../../../components/Formik';

const CloudField = () => {
  const userData = useSelector((state) => state.user);

  const [virtualClouds, setVirtualClouds] = useOutletContext();
  const [products, setProducts] = useState([]);

  const fetchProducts = useApiCall(Auth.fetchV2Products);
  const getVirtualCloudsCall = useApiCall(Provision.fetchVirtualClouds);

  useEffect(() => {
    const getProducts = async () => {
      const [data] = await fetchProducts({ _id: userData.productIds });

      if (data) {
        setProducts(data.filter((product) => userData.productIds.includes(product._id)));
      }
    };

    if (virtualClouds === undefined) {
      getProducts();
    }
  }, []);

  useEffect(() => {
    const getVirtualClouds = async (juiceGroups) => {
      const [data] = await getVirtualCloudsCall({ juiceGroups });
      if (data?.length > 0) {
        setVirtualClouds(
          data.map((virtualCloud) => ({
            id: virtualCloud.cloud.name,
            name: virtualCloud.cloud.name,
          })),
        );
      }
    };

    if (products.length) {
      const juiceGroups = products.map((product) => product.details.juiceGroup);
      getVirtualClouds(juiceGroups);
    }
  }, [products]);

  return (
    {
      fieldType: 'select',
      name: 'cloudId',
      label: 'Cloud',
      menuItems: virtualClouds?.map((item) => ({
        value: item.id,
        label: item.name,
      })),
    }
  );
};

const CloudPasswordReset = () => {
  const intl = useIntl();
  const formRef = useRef(null);
  const updateCloudPassword = useApiCall(Provision.updateCloudPassword, 'cloudPasswordUpdate');

  const fields = [
    CloudField(),
    {
      fieldType: 'password',
      name: 'newPasswordCloud',
      label: 'New password',
    },
    {
      fieldType: 'password',
      name: 'confirmPasswordCloud',
      label: 'Confirm new password',
    },
  ];

  const initialValues = {
    cloudId: '',
    newPasswordCloud: '',
    confirmPasswordCloud: '',
  };

  const validationSchema = Yup.object().shape({
    cloudId: Yup.string().required('Required'),
    newPasswordCloud: Yup.string()
      .test(
        'Strong password',
        intl.formatMessage({ id: 'ERR_PASSWORD_INVALID' }),
        (value) => (value ? validator.isStrongPassword(value, { minLength: 10 }) : true),
      ).required('Required'),
    confirmPasswordCloud: Yup.string()
      .oneOf([Yup.ref('newPasswordCloud'), null], intl.formatMessage({ id: 'userPasswordMismatch' }))
      .required('Required'),
  });

  const handleSubmit = async (formData) => {
    const { cloudId, newPasswordCloud } = formData;

    const [data] = await updateCloudPassword(cloudId, newPasswordCloud);
    if (data) formRef.current.resetForm();
  };

  return (
    <FormikForm
      formRef={formRef}
      fields={fields}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      submitLabel="Change Password"
    />
  );
};

export default CloudPasswordReset;
