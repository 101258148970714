import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import CreateBusinessUnitFormModal from './CreateBusinessUnitFormModal';
import rbacCheck from '../../../rbac/rbacCheck';
import useApiCall from '../../../hooks/useApiCall';
import Auth from '../../../api/auth';
import Table from '../../../components/Table';

const BusinessUnits = () => {
  const { organizationId } = useParams();
  const intl = useIntl();
  const navigate = useNavigate();
  const userData = useSelector((state) => state.user);

  const [businessUnits, setBusinessUnits] = useState([]);
  const [createModalOpened, setCreateModalOpened] = useState(false);

  const getBusinessUnitsCall = useApiCall(Auth.fetchV1BusinessUnits);

  const canCreateBusinessUnit = rbacCheck(userData.roles, '/business-units:POST');

  useEffect(() => {
    const getBusinessUnits = async () => {
      const [data] = await getBusinessUnitsCall({ organizationId });
      if (data) setBusinessUnits(data);
    };

    getBusinessUnits();
  }, []);

  const fields = [
    {
      id: 'name',
      title: 'Name',
    },
    {
      id: 'description',
      title: 'Description',
    },
    {
      id: 'businessUnitManager',
      title: 'Business Unit Manager',
    },
  ];

  const rows = businessUnits.map((bu) => ({
    key: bu._id,
    onRowClick: () => navigate(`/business-units/${bu._id}`),
    data: [
      {
        field: 'name',
        value: bu.name,
      },
      {
        field: 'description',
        value: bu.description,
      },
      {
        field: 'businessUnitManager',
        value: bu.businessUnitManager?.email,
      },
    ],
  }));

  return (
    <>
      {createModalOpened && (
      <CreateBusinessUnitFormModal
        onSubmit={(newBusinessUnit) => setBusinessUnits((prev) => [...prev, newBusinessUnit])}
        onCancel={() => setCreateModalOpened(false)}
      />
      )}
      <Table
        header="Business Units"
        fields={fields}
        rows={rows}
        createAction={{
          disabled: !canCreateBusinessUnit,
          onClick: () => setCreateModalOpened(true),
          tooltip: !canCreateBusinessUnit ? intl.formatMessage({ id: 'businessUnitCreatePermissionErr' }) : '',
        }}
      />
    </>
  );
};

export default BusinessUnits;
