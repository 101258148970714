import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import CreateProductFormModal from './CreateProductFormModal';
import rbacCheck from '../../../../../rbac/rbacCheck';
import { fetchProducts } from '../../../../../redux/safescarf/products/actions';
import { fetchProductTypes } from '../../../../../redux/safescarf/productTypes/actions';
import Table from '../../../../../components/Table';

const Products = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userData = useSelector((state) => state.user);
  const products = useSelector((state) => state.safescarf.products);
  const productTypes = useSelector((state) => state.safescarf.productTypes);

  const [createModalOpened, setCreateModalOpened] = useState(false);

  const canCreateProduct = rbacCheck(userData.roles, '/products/safescarf/products:POST');

  useEffect(() => {
    if (!products.length) dispatch(fetchProducts());
    if (!productTypes.length) dispatch(fetchProductTypes());
  }, [products.length, productTypes.length]);

  const productTypeLookup = Object.fromEntries(
    productTypes.map((productType) => [productType.id, productType.name]),
  );

  const fields = [
    { title: 'Name', id: 'name' },
    { title: 'Description', id: 'description' },
    { title: 'Product type', id: 'prod_type' },
  ];

  const rows = products.map((product) => ({
    key: product.id.toString(),
    onRowClick: () => navigate(product.id.toString()),
    data: [
      { field: 'name', value: product.name },
      { field: 'description', value: product.description },
      { field: 'prod_type', value: productTypeLookup[product.prod_type] },
    ],
  }));

  return (
    <>
      {createModalOpened && (
        <CreateProductFormModal
          onSubmit={() => setCreateModalOpened(false)}
          onCancel={() => setCreateModalOpened(false)}
        />
      )}
      <Table
        header="Products"
        fields={fields}
        rows={rows}
        createAction={{
          disabled: !canCreateProduct,
          onClick: () => setCreateModalOpened(true),
          tooltip: !canCreateProduct ? intl.formatMessage({ id: 'createProductDisabled' }) : '',
        }}
      />
    </>
  );
};

export default Products;
