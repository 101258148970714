import { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import useApiCall from '../../hooks/useApiCall';
import DocumentationMS from '../../api/documentation';
import PageContent from './PageContent';
import SidebarNavigation from '../../components/SidebarNavigation/SidebarNavigation';

const Documentation = () => {
  const { pageTitle = import.meta.env.VITE_CONFLUENCE_HOMEPAGE } = useParams();
  const currentPath = `/docs/${pageTitle}`;

  const [pages, setPages] = useState([]);
  const getChildrenPages = useApiCall(DocumentationMS.getChildrenPages);

  useEffect(() => {
    const getAllPages = async () => {
      const [data] = await getChildrenPages(import.meta.env.VITE_CONFLUENCE_HOMEPAGE_ID);

      if (data) {
        setPages(data);
      }
    };

    getAllPages();
  }, []);

  const formatPagesForNavigation = (pagesData) => {
    if (!pagesData?.length) return [];

    return pagesData.map((page) => ({
      title: page.title,
      path: `/docs/${page.title}`,
      children: page.children?.length ? formatPagesForNavigation(page.children) : undefined,
    }));
  };

  const navigationItems = [
    {
      title: import.meta.env.VITE_CONFLUENCE_HOMEPAGE,
      path: `/docs/${import.meta.env.VITE_CONFLUENCE_HOMEPAGE}`,
    },
    ...formatPagesForNavigation(pages),
  ];

  return (
    <Grid container>
      <Grid item xs={2}>
        <SidebarNavigation menuItems={navigationItems} currentPath={currentPath} />
      </Grid>
      <Grid item xs={10}>
        <PageContent pageTitle={pageTitle} />
      </Grid>
    </Grid>
  );
};

export default Documentation;
