import { useEffect, useMemo } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import { fetchMachineAccounts } from '../../../../../redux/safescarf/machineAccounts/actions';
import { fetchProductTypeMembers } from '../../../../../redux/safescarf/productTypeMembers/actions';
import { fetchRoles } from '../../../../../redux/safescarf/roles/actions';

import { getHigherRole } from '../services/roles';
import Table from '../../../../../components/Table';


function getMember(user, productTypeMembers) {
  const productTypeMember = productTypeMembers.find((ptm) => ptm.user === user.id);

  return {
    userId: user.id,
    username: user.username,
    globalRole: user.globalRole,
    productTypeMemberId: productTypeMember?.id,
    productTypeMemberRole: productTypeMember?.role,
  };
}

function getMembers(safescarfUsers, machineAccounts, productTypeMembers) {
  const activeSafescarfUsers = safescarfUsers.filter((user) => user.is_active);
  const activeMachineAccounts = machineAccounts.filter((ma) => ma.is_active);
  const decoratedSafescarfUsers = activeSafescarfUsers.map(
    (user) => getMember(user, productTypeMembers),
  );
  const decoratedMachineAccounts = activeMachineAccounts.map(
    (ma) => getMember(ma, productTypeMembers),
  );
  return [...decoratedSafescarfUsers, ...decoratedMachineAccounts];
}

const ProductTypeMembers = ({ safescarfUsers }) => {
  const { productTypeId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const roles = useSelector((state) => state.safescarf.roles);
  const productTypeMembers = useSelector((state) => state.safescarf.productTypeMembers
    .filter((ptm) => ptm.product_type === Number(productTypeId)), shallowEqual);
  const machineAccounts = useSelector((state) => state.safescarf.machineAccounts);

  const members = useMemo(() => {
    if (safescarfUsers.length || machineAccounts.length) {
      return getMembers(safescarfUsers, machineAccounts, productTypeMembers);
    }
    return [];
  }, [safescarfUsers, machineAccounts, productTypeMembers]);

  useEffect(() => {
    if (!roles.length) dispatch(fetchRoles());
    if (!machineAccounts.length) dispatch(fetchMachineAccounts());
    if (!productTypeMembers.length) dispatch(fetchProductTypeMembers());
  }, [roles, machineAccounts, productTypeMembers]);

  const mapMembersToRows = members.map((member) => ({
    key: member.userId.toString(),
    onRowClick: () => navigate(`members/${member.userId.toString()}`),
    data: [
      {
        field: 'safescarfUserName',
        value: member.username,
      },
      {
        field: 'productTypeMemberRole',
        value: getHigherRole(roles, member.globalRole, member.productTypeMemberRole),
      },
    ],
  }));


  return (
    <Table
      header="Members"
      fields={[
        {
          id: 'safescarfUserName',
          title: 'Username',
        },
        {
          id: 'productTypeMemberRole',
          title: 'Permission',
        },
      ]}
      rows={mapMembersToRows || []}
    />
  );
};
ProductTypeMembers.propTypes = {
  safescarfUsers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      username: PropTypes.string,
      email: PropTypes.string,
      is_active: PropTypes.bool,
    }),
  ).isRequired,
};


export default ProductTypeMembers;
