import { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import {
  Breadcrumbs,
  Container,
  Grid,
  Typography,
} from '@mui/material';

import { fetchMachineAccounts } from '../../../../../redux/safescarf/machineAccounts/actions';
import { fetchRoles } from '../../../../../redux/safescarf/roles/actions';
import { fetchProducts } from '../../../../../redux/safescarf/products/actions';
import { fetchProductMembers } from '../../../../../redux/safescarf/productMembers/actions';
import { fetchUserProfile } from '../../../../../redux/safescarf/userProfile/actions';
import { fetchUsers as fetchSafescarfUsers } from '../../../../../redux/safescarf/users/actions';

import rbacCheck from '../../../../../rbac/rbacCheck';
import OverviewTable from '../../../../../components/OverviewTable/OverviewTable';
import EditProductMemberFormModal from './EditProductMemberFormModal';

function getMember(user, productMembers, isMachineAccount, productId) {
  const productMember = productMembers.find((m) => m.product === Number(productId)
    && m.user === user.id);

  return {
    userId: user.id,
    username: user.username,
    createdBy: user.email,
    globalRole: user.globalRole,
    productMemberId: productMember?.id,
    productMemberRole: productMember?.role,
    isMachineAccount,
  };
}

const ProductMemberDetails = () => {
  const { productId, safescarfUserId } = useParams();
  const dispatch = useDispatch();

  const [editModalOpened, setEditModalOpened] = useState(false);
  const userData = useSelector((state) => state.user);
  const roles = useSelector((state) => state.safescarf.roles);
  const products = useSelector((state) => state.safescarf.products);
  const productMembers = useSelector((state) => state.safescarf.productMembers);
  const safeScarfUserProfile = useSelector((state) => state.safescarf.userProfile);
  const machineAccounts = useSelector((state) => state.safescarf.machineAccounts);
  const safescarfUsers = useSelector((state) => state.safescarf.users);

  // Find specific user, product, and machine account
  const safescarfUser = safescarfUsers.find((user) => user.id === Number(safescarfUserId));
  const product = products.find((pt) => pt.id === Number(productId));
  const machineAccount = machineAccounts.find((ma) => ma.id === Number(safescarfUserId));

  const canGetSafeScarfUsers = rbacCheck(
    userData.roles,
    '/products/safescarf/products:GET_USERS',
    { product, safescarfUser: safeScarfUserProfile },
  );

  useEffect(() => {
    dispatch(fetchUserProfile());
    dispatch(fetchMachineAccounts());
    dispatch(fetchRoles());
    dispatch(fetchProducts());
    dispatch(fetchProductMembers());
  }, []);

  useEffect(() => {
    if (canGetSafeScarfUsers && !safescarfUsers.length) {
      dispatch(fetchSafescarfUsers({ productId }));
    }
  }, [safescarfUsers, productMembers]);

  const currentUser = useMemo(() => {
    if (safeScarfUserProfile && productMembers.length) {
      return getMember(safeScarfUserProfile, productMembers, false, productId);
    }
    return null;
  }, [safeScarfUserProfile, productMembers, productId]);

  const member = useMemo(() => {
    if (machineAccount && productMembers.length) {
      return getMember(machineAccount, productMembers, true, productId);
    }
    if (safescarfUser && productMembers.length) {
      return getMember(safescarfUser, productMembers, false, productId);
    }
    return null;
  }, [machineAccount, productMembers, safescarfUser, productId]);

  const canEditProductMembers = currentUser && rbacCheck(
    userData.roles,
    '/products/safescarf/products:PUT_MEMBERS',
    currentUser,
  );

  const roleLookup = useMemo(() => roles.reduce((acc, role) => {
    acc[role.id] = role.name;
    return acc;
  }, {}), [roles]);

  return (
    <>
      {editModalOpened && (
        <EditProductMemberFormModal
          productMember={member}
          onSubmit={() => setEditModalOpened(false)}
          onCancel={() => setEditModalOpened(false)}
        />
      )}
      <Grid
        container
        className="content-with-header"
        direction="column"
        rowGap={4}
      >
        <Grid item alignSelf="flex-start">
          <Breadcrumbs key={product?.id} separator=">">
            <Link to="/products/safescarf">Safescarf</Link>
            <Link to="/products/safescarf/products">products</Link>
            <Link to={`/products/safescarf/products/${product?.id}`}>{product?.name}</Link>
            <Link to={`/products/safescarf/products/${product?.id}`}>members</Link>
            <Typography>{member?.username}</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <Container maxWidth="lg">
            {member ? (
              <OverviewTable
                header={member?.username}
                rows={[
                  { title: 'Product:', value: product?.name },
                  { title: 'Machine account:', value: member?.isMachineAccount ? 'Yes' : 'No' },
                  { title: 'Created by:', value: member?.createdBy },
                  { title: 'Role:', value: roleLookup[member?.productMemberRole] || 'Not assigned' },
                  member?.globalRole && { title: 'Global role:', value: roleLookup[member?.globalRole] },
                ].filter((r) => r)}
                editAction={{
                  onClick: () => setEditModalOpened(true),
                  disabled: !canEditProductMembers,
                  tooltip: 'editProductMemberDisabled',
                }}
              />
            ) : (
              <Typography>Member information unavailable</Typography>
            )}
          </Container>
        </Grid>
      </Grid>
    </>
  );
};


export default ProductMemberDetails;
