import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { Container, Paper, Typography } from '@mui/material';
import FormikForm from '../../components/Formik/Form/FormikForm';
import { enqueueSnackbar } from '../../redux/notifier/actions';
import Auth from '../../api/auth';

const PasswordReset = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fields = [
    {
      fieldType: 'text',
      name: 'email',
      label: 'Email address',
      placeholder: 'example@pan-net.eu',
    },
  ];

  const initialValues = {
    email: '',
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required('Required')
      .email('Invalid email address'),
  });

  const handleSubmit = async (data) => {
    try {
      await Auth.resetUserPasswordLink(data);
      dispatch(enqueueSnackbar({
        message: 'userPasswordResetEmail',
        options: { variant: 'success' },
      }));
      navigate('/');
    } catch (error) {
      dispatch(enqueueSnackbar({
        message: error.message,
        options: { variant: 'error' },
      }));
    }
  };

  return (
    <Container maxWidth="sm">
      <Paper style={{ padding: '2.125rem' }}>
        <Typography align="center" variant="h4" color="primary">Reset Password</Typography>
        <Typography align="center">Enter your portal email address in order to receive a password reset link:</Typography>
        <FormikForm
          fields={fields}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          submitLabel="Reset Password"
        />
      </Paper>
    </Container>
  );
};

export default PasswordReset;
