import { useState } from 'react';
import debounce from 'lodash.debounce';
import useApiCall from './useApiCall';
import Provision from '../api/provision';

const useJuiceGroupValidation = () => {
  const [isCheckingJuiceGroup, setIsCheckingJuiceGroup] = useState(false);
  const [juiceGroupError, setJuiceGroupError] = useState('');

  const getJuiceGroupCall = useApiCall(Provision.fetchJuiceGroupByName);

  const checkJuiceGroup = debounce(async (value) => {
    if (!value) return;

    setIsCheckingJuiceGroup(true);
    const [data] = await getJuiceGroupCall(value);

    const juiceGroupAvailability = data?.title;
    setJuiceGroupError(juiceGroupAvailability === 'Not Found' ? '' : 'Juice Group invalid or already exists');
    setIsCheckingJuiceGroup(false);
  }, 800);

  return {
    juiceGroupError,
    isCheckingJuiceGroup,
    checkJuiceGroup,
  };
};

export default useJuiceGroupValidation;
