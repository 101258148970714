import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Gauge } from '@mui/x-charts';

import MonitoringMs from '../../../../api/monitoring';
import useApiCall from '../../../../hooks/useApiCall';

const GaugeComponent = ({
  panelInfo, dateRange, filterParams, height,
}) => {
  const { dashboardId } = useParams();
  const [value, setValue] = useState();
  const getPanelDataApiCall = useApiCall(MonitoringMs.getPanel);

  const getPanel = async (targets) => {
    const [data] = await getPanelDataApiCall(targets, dashboardId);
    if (data) {
      const { refId } = panelInfo.targets.filter((target) => !target.hide)[0];
      const panelData = data.results[refId]?.frames[0].data.values;
      if (panelData)setValue(panelData[panelData.length - 1][0]);
    }
  };

  useEffect(() => {
    const toDate = new Date(dateRange.to).getTime();
    const fromDate = new Date(dateRange.from).getTime();
    const targets = panelInfo.targets.filter((target) => !target.hide);
    if (targets) {
      getPanel({
        queries: targets,
        to: toDate.toString(),
        from: fromDate.toString(),
        filterParams,
      });
    }
  }, [dateRange, filterParams]);

  return (
    <Gauge
      value={value || 0}
      text={value ? `${value.toFixed(2)} %` : 'No data'}
      startAngle={-90}
      endAngle={90}
      height={height}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    />
  );
};


GaugeComponent.propTypes = {
  panelInfo: PropTypes.shape({
    title: PropTypes.string,
    targets: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
    repeat: PropTypes.string,
    gridPos: PropTypes.shape({
      h: PropTypes.number,
      w: PropTypes.number,
      x: PropTypes.number,
      y: PropTypes.number,
    }),
  }).isRequired,
  dateRange: PropTypes.shape({
    from: PropTypes.string,
    to: PropTypes.string,
  }).isRequired,
  height: PropTypes.number.isRequired,
  filterParams: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.object,
  ])).isRequired,
};


export default GaugeComponent;
