import { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Container } from '@mui/material';
import Modal from '../../../components/Modal';
import OverviewTable from '../../../components/OverviewTable/OverviewTable';
import DeleteFormModal from '../../../components/DeleteFormModal/DeleteFormModal';
import rbacCheck from '../../../rbac/rbacCheck';
import Provision from '../../../api/provision';
import useApiCall from '../../../hooks/useApiCall';
import Button from '../../../components/Buttons/Button';
import { useCopyToClipboard } from '../../../hooks/useCopyToClipboard';
import CodeBlock from '../../../components/CodeBlock/CodeBlock';

const createConfig = (osProject, userEmail) => `    export OS_REGION_NAME=RegionOne
    export OS_AUTH_URL=${osProject.cloudId}.in.pan-net.eu:5000/v3
    export OS_USER_DOMAIN_NAME=users_domain
    export OS_USERNAME=${userEmail}
    echo "Please enter your OpenStack Password: "
    read -sr OS_PASSWORD_INPUT
    export OS_PASSWORD=$OS_PASSWORD_INPUT
    export OS_PROJECT_NAME=${osProject.name}
    export OS_PROJECT_DOMAIN_NAME=${osProject.juiceGroup}
    # set prompt - optional
    export BDCNAME=${osProject.cloudId}
    export PS1=\\[\\e[32m\\]$OS_USERNAME:$OS_USER_DOMAIN_NAME\\[\\e[m\\]@\\h (\\W)[\\[\\e[34m\\]$OS_PROJECT_NAME:$OS_PROJECT_DOMAIN_NAME\\[\\e[m\\]@\\[\\e[36m\\]\${BDC_NAME}\\[\\e[m\\]]\\[\\033[01;31m\\]$(parse_git_branch)\\[\\033[00m\\]\\$
    # explicit placement and compute api version - optional
    # export OS_PLACEMENT_API_VERSION=1.28
    # export OS_COMPUTE_API_VERSION=2.53
    # if no DT Cloud Services certs installed on your PC, cert file path should be defined
    # export OS_CACERT=<cert_path>`;

const OsProject = () => {
  const {
    productId, juiceGroup, cloudId, osprojectName,
  } = useParams();
  const navigate = useNavigate();
  const userData = useSelector((state) => state.user);
  const copyToClipboard = useCopyToClipboard();

  const [osProject, setOsProject] = useState(null);
  const [osConfigModalOpened, setOsConfigModalOpened] = useState(false);
  const [deleteModalOpened, setDeleteModalOpened] = useState(false);

  const fetchOsProjectDetails = useApiCall(Provision.fetchOsProjectDetails);
  const deleteOsProjectCall = useApiCall(Provision.deleteOsProject, 'osProjectDeleted');
  const patchOsProjectCall = useApiCall(Provision.patchOsProject, 'osProjectSetDeleted', 'osProjectPatchFailed');

  useEffect(() => {
    const getOsProjectDetails = async () => {
      const [data] = await fetchOsProjectDetails(juiceGroup, cloudId, osprojectName);
      if (data) setOsProject(data);
    };

    getOsProjectDetails();
  }, []);

  const deleteOsProject = async () => {
    setDeleteModalOpened(false);
    const [data] = await deleteOsProjectCall(cloudId, osprojectName);

    if (data) {
      navigate(-1);
    }
  };

  const patchOsProject = async () => {
    if (!osProject) return;

    const [data] = await patchOsProjectCall(
      osProject.juiceGroup,
      osProject.cloudId,
      osProject.name,
      { status: 'deleted' },
    );

    if (data) {
      setDeleteModalOpened(false);
      navigate(-1);
    }
  };

  const config = osProject ? createConfig(osProject, userData.email) : '';

  const canDelete = osProject && rbacCheck(userData.roles, '/osprojects:DELETE', {
    productId,
    osProjectType: osProject.type,
    userProducts: userData.productIds,
  });

  let tooltip;
  if (osProject?.createdFromJuice) {
    tooltip = 'osProjectDeleteDisabledCreatedFromJuice';
  } else if (!canDelete) {
    tooltip = 'osProjectDeleteDisabled';
  }

  if (!osProject || !['active', 'deletionFailed'].includes(osProject.status)) return null;

  const rows = [
    {
      title: 'Dev. Project Name',
      value: osProject?.name,
    },
    {
      title: 'OpenStack User Name:',
      value: userData.productIds.includes(productId) ? userData.email : '',
    },
    {
      title: 'OpenStack working source file:',
      component: (
        <Button
          size="small"
          variant="outlined"
          disabled={!osProject?.name}
          onClick={() => setOsConfigModalOpened(true)}
          icon="Copy"
          label="Copy working source file"
        />
      ),
    },
    {
      title: 'Horizon URL:',
      component: (
        <Link to={osProject.horizonUrl} target="_blank" rel="noopener noreferrer">
          {osProject.horizonUrl}
        </Link>
      ),
    },
  ];

  return (
    <Container maxWidth="md">
      {osConfigModalOpened && (
        <Modal
          maxWidth="md"
          title="Configuration"
          onClose={() => setOsConfigModalOpened(false)}
          onSubmit={() => {
            setOsConfigModalOpened(false);
            copyToClipboard(config);
          }}
          submitLabel="Copy"
        >
          <CodeBlock code={config} language="sh" />
        </Modal>
      )}
      {deleteModalOpened && (
        <DeleteFormModal
          fieldName="Os Project"
          fieldValue={osProject?.name}
          messages={['osProjectsDeleteMessage']}
          onSubmit={deleteOsProject}
          onCancel={() => setDeleteModalOpened(false)}
        />
      )}
      <OverviewTable
        header={osProject?.name}
        rows={rows}
        deleteAction={{
          onClick: osProject.status === 'deletionFailed' ? patchOsProject : () => setDeleteModalOpened(true),
          label: osProject.status === 'deletionFailed' ? 'Set As Deleted' : undefined,
          tooltip,
          disabled: !canDelete || osProject.createdFromJuice,
        }}
      />
    </Container>
  );
};

export default OsProject;
