import PropTypes from 'prop-types';
import { Tooltip as TooltipMUI } from '@mui/material';

const Tooltip = ({
  children,
  title,
}) => (
  <TooltipMUI title={title}>
    <div>
      {children}
    </div>
  </TooltipMUI>
);

Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
};

Tooltip.defaultProps = {
  title: '',
};

export default Tooltip;
