import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';

import { convertUnits } from '../../../../helpers/unitConversion';

import MonitoringMs from '../../../../api/monitoring';
import useApiCall from '../../../../hooks/useApiCall';

const Stat = ({
  panelInfo, dateRange, filterParams, styles,
}) => {
  const { dashboardId } = useParams();
  const [value, setValue] = useState('No data');
  const getPanelDataApiCall = useApiCall(MonitoringMs.getPanel);


  const getPanel = async (targets) => {
    const [data] = await getPanelDataApiCall(targets, dashboardId);
    if (data) {
      const { refId } = panelInfo.targets.filter((target) => !target.hide)[0];
      const panelData = data.results[refId].frames[0].data.values;
      const lastValue = panelData[panelData.length - 1][0];

      setValue(typeof lastValue === 'string' ? lastValue : convertUnits(lastValue, panelInfo.unit));
    }
  };

  useEffect(() => {
    const toDate = new Date(dateRange.to).getTime();
    const fromDate = new Date(dateRange.from).getTime();
    if (panelInfo.targets) {
      getPanel({
        queries: panelInfo.targets,
        to: toDate.toString(),
        from: fromDate.toString(),
        filterParams,
      });
    }
  }, [dateRange, filterParams]);

  return (
    <div style={{
      height: styles.height,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    }}
    >
      <Typography variant="h4" color="primary" align="center" fontSize={styles.fontSize}>{value}</Typography>
    </div>
  );
};

Stat.propTypes = {
  panelInfo: PropTypes.shape({
    title: PropTypes.string,
    targets: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
    unit: PropTypes.string,
    gridPos: PropTypes.shape({
      h: PropTypes.number,
      w: PropTypes.number,
      x: PropTypes.number,
      y: PropTypes.number,
    }),
  }).isRequired,
  dateRange: PropTypes.shape({
    from: PropTypes.string,
    to: PropTypes.string,
  }).isRequired,
  filterParams: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.object,
  ])).isRequired,
  styles: PropTypes.shape({
    height: PropTypes.number,
    fontSize: PropTypes.string,
  }).isRequired,
};


export default Stat;
