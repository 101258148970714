import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { createProductType } from '../../../../../redux/safescarf/productTypes/actions';
import { FormModal } from '../../../../../components/Formik';

const CreateProductTypeFormModal = ({ onSubmit, onCancel }) => {
  const dispatch = useDispatch();

  const fields = [
    {
      fieldType: 'text',
      name: 'name',
      label: 'Name',
    },
    {
      fieldType: 'text',
      name: 'description',
      label: 'Description',
    },
  ];

  const initialValues = {
    name: '',
    description: '',
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    description: Yup.string().required('Required'),
  });

  const handleSubmit = async (formValues) => {
    onSubmit();
    dispatch(createProductType({ ...formValues }));
  };

  return (
    <FormModal
      title="Create New Product Type"
      fields={fields}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      onCancel={onCancel}
      submitLabel="Create"
    />
  );
};

CreateProductTypeFormModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default CreateProductTypeFormModal;
