import * as Yup from 'yup';
import { regexValidator } from '../../../helpers/regexValidator';

export const validationSchema = Yup.object().shape({
  osProjectName: Yup.string()
    .required('Required')
    .matches(regexValidator.noSpace, 'Spaces not allowed'),
  projectId: Yup.string()
    .required('Required'),
  productId: Yup.string()
    .required('Required'),
  cloudName: Yup.string()
    .required('Required'),
  cpuCores: Yup.number()
    .required('Required')
    .min(1, 'Must be greater than 0')
    .max(Yup.ref('$unusedQuota.compute.cores'), 'Exceeds available quota'),
  ram: Yup.number()
    .required('Required')
    .min(1, 'Must be greater than 0')
    .max(Yup.ref('$unusedQuota.compute.ram'), 'Exceeds available quota'),
  diskQuota: Yup.number()
    .required('Required')
    .min(1, 'Must be greater than 0')
    .max(Yup.ref('$unusedQuota.volume.gigabytes'), 'Exceeds available quota'),
  floatingIps: Yup.number()
    .required('Required')
    .min(0, 'Must be 0 or greater')
    .max(Yup.ref('$unusedQuota.network.floatingIps'), 'Exceeds available quota'),
});
