import { ofType, combineEpics } from 'redux-observable';
import { mergeMap, catchError } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { handleError } from '../../errorHandler';
import Safescarf from '../../../api/safescarf';
import {
  FETCH_INSTANCE_STARTED,
  fetchInstancefulfilled,
} from './actions';


const fetchInstanceEpic = (action$) => action$.pipe(
  ofType(FETCH_INSTANCE_STARTED),
  mergeMap(() => from(Safescarf.fetchSafescarfInstance()).pipe(
    mergeMap((response) => of(
      fetchInstancefulfilled(response),
    )),
    catchError(handleError),
  )),
);

export default combineEpics(
  fetchInstanceEpic,
);
