import { useState } from 'react';
import { Grid } from '@mui/material';
import { useLocation, Outlet } from 'react-router-dom';
import SidebarNavigation from '../../components/SidebarNavigation/SidebarNavigation';

const Account = () => {
  const { pathname } = useLocation();
  const currentPath = pathname.split('/')[2];

  const [virtualClouds, setVirtualClouds] = useState(undefined);

  const menuItems = [
    { title: 'Overview' },
    { title: 'Security', path: 'security' },
  ];

  return (
    <Grid
      container
      justifyContent="space-between"
    >
      <Grid item xs={2}>
        <SidebarNavigation menuItems={menuItems} currentPath={currentPath} />
      </Grid>
      <Grid item xs={9}>
        <Outlet context={[virtualClouds, setVirtualClouds]} />
      </Grid>
    </Grid>
  );
};

export default Account;
