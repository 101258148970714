import { Link } from 'react-router-dom';
import { ScaleTelekomFooterContent } from '@telekom/scale-components-react';
import { Typography } from '@mui/material';
import { DataPrivacyIcon } from '../icons/IconLibrary';

const FooterContent = () => {
  const renderNavItem = (to, label, icon = null) => (
    <li>
      <Link to={to}>
        {icon && icon}
        <Typography>{icon ? `\u00A0${label}` : label}</Typography>
      </Link>
    </li>
  );

  return (
    <ScaleTelekomFooterContent>
      <span slot="notice">
        <Typography>© DT Europe Holding GmbH</Typography>
      </span>
      <ul slot="navigation">
        {renderNavItem('/contact-us', 'Contact')}
        {renderNavItem('/gtc', 'Terms and conditions')}
        {renderNavItem('/about-us', 'Imprint')}
        {renderNavItem('/data-privacy', 'Data Privacy', <DataPrivacyIcon />)}
      </ul>
    </ScaleTelekomFooterContent>
  );
};

export default FooterContent;
