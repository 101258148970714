import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useNavigate, Link } from 'react-router-dom';
import {
  Breadcrumbs,
  Container,
  Grid,
  Typography,
} from '@mui/material';
import rbacCheck from '../../../../rbac/rbacCheck';
import Products from './Products';
import DeleteFormModal from '../../../../components/DeleteFormModal/DeleteFormModal';
import OverviewTable from '../../../../components/OverviewTable/OverviewTable';
import EditProjectFormModal from './EditProjectFormModal';
import useApiCall from '../../../../hooks/useApiCall';
import Auth from '../../../../api/auth';

const Project = () => {
  const navigate = useNavigate();
  const { projectId } = useParams();
  const userData = useSelector((state) => state.user);

  const [projectDetails, setProjectDetails] = useState({});
  const [deleteModalOpened, setDeleteModalOpened] = useState(false);
  const [editModalOpened, setEditModalOpened] = useState(false);

  const canSeeOrganizations = rbacCheck(userData.roles, '/organizations:VIEW_ALL');
  const canDeleteProject = rbacCheck(userData.roles, '/projects:DELETE');
  const canEditProjects = rbacCheck(userData.roles, '/projects:PATCH');

  const getProjectDetailsCall = useApiCall(Auth.fetchV2ProjectById);
  const deleteProjectCall = useApiCall(Auth.deleteV2Project);

  const getProjectDetails = async () => {
    const [data] = await getProjectDetailsCall(projectId);
    if (data) setProjectDetails(data);
  };

  useEffect(() => {
    getProjectDetails();
  }, []);

  const deleteProject = async () => {
    setDeleteModalOpened(false);
    const [data] = await deleteProjectCall(projectId);

    if (data) {
      navigate(-1);
    }
  };

  const getProjectInfo = () => [
    {
      title: 'Description',
      value: projectDetails.description,
    },
    {
      title: 'Organization',
      component: (
        <Link to={`/organizations/${projectDetails.organizationId}`}>
          {projectDetails.organization?.enterpriseName}
        </Link>
      ),
    },
    {
      title: 'Business unit',
      component: (
        <Link to={`/business-units/${projectDetails.businessUnitId}`}>
          {projectDetails.businessUnit?.name}
        </Link>
      ),
    },
    {
      title: 'Technical lead',
      value: projectDetails.technicalLead?.email,
      component: (
        <Link to={`/users/${projectDetails.technicalLead?.uuid}`}>
          {projectDetails.technicalLead?.email}
        </Link>
      ),
    },
  ];

  return (
    <>
      {editModalOpened && (
        <EditProjectFormModal
          project={projectDetails}
          onSubmit={getProjectDetails}
          onCancel={() => setEditModalOpened(false)}
        />
      )}
      {deleteModalOpened && (
        <DeleteFormModal
          fieldName="Project"
          fieldValue={projectDetails.name}
          onSubmit={deleteProject}
          onCancel={() => setDeleteModalOpened(false)}
        />
      )}
      <Grid
        container
        className="content-with-header"
        direction="column"
      >
        <Grid item>
          <Breadcrumbs separator=" > ">
            {canSeeOrganizations
              ? <Link to="/organizations">organizations</Link>
              : <Link to={`/organizations/${projectDetails.organizationId}`}>organizations</Link>}
            <Link to={`/organizations/${projectDetails.organizationId}`}>{projectDetails.organization?.enterpriseName}</Link>
            <Link to={`/organizations/${projectDetails.organizationId}/projects`}>projects</Link>
            <Typography>{projectDetails.name}</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <Container maxWidth="lg">
            <Grid
              container
              direction="column"
              rowGap={8}
              padding="2rem 0"
            >
              <Grid item>
                <OverviewTable
                  header={projectDetails.name}
                  rows={getProjectInfo()}
                  editAction={{
                    onClick: () => setEditModalOpened(true),
                    disabled: !canEditProjects,
                    tooltip: 'editProjectDisabled',
                  }}
                  deleteAction={{
                    onClick: () => setDeleteModalOpened(true),
                    disabled: !canDeleteProject,
                    tooltip: 'businessUnitDeletePermissionErr',
                  }}
                />
              </Grid>
              <Grid item>
                <Products />
              </Grid>
            </Grid>
          </Container>
        </Grid>
      </Grid>
    </>
  );
};

export default Project;
