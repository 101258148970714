import PropTypes from 'prop-types';
import * as Yup from 'yup';
import validator from 'validator';
import Provision from '../../api/provision';
import useApiCall from '../../hooks/useApiCall';
import { FormModal } from '../../components/Formik';

const EditContactFormModal = ({
  juiceGroup, contact, onSubmit, onCancel,
}) => {
  const editContact = useApiCall(Provision.editContact, 'ContactEdited');

  const fields = [
    {
      fieldType: 'text',
      name: 'name',
      label: 'Name',
    },
    {
      fieldType: 'text',
      name: 'phoneNumber',
      label: 'Phone Number',
    },
    {
      fieldType: 'text',
      name: 'slackChannel',
      label: 'Slack Channel',
    },
  ];

  const initialValues = {
    name: contact.name,
    phoneNumber: contact.phoneNumber,
    slackChannel: contact.slackChannel,
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().ensure(),
    phoneNumber: Yup.string().test({
      message: 'Please input valid phone number prefixed with country code',
      test: (value) => (value !== undefined
        ? validator.isMobilePhone(value, 'any', { strictMode: true })
        : true
      ),
    }),
    slackChannel: Yup.string(),
  });

  const updateContact = async (formValues) => {
    onCancel();

    const [data] = await editContact({
      ...formValues,
      juiceGroup,
      id: contact._id,
    });

    if (data) {
      onSubmit();
    }
  };

  return (
    <FormModal
      title={`Edit contact ${contact.email}`}
      fields={fields}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={updateContact}
      onCancel={onCancel}
      submitLabel="Save"
    />
  );
};

EditContactFormModal.propTypes = {
  contact: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
      PropTypes.object,
    ]),
  ).isRequired,
  juiceGroup: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default EditContactFormModal;
