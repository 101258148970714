import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import Provision from '../../../../../api/provision';
import useApiCall from '../../../../../hooks/useApiCall';
import Modal from '../../../../../components/Modal';
import Table from '../../../../../components/Table';

const ForecastTable = ({ product, onSubmit, onClose }) => {
  const { juiceGroup, name } = product.details;
  const intl = useIntl();

  const [forecasts, setForecasts] = useState(null);

  const fetchForecasts = useApiCall(Provision.fetchForecastCapacity);
  const createVirtualClouds = useApiCall(
    Provision.createVirtualClouds,
    'forecastsApplySuccess',
    'forecastsApplyFailure',
  );

  useEffect(() => {
    const getForecasts = async () => {
      const [data] = await fetchForecasts(juiceGroup);
      if (data) setForecasts(data);
    };

    getForecasts();
  }, []);

  const ramToMegabytes = (ram) => ram * 1024;

  const fields = [
    { title: 'Cloud Id', id: 'cloudId' },
    { title: 'vCPU', id: 'cores' },
    { title: 'RAM (GB)', id: 'ram' },
    { title: 'Storage (GB)', id: 'gigabytes' },
    { title: 'Floating IPs', id: 'floatingIps' },
  ];

  const handleClick = async () => {
    if (!forecasts) return;

    const transformedForecasts = forecasts.map((forecast) => ({
      ...forecast,
      compute: {
        ...forecast.compute,
        ram: ramToMegabytes(forecast.compute.ram),
      },
    }));

    const [data] = await createVirtualClouds(transformedForecasts);
    if (data) onSubmit();
  };

  const rows = forecasts?.map((forecast) => ({
    key: forecast.cloudId,
    data: [
      { field: 'cloudId', value: forecast.cloudId },
      { field: 'cores', value: forecast.compute.cores },
      { field: 'ram', value: forecast.compute.ram },
      { field: 'gigabytes', value: forecast.volume.gigabytes },
      { field: 'floatingIps', value: forecast.network.floatingIps },
    ],
  }));

  return (
    <Modal
      maxWidth="sm"
      title={intl.formatMessage({ id: 'productForecasts' }, { name })}
      hideActions={!forecasts?.length}
      disabled={!forecasts}
      onClose={onClose}
      onSubmit={handleClick}
      submitLabel="Apply"
    >
      {forecasts ? (
        <Table
          fields={fields}
          rows={rows}
          search={false}
        />
      ) : (
        <Typography pb={2}>
          {intl.formatMessage({ id: 'forecastsNotFound' })}
        </Typography>
      )}
    </Modal>
  );
};

ForecastTable.propTypes = {
  product: PropTypes.shape({
    details: PropTypes.shape({
      juiceGroup: PropTypes.string,
      name: PropTypes.string,
    }),
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ForecastTable;
