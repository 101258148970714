import http from './httpClient';

class Safescarf {
  // SAFESCARF_PRODUCT_TYPES
  static createSafescarfProductType(data) {
    return http.post(
      `${import.meta.env.VITE_SAFESCARF_V0_BASE}/product-types`,
      data,
    );
  }

  static fetchSafescarfProductTypes() {
    return http.get(`${import.meta.env.VITE_SAFESCARF_V0_BASE}/product-types`);
  }

  static updateSafescarfProductType(data) {
    return http.put(
      `${import.meta.env.VITE_SAFESCARF_V0_BASE}/product-types/${data.id}`,
      data,
    );
  }

  // SAFESCARF_PRODUCTS
  static createSafescarfProduct(data) {
    return http.post(
      `${import.meta.env.VITE_SAFESCARF_V0_BASE}/products`,
      data,
    );
  }

  static fetchSafescarfProducts(productId) {
    return http.get(
      `${import.meta.env.VITE_SAFESCARF_V0_BASE}/products`,
      { params: { productId } },
    );
  }

  static updateSafescarfProduct(data) {
    return http.put(
      `${import.meta.env.VITE_SAFESCARF_V0_BASE}/products/${data.id}`,
      data,
    );
  }

  static deleteSafescarfProduct(id) {
    return http.delete(`${import.meta.env.VITE_SAFESCARF_V0_BASE}/products/${id}`);
  }

  // SAFESCARF_USERS
  static createSafescarfUser(data) {
    return http.post(
      `${import.meta.env.VITE_SAFESCARF_V0_BASE}/users`,
      data,
    );
  }

  static fetchSafescarfUsers(params) {
    return http.get(
      `${import.meta.env.VITE_SAFESCARF_V0_BASE}/users`,
      { params },
    );
  }

  static updateSafescarfUser(data) {
    return http.put(
      `${import.meta.env.VITE_SAFESCARF_V0_BASE}/users/${data.id}`,
      data,
    );
  }

  static fetchSafescarfUserProfile() {
    return http.get(`${import.meta.env.VITE_SAFESCARF_V0_BASE}/users/profile`);
  }

  // SAFESCARF_PRODUCT_TYPE_MEMBERS
  static fetchSafescarfProductTypeMembers(productTypeId) {
    return http.get(
      `${import.meta.env.VITE_SAFESCARF_V0_BASE}/product-type-members`,
      { params: { productTypeId } },
    );
  }

  static updateProductTypeMemberRole(data) {
    return http.post(
      `${import.meta.env.VITE_SAFESCARF_V0_BASE}/product-type-members`,
      data,
    );
  }


  // SAFESCARF_PRODUCT_MEMBERS
  static fetchSafescarfProductMembers(productId) {
    return http.get(
      `${import.meta.env.VITE_SAFESCARF_V0_BASE}/product-members`,
      { params: { productId } },
    );
  }

  static updateProductMemberRole(data) {
    return http.post(
      `${import.meta.env.VITE_SAFESCARF_V0_BASE}/product-members`,
      data,
    );
  }

  // SAFESCARF_ROLES
  static fetchSafescarfRoles() {
    return http.get(`${import.meta.env.VITE_SAFESCARF_V0_BASE}/roles`);
  }

  // SAFESCARF_MACHINE_ACCOUNTS
  static fetchMachineAccounts() {
    return http.get(`${import.meta.env.VITE_SAFESCARF_V0_BASE}/users/machine-account`);
  }

  static createMachineAccount() {
    return http.post(`${import.meta.env.VITE_SAFESCARF_V0_BASE}/users/machine-account`);
  }

  static fetchSafescarfInstance() {
    return http.get(
      `${import.meta.env.VITE_SAFESCARF_V0_BASE}/instance`,
      { validateStatus: (status) => ((status >= 200 && status < 300) || status === 404) },
    );
  }

  static fetchFQDNAvailability(fqdn) {
    return http.get(
      `${import.meta.env.VITE_SAFESCARF_V0_BASE}/fqdn/${fqdn}`,
    );
  }
}

export default Safescarf;
