import PropTypes from 'prop-types';
import { Grid, Typography } from '@mui/material';
import { FormikSwitch } from '../../../../components/Formik';

const NetworkConnectivity = ({ externalNetworks }) => (
  <Grid container spacing={2}>
    <Grid item xs={12} md={8}>
      <Typography>
        <strong>Internet Connectivity: </strong>
        This option includes the demanded (predefined) number of public floating IPs,
        the creation of an internal network
        and the creation of a virtual router configured for internet connectivity.
        <br />
        In case this option is disabled, only the demanded floating IPs are provided,
        and no networks or routers are created.
        Internet connectivity can be configured using JUICE API at a later stage
      </Typography>
    </Grid>
    <Grid item xs={12} md={4}>
      <FormikSwitch
        name="internetConnectivity"
        label="Internet Connectivity"
        disabled={!externalNetworks.includes('external_internet_provider')}
      />
    </Grid>
    <Grid item xs={12} md={8}>
      <Typography>
        <strong>DTH Connectivity: </strong>
        This option includes the demanded (predefined) number of DTH floating IPs,
        the creation of an internal network
        and the creation of a virtual router configured for DTH connectivity.
        <br />
        In case this option is disabled, only the demanded floating IPs are provided,
        and no networks or routers are created.
        DTH connectivity can be configured using JUICE API at a later stage as well.
      </Typography>
    </Grid>
    <Grid item xs={12} md={4}>
      <FormikSwitch
        name="DTHConnectivity"
        label="DTH Connectivity"
        disabled={!externalNetworks.includes('external_pi-net_provider')}
      />
    </Grid>
    <Grid item xs={12} md={8}>
      <Typography>
        <strong>DTH Red Router Connectivity: </strong>
        This option includes the demanded (predefined) number of DTH floating IPs,
        the creation of an internal network,
        the creation of a virtual router configured for DTH and internet connectivity.
        <br />
        In case this option is disabled, only the demanded floating IPs are provided,
        and no networks or routers are created.
        DTH and internet connectivity can be configured
        using JUICE API at a later stage as well.
      </Typography>
    </Grid>
    <Grid item xs={12} md={4}>
      <FormikSwitch
        name="DTHRedRouterConnectivity"
        label="DTH Connectivity with Internet (Red Router)"
        disabled={!externalNetworks.includes('external_pi-net2inet_provider')}
      />
    </Grid>
  </Grid>
);

NetworkConnectivity.propTypes = {
  externalNetworks: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default NetworkConnectivity;
