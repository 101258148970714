import PropTypes from 'prop-types';
import Modal from '../../Modal/index';
import FormikForm from '../Form/FormikForm';

const FormModal = ({
  title,
  fields,
  initialValues,
  validationSchema,
  onSubmit,
  onCancel,
  submitLabel,
}) => (
  <Modal
    title={title}
    hideActions
    onClose={onCancel}
  >
    <FormikForm
      fields={fields}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      onCancel={onCancel}
      submitLabel={submitLabel}
    />
  </Modal>
);

FormModal.propTypes = {
  title: PropTypes.string.isRequired,
  fields: PropTypes.arrayOf(PropTypes.object).isRequired,
  initialValues: PropTypes.object.isRequired,
  validationSchema: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  submitLabel: PropTypes.string.isRequired,
};

FormModal.defaultProps = {
  validationSchema: null,
};

export default FormModal;
