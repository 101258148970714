import {
  FETCH_USER_PROFILE_FULFILLED,
} from './actions';

const userProfileReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_USER_PROFILE_FULFILLED:
      return action.payload;
    default:
      return state;
  }
};

export { userProfileReducer };
