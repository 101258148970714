import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Table from '../../../components/Table';
import useApiCall from '../../../hooks/useApiCall';
import Auth from '../../../api/auth';

const ONE_YEAR_IN_MS = 365 * 24 * 3600 * 1000;

const MarketplaceItems = ({ organization, setOrganization }) => {
  const [marketplaceItems, setMarketplaceItems] = useState([]);

  const getMarketplaceItemsCall = useApiCall(Auth.fetchV1MarketplaceItems);
  const activateMarketplaceItemCall = useApiCall(Auth.activateMarketplaceItem);

  useEffect(() => {
    const getAllMarketplaceItems = async () => {
      const [data] = await getMarketplaceItemsCall();
      if (data) setMarketplaceItems(data);
    };

    getAllMarketplaceItems();
  }, []);

  const isOrgOlderThanOneYear = () => (
    new Date() - new Date(organization.createdAt)
  ) > ONE_YEAR_IN_MS;

  const activateItem = async (item) => {
    const [data] = await activateMarketplaceItemCall({
      _id: organization._id,
      marketplaceItemId: item.id,
    });
    if (data) setOrganization(data);
  };

  const fields = [
    {
      id: 'name',
      title: 'Name',
    },
    {
      id: 'status',
      title: 'Status',
      type: 'options',
    },
  ];

  const rows = marketplaceItems?.map(({ _id, name }) => {
    const activated = organization.marketplaceItemIds?.includes(_id);

    return {
      key: _id,
      data: [
        {
          field: 'name',
          value: name,
        },
        {
          field: 'status',
          type: 'switch',
          value: activated ? 'Active' : 'Inactive',
          props: {
            disabled: activated || (name === 'Evaluation' && isOrgOlderThanOneYear()),
            checked: activated,
            name,
            label: activated ? 'Active' : 'Inactive',
            onChange: () => !activated && activateItem({ id: _id, name }),
          },
        },
      ],
    };
  });

  return (
    <Table
      header="Marketplace"
      fields={fields}
      rows={rows}
    />
  );
};

MarketplaceItems.propTypes = {
  organization: PropTypes.shape({
    _id: PropTypes.string,
    createdAt: PropTypes.string,
    marketplaceItemIds: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  setOrganization: PropTypes.func.isRequired,
};

export default MarketplaceItems;
