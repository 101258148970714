import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { FormModal } from '../../components/Formik';
import { regexValidator } from '../../helpers/regexValidator';
import useApiCall from '../../hooks/useApiCall';
import Auth from '../../api/auth';

const CreateOrganizationFormModal = ({ onSubmit, onCancel }) => {
  const createOrganizationCall = useApiCall(Auth.createV1Organization);

  const fields = [
    {
      fieldType: 'text',
      name: 'enterpriseName',
      label: 'Organization name',
    },
    {
      fieldType: 'text',
      name: 'CRMId',
      label: 'CRM Id',
    },
  ];

  const initialValues = {
    enterpriseName: '',
    CRMId: '',
  };

  const validationSchema = Yup.object().shape({
    enterpriseName: Yup.string()
      .required('Required')
      .matches(regexValidator.name, 'Start with letter, all lower-case, no spaces, no special characters(dash only)'),
  });

  const createOrganization = async (formValues) => {
    onCancel();

    const [data] = await createOrganizationCall(formValues);

    if (data) {
      onSubmit(data);
    }
  };

  return (
    <FormModal
      title="Create New Organization"
      fields={fields}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={createOrganization}
      onCancel={onCancel}
      submitLabel="Create"
    />
  );
};

CreateOrganizationFormModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default CreateOrganizationFormModal;
