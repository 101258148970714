import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from '../redux/notifier/actions';

export const useCopyToClipboard = () => {
  const dispatch = useDispatch();

  const copy = async (data) => {
    try {
      await navigator.clipboard.writeText(data);
      dispatch(
        enqueueSnackbar({
          message: 'copied',
          options: { variant: 'success' },
        }),
      );
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: 'copyFailed',
          options: { variant: 'error' },
        }),
      );
    }
  };

  return copy;
};
