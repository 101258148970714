import PropTypes from 'prop-types';

import Add from '@mui/icons-material/Add';
import Check from '@mui/icons-material/Check';
import Close from '@mui/icons-material/Close';
import ContentCopy from '@mui/icons-material/ContentCopy';
import Delete from '@mui/icons-material/Delete';
import Edit from '@mui/icons-material/Edit';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import Group from '@mui/icons-material/Group';
import Info from '@mui/icons-material/Info';
import Brightness4 from '@mui/icons-material/Brightness4';
import Brightness7 from '@mui/icons-material/Brightness7';
import PersonOutline from '@mui/icons-material/PersonOutline';
import Visibility from '@mui/icons-material/Visibility';
import OpenInFull from '@mui/icons-material/OpenInFull';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Tune from '@mui/icons-material/Tune';
import Logout from '@mui/icons-material/Logout';
import Cancel from '@mui/icons-material/Cancel';
import Save from '@mui/icons-material/Save';
import Done from '@mui/icons-material/Done';
import ImportExport from '@mui/icons-material/ImportExport';
import DataPrivacy from '@mui/icons-material/PrivacyTip';

const Icon = ({ component: IconComponent, ...props }) => (
  <IconComponent {...props} />
);

Icon.propTypes = {
  component: PropTypes.elementType.isRequired,
};

export const AddIcon = (props) => <Icon component={Add} {...props} />;
export const CloseIcon = (props) => <Icon component={Close} {...props} />;
export const ContentCopyIcon = (props) => <Icon component={ContentCopy} {...props} />;
export const DeleteIcon = (props) => <Icon component={Delete} {...props} />;
export const EditIcon = (props) => <Icon component={Edit} {...props} />;
export const ExpandMoreIcon = (props) => <Icon component={ExpandMore} {...props} />;
export const ExpandLessIcon = (props) => <Icon component={ExpandLess} {...props} />;
export const GroupIcon = (props) => <Icon component={Group} {...props} />;
export const InfoIcon = (props) => <Icon component={Info} {...props} />;
export const Brightness4Icon = (props) => <Icon component={Brightness4} {...props} />;
export const Brightness7Icon = (props) => <Icon component={Brightness7} {...props} />;
export const PersonOutlineIcon = (props) => <Icon component={PersonOutline} {...props} />;
export const VisibilityIcon = (props) => <Icon component={Visibility} {...props} />;
export const VisibilityOffIcon = (props) => <Icon component={VisibilityOff} {...props} />;
export const TuneIcon = (props) => <Icon component={Tune} {...props} />;
export const LogoutIcon = (props) => <Icon component={Logout} {...props} />;
export const ApplyIcon = (props) => <Icon component={Check} {...props} />;
export const CancelIcon = (props) => <Icon component={Cancel} {...props} />;
export const SaveIcon = (props) => <Icon component={Save} {...props} />;
export const DoneIcon = (props) => <Icon component={Done} {...props} />;
export const OpenInFullIcon = (props) => <Icon component={OpenInFull} {...props} />;
export const ImportExportIcon = (props) => <Icon component={ImportExport} {...props} />;
export const DataPrivacyIcon = (props) => <Icon component={DataPrivacy} {...props} />;

export default {
  AddIcon,
  CloseIcon,
  ContentCopyIcon,
  DeleteIcon,
  EditIcon,
  ExpandMoreIcon,
  ExpandLessIcon,
  GroupIcon,
  InfoIcon,
  Brightness4Icon,
  Brightness7Icon,
  PersonOutlineIcon,
  VisibilityIcon,
  VisibilityOffIcon,
  TuneIcon,
  LogoutIcon,
  ApplyIcon,
  CancelIcon,
  SaveIcon,
  DoneIcon,
  OpenInFullIcon,
  ImportExportIcon,
  DataPrivacyIcon,
};
